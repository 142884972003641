/**
 * @file BadgeSection.js
 * @module BadgeSection
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <BadgeSection prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import jays from '../../assets/images/badges/bluejays.png';
import leafs from '../../assets/images/badges/leafs.png';
import raptors from '../../assets/images/badges/raptors.png';
import tfc from '../../assets/images/badges/tfc.png';
import canada from '../../assets/images/badges/canada.png';

function BadgeSection() {

    return (
        <section className="brand-one">
            <div className="container">
                <h4 className="brand-one__title">All year supporters of</h4>
                <div className="brand-container">
                        <img src={canada} alt='Canada National Team' />
                        <img src={jays} alt='Toronto Blue Jays' />
                        <img src={leafs} alt='Toronto Maple Leafs' />
                        <img src={raptors} alt='Torontlo Raptors' />
                        <img src={tfc} alt='TFC' />
                </div>
            </div>
        </section>
    );
}

export default BadgeSection;