/**
 * @file Step12.js
 * @module Step12
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step12 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad';

const Step12 = ({ listingId }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div>
      {/* Step 5: Add Rooms */}
      <div 
        className="form-group" 
        style=
        {{ 
          fontStyle: 'italic', 
          backgroundColor: '#f9f9f9', 
          border: '1px solid #ddd', 
          padding: '15px', 
          borderRadius: '5px', 
          marginBottom: '50px' 
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'brown',
          }}
        >
          Last Step: DocuSign
        </p>
        <p style={{ marginBottom: '10px' }}>
        The day before your property is scheduled to be uploaded to Realtor.ca, you will receive a 'Limited Services Representation Mere Posting Agreement' to be signed virtually via DocuSign to proceed with the listing.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input
            type="checkbox"
            {...register('._mls.step12.finalDocusignAccepted', { required: 'You must agree to the disclaimer.' })}
            style={{ marginRight: '10px' }}
          />
          <label style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            I HEREBY ACKNOWLEDGE AND AGREE
          </label>
        </div>
        {errors._mls?.step12?.finalDocusignAccepted && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errors._mls.step12.finalDocusignAccepted.message}
          </p>
        )}
      </div>
      {/* Legal Disclaimer Section */}
      <div 
        className="form-group" 
        style=
        {{ 
          fontStyle: 'italic', 
          backgroundColor: '#f9f9f9', 
          border: '1px solid #ddd', 
          padding: '15px', 
          borderRadius: '5px', 
          marginBottom: '50px' 
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'brown',
          }}
        >
          Consumer Consent Disclosure
        </p>
        <p style={{ marginBottom: '10px' }}>
        Consumer Disclosure Regarding Signing Documents Electronically, Conducting Business Electronically, and Receiving Electronic Documents, Notices and Disclosures
        <br /><br />
        By utilizing the electronic signature product or service, you are agreeing that you have reviewed the following information, and you consent to utilizing electronic signatures in lieu of using handwritten or non-digital signatures or paper, to conduct business using electronic communications, and to receive notices, signatures, and disclosures electronically. If you have consented to receive future electronic signature invitations or other notices, signatures, or disclosures electronically from RocketListings.ca, and you want to withdraw that consent, you may email us at team@rocketlistings.ca.
        <br /><br />
        I understand and accept that my electronic signature will be as valid as a handwritten signature and considered original to the extent allowed by applicable law.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input
            type="checkbox"
            {...register('._mls.step12.disclaimerAccepted', { required: 'You must agree to the disclaimer.' })}
            style={{ marginRight: '10px' }}
          />
          <label style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            I HEREBY ACKNOWLEDGE AND AGREE
          </label>
        </div>
        {errors._mls?.step12?.disclaimerAccepted && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errors._mls.step12.disclaimerAccepted.message}
          </p>
        )}
      </div>

      {/* Signature Pad */}
      <SignaturePad 
        step={12} 
        listingId={listingId} 
        fieldName="_mls.step12.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step12?.signature}
      />
    </div>
  );
};

export default Step12;