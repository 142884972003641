/**
 * @file Step7.js
 * @module Step7
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires aws-sdk - Module for additional functionalities
 * @requires firebase/firestore - Module for additional functionalities
 * @requires react-router-dom - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step7 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { firestore } from '../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Step7 = ({ listingId }) => {
  const [propertyDoc, setPropertyDoc] = useState(null);
  const [idDoc, setIdDoc] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [fileCount, setFileCount] = useState(0);

  useEffect(() => {
    const fetchListingData = async () => {
      if (listingId) {
        const docRef = doc(firestore, 'listings', listingId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setPaymentIntentId(data.stripe.paymentIntentId);
        }
      }
    };

    fetchListingData();
  }, [listingId]);

  useEffect(() => {
    const nextButton = document.querySelector('.next-btn');
    if (nextButton) {
      if (fileCount < 2) {
        nextButton.classList.add('disabled');
      } else {
        nextButton.classList.remove('disabled');
      }
    }
  }, [fileCount]);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  const fetchUploadedFiles = async () => {
    if (!paymentIntentId) return;
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Prefix: `${paymentIntentId}/owner-verification/`,
    };

    try {
      const data = await s3.listObjectsV2(params).promise();
      const fileUrls = data.Contents.map((item) => ({
        url: `https://${params.Bucket}.s3.amazonaws.com/${item.Key}`,
        key: item.Key,
      }));

      const uniqueFiles = fileUrls.filter((file, index, self) =>
        index === self.findIndex((f) => f.key === file.key)
      );

      setUploadedFiles(uniqueFiles);
      setFileCount(uniqueFiles.length);
    } catch (error) {
      console.error('Error fetching uploaded files:', error);
    }
  };

  useEffect(() => {
    if (paymentIntentId) {
      fetchUploadedFiles();
    }
  }, [paymentIntentId]);

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    if (!file) return;

    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
    if (!allowedTypes.includes(file.type)) {
      setErrorMessage('Invalid file type. Only JPG, JPEG, PNG, and PDF are allowed.');
      return;
    }
    if (file.size > 4 * 1024 * 1024) {
      setErrorMessage('File size must be under 4 MB.');
      return;
    }

    setErrorMessage('');
    setFile(file);
  };

  const uploadToS3 = async (file, label) => {
    if (!file || !paymentIntentId) {
      setErrorMessage('Payment Intent ID is missing. Please try again.');
      return null;
    }
  
    setUploading(true);
    try {
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: `${paymentIntentId}/owner-verification/${Date.now()}-${file.name}`,
        Body: file,
        ContentType: file.type,
      };
  
      const data = await s3.upload(params).promise();
      const fileUrl = data.Location;
      console.log(`${file.name} uploaded successfully: ${fileUrl}`);
  
      // Get the existing _ids field from Firestore
      const docRef = doc(firestore, 'listings', listingId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const existingIds = docSnap.data()?._mls?._ids || {};
  
        // Update Firestore with the new URL, ensuring it's stored as an array of strings
        await updateDoc(docRef, {
          [`_mls._ids.${label}`]: existingIds[label]
            ? [...existingIds[label], fileUrl]
            : [fileUrl],
        });
  
        fetchUploadedFiles(); // Refresh the list of uploaded files
        return fileUrl;
      }
    } catch (error) {
      console.error('Error uploading to S3:', error);
      setErrorMessage('Error uploading file. Please try again.');
      return null;
    } finally {
      setUploading(false);
    }
  };  

  const handleIndividualUpload = async (file, label) => {
    if (!file) {
      setErrorMessage(`Please select a file for ${label} before uploading.`);
      return;
    }

    await uploadToS3(file, label);
  };

  const deleteFile = async (key) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this file?');
    if (!confirmDelete) return;

    try {
      const params = {
        Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
        Key: key,
      };
      await s3.deleteObject(params).promise();
      console.log('File deleted successfully:', key);
      fetchUploadedFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
      setErrorMessage('Error deleting file. Please try again.');
    }
  };

  return (
    <div>
      {/* Property Ownership Verification Section */}
      <div className="form-group" style={{ fontStyle: 'italic', backgroundColor: '#f9f9f9', border: '1px solid #ddd', padding: '15px', borderRadius: '5px', marginBottom: '50px' }}>
        <p style={{ fontWeight: 'bold', marginBottom: '10px', color: 'brown' }}>Property Ownership Verification required:</p>
        <p style={{ marginBottom: '10px' }}>
          Please upload either:<br />
          - a recent property tax bill, <br />
          - legal transfer document, <br />
          - assessment report showing you as an owner.
        </p>

        <div className="form-group" style={{ marginTop: '10px' }}>
          <label><strong>Step 1:</strong> Choose Property Ownership Document (JPG, JPEG, PNG, PDF)</label>
          <br />
          <input type="file" className="form-control" accept=".jpg, .jpeg, .png, .pdf" onChange={(e) => handleFileChange(e, setPropertyDoc)} />
          <br />
          <label><strong>Step 2:</strong> Click 'Upload Property Document'</label>
          <br />
          <button type="button" className="btn btn-primary mt-2" onClick={() => handleIndividualUpload(propertyDoc, 'property-doc')} disabled={uploading || !propertyDoc}>
            {uploading ? 'Uploading...' : 'Upload Property Document'}
          </button>
          {!propertyDoc && errorMessage && <p style={{ color: 'red', fontSize: '0.8rem', marginTop: '5px' }}>Property document is required.</p>}
        </div>
      </div>

      {/* Identity Verification Section */}
      <div className="form-group" style={{ fontStyle: 'italic', backgroundColor: '#f9f9f9', border: '1px solid #ddd', padding: '15px', borderRadius: '5px', marginBottom: '50px' }}>
        <p style={{ fontWeight: 'bold', marginBottom: '10px', color: 'brown' }}>Identity Verification required:</p>
        <p style={{ marginBottom: '10px' }}>
          We also require a copy of a government-issued identification matching the name(s) of each owner on title of the property:
          <br />- Passport, <br />- Driver's License, <br />- Permanent Resident Card, <br />- Citizenship Card.
        </p>

        <div className="form-group" style={{ marginTop: '10px' }}>
          <label><strong>Step 1:</strong> Choose Government Issued ID (JPG, JPEG, PNG, PDF)</label>
          <input type="file" className="form-control" accept=".jpg, .jpeg, .png, .pdf" onChange={(e) => handleFileChange(e, setIdDoc)} />
          <br />
          <label><strong>Step 2:</strong> Click 'Upload Government ID'</label>
          <br />
          <button type="button" className="btn btn-primary mt-2" onClick={() => handleIndividualUpload(idDoc, 'id-doc')} disabled={uploading || !idDoc}>
            {uploading ? 'Uploading...' : 'Upload Government ID'}
          </button>
          {!idDoc && errorMessage && <p style={{ color: 'red', fontSize: '0.8rem', marginTop: '5px' }}>Government ID document is required.</p>}
        </div>
      </div>

      {errorMessage && <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>}

      {/* Display Uploaded Files */}
      <div className="uploaded-files" style={{ marginTop: '20px', marginBottom: '60px' }}>
        <h5>Uploaded Files:</h5>
        <div style={{ marginBottom: '20px' }}>
          <strong>Number of uploaded files:</strong> {fileCount}
        </div>
        {uploadedFiles.length > 0 && (
          <ul>
            {uploadedFiles.map((file, index) => (
              <li key={index}>
                <Link to={file.url} target="_blank" rel="noopener noreferrer">{file.url.split('/').pop()}</Link>
                <Link onClick={() => deleteFile(file.key)} style={{ marginLeft: '10px', color: 'red' }}>Delete</Link>
              </li>
            ))}
          </ul>
        )}
      </div>

      {fileCount < 2 && (
        <p 
          className="error-text"
          style={{
            backgroundColor: 'yellow',
            fontWeight: 'bolder',
            padding: '10px',
            fontSize: '17px',
            letterSpacing: '0.01rem',
          }}
        >
          The Next button is disabled until you upload both Property Ownership and Identity Verification documents.
        </p>
      )}
    </div>
  );
};

export default Step7;