/**
 * @file Step1.js
 * @module Step1
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingType - String representing the type of listing.
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step1 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad'; // Import the reusable signature component
import FormGroup from './FormGroup'; // Import the reusable form group component
import './Steps.css'; // Import custom styles for Step1

const Step1 = ({ listingType, listingId }) => {
  const { register, formState: { errors } } = useFormContext();
  const [numSellers, setNumSellers] = useState(1); // State to track the number of sellers (default 1)
  const [showOtherField, setShowOtherField] = useState(false);

  const renderSellerFields = () => {
    const sellerFields = [];
    for (let i = 1; i <= numSellers; i++) {
      sellerFields.push(
        <FormGroup
          key={i}
          label={`Seller #${i}`}
          helperText="If person, then provide legal first and last name. If company, then provide company name."
          registerName={`_mls.step1.seller${i}`}
          register={register}
          required={i === 1 ? 'Seller #1 is required' : false}
          errors={errors}
          type="text"
        />
      );
    }
    return sellerFields;
  };  

  const handlePossessionChange = (e) => {
    if (e.target.value === 'Other') {
      setShowOtherField(true);
    } else {
      setShowOtherField(false);
    }
  };

  return (
    <div> 
      {/* Street Address */}
      <div className="form-group">
        <label>Property Address</label>
        <input
          type="text"
          className="form-control"
          {...register('propertyAddress', { required: 'Property Address is required' })}
          disabled
        />
        {errors._mls_streetAddress && <p className="error-text">{errors._mls_streetAddress.message}</p>}
      </div>

      {/* Suite/Unit # */}
      <FormGroup
        label="Suite/Unit #"
        helperText="If applicable, do not forget to provide the suite or unit number. If NOT applicable, please enter 'Not applicable'."
        registerName="_mls.step1.suite"
        register={register}
        required="NOTE: If there is no suite number, say Not Applicable"
        errors={errors}
      />
      
      {/* Property Management Company */}
      {(listingType === 'condo-sale' || listingType === 'condo-lease') && (
      <>
      <FormGroup
        label="Property Management Company"
        registerName="_mls.step1.propertyManagementCompany"
        register={register}
        required="Property Management Company is required"
        errors={errors}
      />

      {/* Exposure */}
      <FormGroup
          label="Exposure"
          registerName="_mls.step1.exposure"
          register={register}
          required="Exposure is required"
          errors={errors}
          type="select"
          options={[
            "East", "East West", "North", "North East", 
            "North South", "North West", "South", 
            "South East", "South West", "West"
          ]}
        />

      {/* Balcony */}
      <FormGroup
        label="Balcony"
        registerName="_mls.step1.balcony"
        register={register}
        required="Balcony selection is required"
        errors={errors}
        type="select"
        options={["Enclosed", "Juliette", "None", "Open", "Terrace"]}
      />
      </>
      )}

      {/* Number of Sellers */}
      <div className="form-group">
        <label>How many Owners are listed on the property?</label>
        <small className="form-text helper-text">
          Please select the number of owners for the property. You can list up to three owners.
        </small>
        <select
          className="form-control"
          value={numSellers}
          onChange={(e) => setNumSellers(Number(e.target.value))}
        >
          <option value={1}>One (1) Owner</option>
          <option value={2}>Two (2) Owners</option>
          <option value={3}>Three (3) Owners</option>
        </select>
      </div>

      {/* Render Seller fields dynamically based on the selected number of sellers */}
      {renderSellerFields()}

      {(listingType === 'freehold-sale') && (
      <>
      {/* Fronting On */}
      <FormGroup
        label="Fronting On"
        helperText="The side of the street your property fronts on to."
        registerName="_mls.step1.frontingOn"
        register={register}
        required="Fronting On is required"
        errors={errors}
        type="select"
        options={["East", "North", "South", "West"]}
      />

      {/* Frontage */}
      <FormGroup
        label="Lot Frontage (Feet)"
        registerName="_mls.step1.frontage"
        register={register}
        required="Lot Frontage is required"
        errors={errors}
        type="text"
      />

      {/* Depth */}
      <FormGroup
        label="Lot Depth (Feet)"
        registerName="_mls.step1.lot_depth"
        register={register}
        required="Lot Depth is required"
        errors={errors}
        type="text"
      />
      </>
      )}

      {/* Nearest Cross Street */}
      <FormGroup
        label="Nearest Cross Street"
        helperText="Nearest major intersection."
        registerName="_mls.step1.cross_street"
        register={register}
        required="Nearest Cross Street is required"
        errors={errors}
        type="text"
      />

      {/* Freehold and Condo Sale Fields */}
      {(listingType === 'freehold-sale' || listingType === 'condo-sale') && (
      <>
      {/* Possession Date */}
      <div className="form-group">
      <label>Possession Date</label>
      <small className="form-text helper-text">
        Ideal closing date (move out date) for the seller. It is common to say "30 to 60 days after offer acceptance". 
        This is not a firm date and can be negotiated with the buyer. You can also choose a "Specific date" if you have one in mind (e.g. "April 1").
      </small>
      
      {/* Possession Date Dropdown */}
      <FormGroup
        label="Possession Date"
        registerName="_mls.step1.possessionDate"
        register={register}
        required="Possession Date is required"
        errors={errors}
        type="select"
        options={["30-60 days", "60-90 days", "Other"]}
        onChange={handlePossessionChange}
      />

      {/* Conditional Text Input for 'Other' */}
      {showOtherField && (
        <input
          type="text"
          placeholder="Specify Possession Date"
          className="form-control"
          {...register('._mls.step1.possessionDateOther', { required: 'Please specify the possession date' })}
        />
      )}
    </div>
      </>
      )}

      {/* Approx Square Footage */}
      <FormGroup
        label="Approx Square Footage of House"
        helperText="Main and Upper Floors Only! Do NOT Include Basement. Square footage can be a range (e.g 2,000 to 2,500)."
        registerName="_mls.step1.squareFootage"
        register={register}
        required="Square Footage is required"
        errors={errors}
        type="number"
      />

      {/* Special Designation */}
      <FormGroup
        label="Special Designation"
        helperText="If the building has any special designation, i.e. a heritage property. If not, then select 'Unknown'."
        registerName="_mls.step1.specialDesignation"
        register={register}
        required="Special Designation is required"
        errors={errors}
        type="select"
        options={[
          "Accessibility", 
          "Expropriation", 
          "Heritage", 
          "Landlease", 
          "Other", 
          "Unknown"
        ]}
      />

      {/* Freehold Sale Fields */}
      {(listingType === 'freehold-sale' || listingType === 'condo-sale') && (
        <>
          {/* List Price */}
          <FormGroup
            label="List Price"
            helperText="Pro tip: Avoid overpricing, it can lead to a longer time on the market."
            registerName="_mls.step1.listPrice"
            register={register}
            required="List Price is required"
            errors={errors}
            type="number"
          />

          {/* Applicable */}
          <FormGroup
            label="HST Applicable to Sale Price?"
            helperText='Is HST applicable to the sale price? If yes, the buyer will be responsible for paying taxes if you say "YES". Or "INCLUDED" if the price includes taxes.'
            registerName="_mls.step1.taxincl"
            register={register}
            required="Tax applicable is required"
            errors={errors}
            type="select"
            options={["INCLUDED", "Yes", "No"]}
          />

          {/* Annual Property Tax Amount */}
          <FormGroup
            label="Annual Property Tax Amount"
            helperText="Indicates the taxes paid on property and what year this figure represents. (Usually the most recent tax year)"
            registerName="_mls.step1.propertyTaxes"
            register={register}
            required="Property Tax Amount is required"
            errors={errors}
            type="number"
          />

          {/* Tax Year */}
          <FormGroup
            label="Tax Year"
            helperText="What year does the tax amount above represent?"
            registerName="_mls.step1.taxYear"
            register={register}
            required="Tax Year is required"
            errors={errors}
            type="select"
            options={[
              new Date().getFullYear() - 2, 
              new Date().getFullYear() - 1, 
              new Date().getFullYear(), 
              new Date().getFullYear() + 1
            ]}
          />
        </>
      )}

      {/* Freehold Rent Fields */}
      {(listingType === 'freehold-rent' ||  listingType === 'condo-lease') && (
        <>
          {/* Lease Per Month */}
          <FormGroup
            label="Lease Per Month"
            registerName="_mls.step1.leasePerMonth"
            register={register}
            required="Lease Per Month is required"
            errors={errors}
            type="number"
          />

          {/* Lease Term */}
          <FormGroup
            label="Lease Term"
            registerName="_mls.step1.leaseTerm"
            register={register}
            required="Lease Term is required"
            errors={errors}
            type="select"
            options={[
              "12 Months", 
              "24 Months", 
              "36+ Months", 
              "Month to Month", 
              "Short Term Lease", 
              "Weekly"
            ]}
          />

          {/* Tenant Possession Date */}
          <div className="form-group">
          <label>Tenant Possession Date</label>
          <small className="form-text helper-text">
            Ideal date for the tenant to move-in. It is common to say "Immediate". 
            This is not a firm date and can be negotiated with the tenant. You can also choose a "Specific date" if you have one in mind (e.g. "April 1").
          </small>
          
          {/* Tenant Possession Date Dropdown */}
          <FormGroup
            label="Tenant Possession Date"
            registerName="_mls.step1.possessionDateTenant"
            register={register}
            required="Possession Date is required"
            errors={errors}
            type="select"
            options={["Immediate", "To Be Discussed", "Specific Date"]}
            onChange={handlePossessionChange}
          />

          {/* Conditional Text Input for 'Other' */}
          {showOtherField && (
            <input
              type="text"
              placeholder="Specify Possession Date"
              className="form-control"
              {...register('._mls.step1.possessionDateOtherTenant', { required: 'Please specify the possession date' })}
            />
          )}
        </div>
        </>
      )}

      {(listingType === 'condo-sale' ||  listingType === 'condo-lease') && (
        <>
          {/* Maintenance Fee (Monthly) */}
          <FormGroup
            label="Maintenance Fee (Monthly)"
            registerName="_mls.step1.maintenanceFeeMonthly"
            register={register}
            required="Maintenance Fee (Monthly) is required"
            errors={errors}
            type="number"
          />
        </>
      )}
      
      {/* Portion of Property for Lease */}
      {(listingType === 'freehold-rent') && (
        <>
      <FormGroup
        label="Portion of Property for Lease"
        registerName="_mls.step1.portionForLease"
        register={register}
        required="Portion of Property for Lease is required"
        errors={errors}
        type="select"
        options={[
          "Entire Property", 
          "Basement", 
          "Main Floor", 
          "2nd Floor", 
          "Other"
        ]}
      />
      </>
      )}

      {(listingType === 'condo-sale' || listingType === 'condo-lease') && (
        <>
        {/* Maintenance Fee Inclusions */}
        <h4>What's included in the Maintenance Fee?</h4>
        {/* Heat Included */}
        <FormGroup
          label="Heat Included?"
          registerName="_mls.step1.heatIncluded"
          register={register}
          required="Heat Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Hydro Included */}
        <FormGroup
          label="Hydro Included?"
          registerName="_mls.step1.hydroIncluded"
          register={register}
          required="Hydro Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Water Included */}
        <FormGroup
          label="Water Included?"
          registerName="_mls.step1.waterIncluded"
          register={register}
          required="Water Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Cable TV Included */}
        <FormGroup
          label="Cable TV Included?"
          registerName="_mls.step1.cableTvIncluded"
          register={register}
          required="Cable TV Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Condo Taxes Included */}
        <FormGroup
          label="Condo Taxes Included?"
          registerName="_mls.step1.condoTaxesIncluded"
          register={register}
          required="Condo Taxes Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Air Conditioning Cost Included */}
        <FormGroup
          label="Air Conditioning Cost Included?"
          registerName="_mls.step1.airConditioningCostIncluded"
          register={register}
          required="Air Conditioning Cost Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Common Elements Included */}
        <FormGroup
          label="Common Elements Included?"
          registerName="_mls.step1.commonElementsIncluded"
          register={register}
          required="Common Elements Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Building Insurance Included */}
        <FormGroup
          label="Building Insurance Included?"
          registerName="_mls.step1.buildingInsuranceIncluded"
          register={register}
          required="Building Insurance Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Parking Included */}
        <FormGroup
          label="Parking Included?"
          registerName="_mls.step1.parkingIncluded"
          register={register}
          required="Parking Included selection is required"
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />
        </>
      )}
      
      {/* Lease Inclusions */}
      {(listingType === 'freehold-rent') && (
      <>
        <h4>What's included in the Lease?</h4>
        {/* All Inclusive */}
        <FormGroup
          label="All Inclusive"
          registerName="_mls.step1.allInclusive"
          register={register}
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Cable TV Included */}
        <FormGroup
          label="Cable TV Included"
          registerName="_mls.step1.cableTVIncluded"
          register={register}
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Air Conditioning Cost Included */}
        <FormGroup
          label="Air Conditioning Cost Included"
          registerName="_mls.step1.airConditioningCostIncluded"
          register={register}
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Common Elements Included */}
        <FormGroup
          label="Common Elements Included"
          registerName="_mls.step1.commonElementsIncluded"
          register={register}
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Hydro Included */}
        <FormGroup
          label="Hydro Included"
          registerName="_mls.step1.hydroIncluded"
          register={register}
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Heat Included */}
        <FormGroup
          label="Heat Included"
          registerName="_mls.step1.heatIncluded"
          register={register}
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Water Included */}
        <FormGroup
          label="Water Included"
          registerName="_mls.step1.waterIncluded"
          register={register}
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />

        {/* Parking Included */}
        <FormGroup
          label="Parking Included"
          registerName="_mls.step1.parkingIncluded"
          register={register}
          errors={errors}
          type="select"
          options={["Yes", "No"]}
        />
      </>
    )}
      {/* Add the Signature Pad at the end of Step 1 */}
      <SignaturePad 
        step={1} 
        listingId={listingId} 
        fieldName="_mls.step1.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step1?.signature}
      />
    </div>
  );
};

export default Step1;