/**
 * @file MyAccount.js
 * @module MyAccount
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <MyAccount prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import HeaderUser from '../components/Header/HeaderUser';
import FooterDashboard from '../components/Footer/FooterDashboard';
import Dashboard from '../components/Dashboard/Dashboard';

function MyAccount() {
  return (
    <>
      <HeaderUser />
      <Dashboard />
      <FooterDashboard />
    </>
  );
}

export default MyAccount;