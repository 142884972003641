/**
 * @file Footer.js
 * @module Footer
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library




 * 
 * @example
 * // Example usage:
 * <Footer prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { Link } from 'react-router-dom';
// import footerWidgetImg from '../../assets/images/resources/footer-trucklogo.png';
// import footerMascot from '../../assets/images/resources/footer-mascot.png';

function Footer() {
    return (
        <footer className="site-footer">
            <div className="site-footer__top">
                <div className="container">
                    <div className="site-footer__inner">
                        <div className="site-footer__contact-info">
                            <ul className="site-footer__contact-points list-unstyled">
                                {/* <li>
                                <img src={footerMascot} className="site-footer__mascot" alt="Footer logo" />
                                </li> */}
                                <li>
                                    <div className="content">
                                        <h4>Get your property listed on REALTOR.CA&reg; for a flat fee. No hidden fees, no listing commissions.</h4>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__middle">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="footer-widget__column footer-widget__services">
                                <div className="footer-widget__title-box">
                                    <h3 className="footer-widget__title">Popular links</h3>
                                </div>
                                <ul className="footer-widget__useful-links-list list-unstyled">
                                    <li>
                                        <Link to="">Flat fee listings Toronto</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-md-5">
                            <div className="float-bob-y">
                                <div className="footer-widget__img">
                                <img src={footerWidgetImg} alt="Footer Truck" />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">
                                    &copy; <Link to="https://www.rocketlistings.ca">RocketListings.ca</Link>
                                </p>
                                {/* <ul className="site-footer__bottom-text-two list-unstyled">
                                    <li>
                                        <Link to="privacy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="https://www.chizebrands.com">CHIZE Brands</Link>
                                    </li>
                                </ul> */}
                            </div>
                            <p 
                                className="site-footer__bottom-text" 
                                data-nosnippet
                                style={{paddingBottom: '50px', fontSize: '14px', lineHeight: '1.5'}}
                            >
                                Disclaimer: RocketListings.ca provides private property marketing services only. RocketListings.ca is not a real estate brokerage, broker or sales representative. We do not represent buyers, sellers, tenants or landlords, and we do not trade in real estate. We partner with professional real estate agents in Canada which allow our customers to list their properties for sale or rent on REALTOR.CA&reg;.
                                <br /><br />
                                Any real estate listing-related services described as well as MLS® & REALTOR.CA® listing services are available only through our partnership with active and fully-licensed Canadian real estate agents and members of the Canadian Real Estate Association. 
                                All information is from sources deemed reliable but no warranty or representation is made as to the accuracy thereof and the same is submitted subject to errors, change of price or other conditions prior sale or lease or financing or withdrawal without notice. All information displayed is believed to be accurate but is not guaranteed and should be independently verified. No warranties or representations are made of any kind.
                                <br /><br />
                                REALTOR&reg;, REALTORS&reg;, REALTOR.CA&reg;, MLS&reg;, Multiple Listing Service&reg; and all related graphics are trademarks of REALTOR&reg; Canada Inc. a corporation owned by The Canadian Real Estate Association and the National Association of REALTORS&reg; and are used within this website to identify certain real estate services provided by partner brokerages who are members of the Canadian Real Estate Association.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;