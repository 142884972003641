/**
 * @file Step9.js
 * @module Step9
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step9 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad';

const Step9 = ({ listingId }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div>
      {/* Step 5: Add Rooms */}
      {/* <div
        className="alert alert-warning"
        role="alert"
        style={{ marginTop: '10px', marginBottom: '30px' }}
      >
        Simply click Add Room to add a new room, and be sure to enter room dimensions in Meters.
      </div> */}
      {/* Legal Disclaimer Section */}
      <div 
        className="form-group" 
        style=
        {{ 
          fontStyle: 'italic', 
          backgroundColor: '#f9f9f9', 
          border: '1px solid #ddd', 
          padding: '15px', 
          borderRadius: '5px', 
          marginBottom: '50px' 
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'brown',
          }}
        >
          Marketing Agreement
        </p>
        <p style={{ marginBottom: '10px' }}>
          <strong>BETWEEN: "ROCKETLISTINGS.CA REAL ESTATE MARKETING GROUP", hereinafter referred to as (the "RL") agrees to provide the following products and services to Seller.</strong>             
          <br /><br />
          The Seller has paid, as remuneration, a Service Fee as indicated above by credit card to "RL". In-Trust, a one dollar portion of which will be payable to Shamairah Noufaro, a licensed real estate agent in Ontario, if the Property is sold or leased, by "RL" for the Listing Agent services provided. The Seller acknowledges that the Service Fee is non-refundable.
          <br /><br />
          The Seller agrees that the "RL" will not be held liable in any manner whatsoever for any acts or omissions with respect to advertising by "RL" or any other party, other than by the "RL"'s gross negligence or willful act.
          <br /><br />
          The Seller hereby indemnifies and saves harmless "RL" and/or any of its employees, servants, or sales representatives from any and all claims, liabilities, suits, actions, losses, costs and legal fees caused by, or arising out of, or resulting from the posting of the property information and documents or other information (including, without limitation, photographs, images, graphics, audio and video recordings, virtual tours, drawings, floor plans, architectural designs, artistic renderings, surveys and listing descriptions as aforesaid.
          <br /><br />
          "RL" does not allow for the advertising of any third party services on any online property marketing related web pages created for the Seller by "RL". "RL" reserves the right to alter any property related data entered into our system by the Seller that does not abide by "RL" rules.    
          <br /><br />
          "RL" agrees to provide Seller with the following products and services following the Service Fee being received by "RL" from Seller.
          <br /><br />
          1.  Realtor.ca Listing: The Seller’s property will be listed on Realtor.ca. The Seller’s direct contact information cannot appear on Realtor.ca because of Canadian Real Estate Association rules. Prospective Buyers/Tenants will be directed on the Realtor.ca listing to a property microsite which will contain your direct contact details.
          <br /><br />
          2.  Property Webpage: We provide you with a professional property webpage with your direct contact information to showcase your property to potential buyers/tenants.
          <br /><br />
          3.  Listing Syndication: Your property sale/rental listing may be showcased on partner websites including, but not limited to, Realtor.ca, Homefinder.ca, Ovlix.com, Point2homes.com, partner Listing Brokerage sites and others listed on our website.
          <br /><br />
          4.  Photos on Realtor.ca: Pictures are an important component of marketing a property. We truly showcase your property by allowing up to 20 photos on the monthly, 3, 6 and 12-month plans at a time per listing.
          <br /><br />
          5.  Execution of Services: The marketing material and listing services shall be completed within 72 to 96 hours following the Service Fee being paid by Seller to "RL", although the timeline is not guaranteed. The Service Fee is non-refundable.
          <br /><br />
          <strong>Seller Acknowledgement: The Seller Owner hereby acknowledges that the Seller fully understands the terms of this MARKETING AGREEMENT and have received a true copy of this Agreement on the date stated below.</strong>
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input
            type="checkbox"
            {...register('._mls.step9.disclaimerAccepted', { required: 'You must agree to the disclaimer.' })}
            style={{ marginRight: '10px' }}
          />
          <label style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            I HEREBY ACKNOWLEDGE AND AGREE
          </label>
        </div>
        {errors._mls?.step9?.disclaimerAccepted && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errors._mls.step9.disclaimerAccepted.message}
          </p>
        )}
      </div>

      {/* Signature Pad */}
      <SignaturePad 
        step={9} 
        listingId={listingId} 
        fieldName="_mls.step9.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step9?.signature}
      />
    </div>
  );
};

export default Step9;