/**
 * @file PricingSection.js
 * @module PricingSection
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library




 * 
 * @example
 * // Example usage:
 * <PricingSection prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
// import { Link } from 'react-router-dom'; 

function PricingSection() {
    return (
        <section className="coupon-offer pricing py-5">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="coupon-offer__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">We Won't Be Beat!</span>
                                <h2 className="section-title__title">Pricing</h2>
                            </div>
                            <p className="coupon-offer__text mb-5">Affordable pricing options to ensure you get the most exposure for your property:</p>
                        </div>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <div className="container">
                            <div className="row">

                                {/* Standard Plan */}
                                <div className="col-lg-4">
                                    <div className="card mb-5 mb-lg-0" style={{ borderRadius: '10px', border: 'none' }}>
                                        <div className="card-body">
                                            <h5 className="card-title text-muted text-uppercase text-center">STANDARD 💪</h5>
                                            <h6 className="card-price text-center">$149<span className="period">/6-MONTH LISTING</span></h6>
                                            <hr />
                                            <p style={{ textAlign: 'center' }}>Equivalent to $24.99/month.</p>
                                            <br />
                                            <ul className="fa-ul">
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span><strong>6-month Listing Term</strong></li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Listing on MLS&reg; system</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Listing on REALTOR.ca</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Upload 20 Photos</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Receive All Leads</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Post Open House Dates</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Virtual Submission Process</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Live Support</li>
                                                <li className="text-muted"><span className="fa-li"><i className="fas fa-check"></i></span><strike>MAX Exposure&reg;</strike></li>
                                            </ul>
                                            {/* <div className="d-grid">
                                                <Link className="btn btn-primary text-uppercase" style={{ backgroundColor: '#0056b3', border: 'none' }}>Select Plan</Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                {/* Max Exposure Plan (Highlighted) */}
                                <div className="col-lg-4">
                                    <div className="card mb-5 mb-lg-0" style={{ backgroundColor: '#003366', color: '#f0f0f0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transform: 'scale(1.05)', transition: 'transform 0.3s ease, background-color 0.3s ease', borderRadius: '10px', border: 'none' }}>
                                        <div className="card-body">
                                            <h5 className="card-title text-uppercase text-center" style={{ color: '#f0f0f0' }}>MAX Exposure 🚀</h5>
                                            <h6 className="card-price text-center" style={{ color: '#f0f0f0' }}>$239<span className="period">/12-MONTH LISTING</span></h6>
                                            <hr />
                                            <p style={{ textAlign: 'center' }}>Equivalent to $19.99/month.</p>
                                            <br />
                                            <ul className="fa-ul">
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span><strong>12-month Listing Term (MAX)</strong></li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Listing on MLS&reg; system</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Listing on REALTOR.ca</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span><strong>Upload 40 Photos (MAX)</strong></li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Receive All Leads</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Post Open House Dates</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Virtual Submission Process</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span>Live Support</li>
                                                <li><span className="fa-li"><i className="fas fa-check"></i></span><strong>More Websites (MAX)</strong></li>
                                            </ul>
                                            <p><strong>MAX Exposure gets your listing on even more high-traffic websites such as HomeFinder, Point2Homes, Kijiji, Ovlix, Rentals.ca and other popular platforms in Canada!</strong></p>
                                            <br />
                                            {/* <div className="d-grid">
                                                <Link className="btn btn-primary text-uppercase" style={{ backgroundColor: '#0056b3', border: 'none' }}>Select Plan</Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                {/* Premium Plan */}
                                <div className="col-lg-4">
                                    <div className="card mb-5 mb-lg-0" style={{ borderRadius: '10px', border: 'none', background: 'lightgrey' }}>
                                        <div className="card-body">
                                            <h5 className="card-title text-muted text-uppercase text-center">2.5% COMMISSION 👎</h5>
                                            <h6 className="card-price text-center">$10,000+<span className="period"></span></h6>
                                            <hr />
                                            <p style={{ textAlign: 'center' }}>
                                                Save $10,000+ on the sale of your property by avoiding the 2.5% commission fee charged by a real estate agent to represent the sale of your property.
                                                <br /><br />
                                                The $10,000 commission is based on a 2.5% selling commission on a property that sells for $400,000. 
                                                <br /><br />
                                                Say no to high selling commissions and list your property with us today for a flat fee!
                                                </p>
                                            <br />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PricingSection;