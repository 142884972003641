/**
 * @file HeroServices.js
 * @module HeroServices
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <HeroServices prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import heroImage from '../../assets/images/resources/realtor-canada-flat-fee-service.png';

function HeroServices({ title, subtitle, pointA, pointB, pointC, imageCaption }) {

    return (
        <section className="main-slider">
            <div className="main-slider-bg">
            </div>
            <div className="main-slider__img">
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-7">
                        <div className="main-slider__left">
                            <div className="main-slider__title-box">
                                <span className="main-slider__sub-title">{subtitle}</span>
                                <h2 className="main-slider__title">{title}</h2>
                                <ul className="additional-services-two__points list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>{pointA}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>{pointB}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>{pointC}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="main-slider__right">
                            <div className="main-slider__form-box">
                            <img src={heroImage} alt="" style={{paddingBottom:'10px'}}/>
                                <h3>{imageCaption}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroServices;