/**
 * @file Step3.js
 * @module Step3
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.
 * @param {string} props.listingType - String representing the type of listing.


 * 
 * @example
 * // Example usage:
 * <Step3 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad';
import FormGroup from './FormGroup';

const Step3 = ({ listingId, listingType }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div>
      {/* Total # of Rooms on Main and Upper Floors */}
      <FormGroup
        label="Total Number of Rooms on Main and Upper Floors"
        helperText="INCLUDE: Bedrooms, Offices, Dens, Living Rooms, Dining Rooms, Kitchens, etc. DO NOT include Washrooms."
        registerName="_mls.step3.totalRoomsMainUpper"
        register={register}
        required="Total # of rooms is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      />

      {/* Total # of Rooms in Basement */}
      <FormGroup
        label="Total Number of Rooms in Basement"
        helperText="INCLUDE: Bedrooms, Offices, Dens, Living Rooms, Dining Rooms, Kitchens, etc. DO NOT include Washrooms."
        registerName="_mls.step3.totalRoomsBasement"
        register={register}
        required="Total # of rooms in basement is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      />

      {/* Total # of Bedrooms on Main and Upper Floors */}
      <FormGroup
        label="Total Number of Bedrooms on Main and Upper Floors"
        helperText="How many bedrooms on the main and upper floors?"
        registerName="_mls.step3.totalBedroomsMainUpper"
        register={register}
        required="Total # of bedrooms is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      />

      {/* Total # of Bedrooms in Basement */}
      <FormGroup
        label="Total Number of Bedrooms in Basement"
        registerName="_mls.step3.totalBedroomsBasement"
        register={register}
        required="Total # of bedrooms in basement is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      />

      {/* Total # of Kitchens on Main and Upper Floors */}
      <FormGroup
        label="Total Number of Kitchens on Main and Upper Floors"
        helperText="Do not include kitchens in the basement."
        registerName="_mls.step3.totalKitchensMainUpper"
        register={register}
        required="Total # of kitchens is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5"]}
      />

      {/* Total # of Kitchens in Basement */}
      <FormGroup
        label="Total Number of Kitchens in Basement"
        registerName="_mls.step3.totalKitchensBasement"
        register={register}
        required="Total # of kitchens in basement is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5"]}
      />

      {/* Total # of 2 Piece Washrooms */}
      <FormGroup
        label="Total Number of 2 Piece Washrooms"
        helperText="Half bathrooms. Vanity and Toilet only. NO shower or bathtub."
        registerName="_mls.step3.totalWashrooms2Piece"
        register={register}
        required="Total # of half bathrooms is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      />

      {/* Total # of 3 Piece Washrooms */}
      <FormGroup
        label="Total Number of 3 Piece Washrooms"
        helperText="Includes Vanity, Toilet, and One (1) Shower/Bathtub."
        registerName="_mls.step3.totalWashrooms3Piece"
        register={register}
        required="Total # of 3 piece bathrooms is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      />

      {/* Total # of 4 Piece Washrooms */}
      <FormGroup
        label="Total Number of 4 Piece Washrooms"
        helperText="Includes Vanity, Toilet, One (1) Shower, and One (1) Bathtub."
        registerName="_mls.step3.totalWashrooms4Piece"
        register={register}
        required="Total # of 4 piece bathrooms is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      />

      {/* Total # of 5 Piece Washrooms */}
      <FormGroup
        label="Total Number of 5 Piece Washrooms"
        helperText="Includes a Vanity, Toilet, Shower, Bathtub, and a Second Sink."
        registerName="_mls.step3.totalWashrooms5Piece"
        register={register}
        required="Total # of 5 piece bathrooms is required"
        errors={errors}
        type="select"
        options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
      />


      {/* Other Washrooms */}
      {/* <div className="form-group">
        <label>Other Washrooms</label>
        <input
          type="text"
          className="form-control"
          {...register('._mls.step3.otherWashrooms')}
        />
      </div> */}

      {/* Interior Features */}
      <div className="form-group">
        <label>Interior Features</label>
        <small className="form-text helper-text">
        Choose up to 6 features - only 6 features can be seen on the listing.
        </small>
        <select
          multiple
          className="form-control"
          {...register('._mls.step3.interiorFeatures', { required: 'Interior features are required' })}
        >
          <option value="Accessory Apartment">Accessory Apartment</option>
          <option value="Air Exchanger">Air Exchanger</option>
          <option value="Atrium">Atrium</option>
          <option value="Auto Garage Door Remote">Auto Garage Door Remote</option>
          <option value="Bar Fridge">Bar Fridge</option>
          <option value="Brick & Beam">Brick & Beam</option>
          <option value="Built-In Oven">Built-In Oven</option>
          <option value="Carpet Free">Carpet Free</option>
          <option value="Central Vacuum">Central Vacuum</option>
          <option value="Countertop Range">Countertop Range</option>
          <option value="ERV/HRV">ERV/HRV</option>
          <option value="Floor Drain">Floor Drain</option>
          <option value="Garburator">Garburator</option>
          <option value="Generator - Full">Generator - Full</option>
          <option value="Generator - Partial">Generator - Partial</option>
          <option value="Guest Accommodations">Guest Accommodations</option>
          <option value="In-Law Capability">In-Law Capability</option>
          <option value="In-Law Suite">In-Law Suite</option>
          <option value="Intercom">Intercom</option>
          <option value="None">None</option>
          <option value="On Demand Water Heater">On Demand Water Heater</option>
          <option value="Other">Other</option>
          <option value="Primary Bedroom - Main Floor">Primary Bedroom - Main Floor</option>
          <option value="Propane Tank">Propane Tank</option>
          <option value="Rough-In Bath">Rough-In Bath</option>
          <option value="Sauna">Sauna</option>
          <option value="Separate Heating Controls">Separate Heating Controls</option>
          <option value="Separate Hydro Meter">Separate Hydro Meter</option>
          <option value="Sewage Pump">Sewage Pump</option>
          <option value="Solar Owned">Solar Owned</option>
          <option value="Solar Tube">Solar Tube</option>
          <option value="Steam Room">Steam Room</option>
          <option value="Storage">Storage</option>
          <option value="Storage Area Lockers">Storage Area Lockers</option>
          <option value="Sump Pump">Sump Pump</option>
          <option value="Suspended Ceilings">Suspended Ceilings</option>
          <option value="Trash Compactor">Trash Compactor</option>
          <option value="Upgraded Insulation">Upgraded Insulation</option>
          <option value="Ventilation System">Ventilation System</option>
          <option value="Water Heater">Water Heater</option>
          <option value="Water Heater Owned">Water Heater Owned</option>
          <option value="Water Meter">Water Meter</option>
          <option value="Water Purifier">Water Purifier</option>
          <option value="Water Softener">Water Softener</option>
          <option value="Water Treatment">Water Treatment</option>
          <option value="Wheelchair Access">Wheelchair Access</option>
          <option value="Workbench">Workbench</option>
        </select>
        {errors._mls_interiorFeatures && <p className="error-text">{errors._mls_interiorFeatures.message}</p>}
      </div>

      {/* Interior Features */}
      {(listingType === 'condo-sale' || listingType === 'condo-lease') && (
        <>
          {/* Building Amenities */}
          <div className="form-group">
            <label>Building Amenities (Select up to 6)</label>
            <select
              className="form-control"
              multiple
              size="7"
              {...register('._mls.step3.buildingAmenities', {
                required: 'Please select at least one amenity',
                validate: (value) =>
                  value.length <= 6 || 'You can select up to 6 amenities only',
              })}
            >
              <option value="BBQ’s allowed">BBQ’s allowed</option>
              <option value="Business Centre (Wi Fi Bldg)">Business Centre (Wi Fi Bldg)</option>
              <option value="Car Wash">Car Wash</option>
              <option value="Concierge">Concierge</option>
              <option value="Exercise Room">Exercise Room</option>
              <option value="Games Room">Games Room</option>
              <option value="Guest Suites">Guest Suites</option>
              <option value="Gym">Gym</option>
              <option value="Indoor Pool">Indoor Pool</option>
              <option value="Lap Pool">Lap Pool</option>
              <option value="Media Room">Media Room</option>
              <option value="Outdoor Pool">Outdoor Pool</option>
              <option value="Party Room/Meeting Room">Party Room/Meeting Room</option>
              <option value="Recreation Room">Recreation Room</option>
              <option value="Roof Top Deck/Garden">Roof Top Deck/Garden</option>
              <option value="Satellite Dish">Satellite Dish</option>
              <option value="Sauna">Sauna</option>
              <option value="Security Guard">Security Guard</option>
              <option value="Security System">Security System</option>
              <option value="Squash/Racquet Court">Squash/Racquet Court</option>
              <option value="Tennis Court">Tennis Court</option>
              <option value="Visitor Parking">Visitor Parking</option>
            </select>
            {errors._mls?.step3?.buildingAmenities && (
              <p className="error-text">{errors._mls.step3.buildingAmenities.message}</p>
            )}
          </div>
        </>
      )}

      {/* Family Room */}
      <FormGroup
        label="Family Room"
        registerName="_mls.step3.familyRoom"
        register={register}
        required="Family Room option is required"
        errors={errors}
        type="select"
        options={["Yes", "No"]}
      />

      {/* Basement */}
      <FormGroup
        label="Basement"
        registerName="_mls.step3.basement"
        register={register}
        required="Basement type is required"
        errors={errors}
        type="select"
        options={[
          "Apartment", "Crawl Space", "Finished", "Finished with Walk-Out", "Full", "Half", "None",
          "Other", "Partial Basement", "Partially Finished", "Separate Entrance", "Unfinished",
          "Unfinished Walk-Out", "Walk-Up"
        ]}
      />

      {/* Fireplace/Stove (Operational) */}
      <FormGroup
        label="Fireplace/Stove (Operational)"
        registerName="_mls.step3.fireplaceStove"
        register={register}
        required="Fireplace/Stove option is required"
        errors={errors}
        type="select"
        options={["Yes", "No"]}
      />

      {/* Heat Source */}
      <FormGroup
        label="Heat Source"
        registerName="_mls.step3.heatSource"
        register={register}
        required="Heat Source is required"
        errors={errors}
        type="select"
        options={["Electric", "Gas", "Ground Source", "Oil", "Other", "Propane", "Solar", "Wood"]}
      />

      {/* Heat Type */}
      <FormGroup
        label="Heat Type"
        registerName="_mls.step3.heatType"
        register={register}
        required="Heat Type is required"
        errors={errors}
        type="select"
        options={["Baseboard", "Forced Air", "Heat Pump", "Other", "Radiant", "Water"]}
      />

      {/* Air Conditioning */}
      <FormGroup
        label="Air Conditioning"
        registerName="_mls.step3.airConditioning"
        register={register}
        required="Air Conditioning type is required"
        errors={errors}
        type="select"
        options={["Central Air", "None", "Other", "Wall Unit", "Window Unit"]}
      />

      {(listingType === 'condo-sale' || listingType === 'condo-lease') && (
        <>
          {/* Ensuite Laundry */}
          <FormGroup
            label="Ensuite Laundry"
            registerName="_mls.step3.ensuiteLaundry"
            register={register}
            required="Ensuite Laundry selection is required"
            errors={errors}
            type="select"
            options={["YES", "NO"]}
          />

          {/* Pets Permitted */}
          <FormGroup
            label="Pets Permitted"
            registerName="_mls.step3.petsPermitted"
            register={register}
            required="Pets Permitted selection is required"
            errors={errors}
            type="select"
            options={["Yes", "No", "Restricted"]}
          />

          {/* Locker */}
          <FormGroup
            label="Locker"
            registerName="_mls.step3.locker"
            register={register}
            required="Locker selection is required"
            errors={errors}
            type="select"
            options={[
              "Common", "Ensuite", "Ensuite + Common", "Ensuite + Exclusive", "Ensuite + Owned", 
              "Exclusive", "None", "Owned"
            ]}
          />
        </>
      )}

      {/* Laundry Features */}
      <FormGroup
        label="Laundry Features"
        registerName="_mls.step3.laundryFeatures"
        register={register}
        required="Laundry Features is required"
        errors={errors}
        type="select"
        options={[
          "Coin Operated", "Common Area", "Electric Dryer Hookup", "Ensuite", "Gas Dryer Hookup",
          "In Area", "In Basement", "In Bathroom", "In Building", "In Carport", "In Garage",
          "In Hall", "In Kitchen", "In-Suite Laundry", "Inside", "Laundry Chute", "Laundry Closet",
          "Laundry Room", "Multiple Locations", "None", "Other", "Outside", "Set Usage", "Shared", 
          "Sink", "Washer Hookup"
        ]}
      />

      {/* Furnished */}
      <FormGroup
        label="Furnished"
        registerName="_mls.step3.furnished"
        register={register}
        required="Furnished status is required"
        errors={errors}
        type="select"
        options={["Yes", "No", "Partial"]}
      />

      <SignaturePad 
        step={3} 
        listingId={listingId} 
        fieldName="_mls.step3.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step3?.signature}
      />
    </div>
  );
};

export default Step3;