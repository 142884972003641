/**
 * @file Resources.js
 * @module Resources
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires react-icons/fa - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.email - String representing an email address.
 * @param {listing[]} props.listings - Array of listing objects.


 * 
 * @example
 * // Example usage:
 * <Resources prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { Link } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';

const Resources = ({ email, listings }) => {
    // Common resources for all types
    const commonResources = [
        {
            name: 'How to Handle Showings',
            description: 'Tips on handling property showings.',
            link: '/downloads/how-to-handle-showings.pdf',
        },
        {
            name: 'Hiring a Real Estate Lawyer',
            description: 'What to look for when hiring a real estate lawyer.',
            link: '/downloads/hiring-a-real-estate-lawyer.pdf',
        },
        {
            name: 'Negotiating Tips',
            description: 'Best practices for negotiating property deals.',
            link: '/downloads/negotiating-tips.pdf',
        },
    ];

    // Seller-specific resources (for freehold-sale and condo-sale)
    const sellerResources = [
        {
            name: 'Sales Agreement Template',
            description: 'A sample agreement for selling your property.',
            link: '/downloads/sales-agreement-template.pdf',
        },
    ];

    // Landlord-specific resources (for freehold-rent and condo-lease)
    const landlordResources = [
        {
            name: 'Rental Agreement Template',
            description: 'A sample agreement for leasing your property.',
            link: '/downloads/rental-agreement-template.pdf',
        },
    ];

    // Filter listings for sellers and landlords
    const isSeller = (listing) => listing.type === 'freehold-sale' || listing.type === 'condo-sale';
    const isLandlord = (listing) => listing.type === 'freehold-rent' || listing.type === 'condo-lease';

    const hasSellerListing = listings.some(isSeller);
    const hasLandlordListing = listings.some(isLandlord);

    return (
    <div>
        <h3>Resources</h3>
        <br />
        <div 
            className="listings-grid single-listing"
            style={{ maxWidth: '600px' }}
        >
            <div className="listing-card wide-card">
                <div className="listing-card-header">
                    <h4>Downloadable reference resources:</h4>
                </div>

                {/* Common Resources */}
                <div className="listing-details" style={{ marginBottom: '30px' }}>
                    {hasSellerListing && (
                    <div
                        className="alert alert-warning"
                        role="alert"
                        style={{ marginTop: '10px', marginBottom: '30px' }}
                    >
                        <strong>Remember - ALWAYS hire a real estate lawyer</strong> to handle the sale of your property 
                        as they can ensure a smooth transaction and protect your interests.
                    </div>
                    )}
                    {commonResources.map((resource, index) => (
                        <div key={index} className="resource-item" style={{ marginBottom: '20px' }}>
                            <strong>{resource.name}</strong>
                            <p>{resource.description}</p>
                            <Link to={resource.link} download style={{ textDecoration: 'none', color: '#007bff' }}>
                                <FaDownload style={{ marginRight: '5px' }} />
                                Download
                            </Link>
                        </div>
                    ))}
                </div>

                {/* Seller Resources */}
                {hasSellerListing && (
                    <div className="listing-details" style={{ marginBottom: '30px' }}>
                        {sellerResources.map((resource, index) => (
                            <div key={index} className="resource-item" style={{ marginBottom: '20px' }}>
                                <strong>{resource.name}</strong>
                                <p>{resource.description}</p>
                                <Link to={resource.link} download style={{ textDecoration: 'none', color: '#007bff' }}>
                                    <FaDownload style={{ marginRight: '5px' }} />
                                    Download
                                </Link>
                            </div>
                        ))}
                    </div>
                )}

                {/* Landlord Resources */}
                {hasLandlordListing && (
                    <div className="listing-details">
                        {landlordResources.map((resource, index) => (
                            <div key={index} className="resource-item" style={{ marginBottom: '20px' }}>
                                <strong>{resource.name}</strong>
                                <p>{resource.description}</p>
                                <Link to={resource.link} download style={{ textDecoration: 'none', color: '#007bff' }}>
                                    <FaDownload style={{ marginRight: '5px' }} />
                                    Download
                                </Link>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    </div>
    );
};

export default Resources;