/**
 * @file PropertyQuestionnaire.js
 * @module PropertyQuestionnaire
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library
 * @requires firebase/firestore - Module for additional functionalities

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <PropertyQuestionnaire prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { firestore } from '../../firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5'; 
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Step10 from './Step10';
import Step11 from './Step11';
import Step12 from './Step12';
import Step13 from './Step13';
import './PropertyQuestionnaire.css'; 

const PropertyQuestionnaire = ({ listingId }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [listingType, setListingType] = useState('');
  const [loading, setLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState('Save for Later'); 
  const [showGeneralError, setShowGeneralError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const methods = useForm();
  const { reset, handleSubmit, getValues, trigger } = methods; // Add 'trigger' function

  // Final submission handler
  const onSubmit = async (data) => {
    const isValid = await trigger(); // Validate all fields before submission
    if (isValid) {
      setShowConfirmation(true); // Show confirmation dialog
    } else {
      setShowGeneralError(true); // Show general error if form is not valid
    }
  };

  const confirmSubmit = async () => {
    try {
      setSaveStatus('Processing...');
      const data = getValues(); // Get all form data
  
      // Update 'status' field to 'PROCESSING'
      data.status = 'PROCESSING';
  
      // Save data to Firestore
      const docRef = doc(firestore, 'listings', listingId);
      await setDoc(docRef, data, { merge: true });
  
      console.log('Form submitted successfully with status PROCESSING');
      setSaveStatus('Saved!');
      setTimeout(() => setSaveStatus('Save for Later'), 2000);
  
      // Redirect or notify the user of successful submission here if needed
    } catch (error) {
      console.error('Error submitting form data:', error);
      setSaveStatus('Save for Later');
    } finally {
      setShowConfirmation(false); // Close confirmation dialog
    }
  };  

  useEffect(() => {
    const fetchListingData = async () => {
      if (listingId) {
        setLoading(true);
        const docRef = doc(firestore, 'listings', listingId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setListingType(data.type);
          reset(data);
        }
        setLoading(false);
      }
    };

    fetchListingData();
  }, [listingId, reset]);

  const saveFormData = async (data) => {
    try {
      setSaveStatus('Processing...');
      const docRef = doc(firestore, 'listings', listingId);
      await setDoc(docRef, data, { merge: true });
      console.log('Form data saved successfully');
      setSaveStatus('Saved!');
      setTimeout(() => setSaveStatus('Save for Later'), 2000);
    } catch (error) {
      console.error('Error saving form data:', error);
      setSaveStatus('Save for Later');
    }
  };

  const nextStep = async () => {
    // Save the current form data first
    await saveFormData(getValues());
    
    // Validate the current step fields
    const isValid = await trigger(); 
  
    if (isValid) {
      setShowGeneralError(false);
      setCurrentStep((prev) => (prev < 13 ? prev + 1 : prev)); // Move to the next step
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top
    } else {
      setShowGeneralError(true); // Show the general error if the step is not valid
      // Scroll to the first error field
      const firstErrorElement = document.querySelector('.error-text');
      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : prev));
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top when moving to the previous step
  };

  const jumpToStep = async (step) => {
    const isValid = await trigger(); // Validate the current step fields before jumping
    if (isValid) {
      setCurrentStep(step);
    }
  };

  const steps = ['Basic Information', 'Property Details', 'Interior', 'Rooms', 'Descriptions', 'Image Upload', 'Owner Verification', 'Agent Commission', 'Marketing Agreement', 'Communication', 'Image Certification', 'Final & Consent', 'Review'];

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <Step1 listingId={listingId} listingType={listingType} />;
      case 2:
        return <Step2 listingId={listingId} listingType={listingType} />;
      case 3:
        return <Step3 listingId={listingId} listingType={listingType} />;
      case 4:
        return <Step4 listingId={listingId} listingType={listingType} />;
      case 5:
        return <Step5 listingId={listingId} listingType={listingType} />;
      case 6:
        return <Step6 listingId={listingId} listingType={listingType} />;
      case 7:
        return <Step7 listingId={listingId} listingType={listingType} />;
      case 8:
        return <Step8 listingId={listingId} listingType={listingType} />;
      case 9:
        return <Step9 listingId={listingId} listingType={listingType} />;
      case 10:
        return <Step10 listingId={listingId} listingType={listingType} />;
      case 11:
        return <Step11 listingId={listingId} listingType={listingType} />;
      case 12:
        return <Step12 listingId={listingId} listingType={listingType} />;
      case 13:
        return <Step13 listingId={listingId} listingType={listingType} />;
      default:
        return null;
    }
  };

  return (
    <div className="property-questionnaire-container container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <FormProvider {...methods}>
          {showGeneralError && (
            <div className="alert alert-danger" role="alert">
              Please fill in all required fields before proceeding to the next step.
            </div>
          )}
          <div className="row">
            <aside className="qsidebar col-md-2 bg-light">
              <div className="nav-title mb-3">Jump to:</div>
              <ul className="nav flex-column">
                {steps.map((step, index) => (
                  <li
                    key={index}
                    className={`nav-item ${currentStep === index + 1 ? 'active' : ''}`}
                    onClick={() => jumpToStep(index + 1)}
                  >
                    <a className="nav-link" href="#!">
                      {step}
                    </a>
                  </li>
                ))}
              </ul>
            </aside>

            <main className="step-content col-md-9">
              <form onSubmit={handleSubmit(nextStep)}>
                <h2>{steps[currentStep - 1]}</h2>
                <div className="form-fields">
                  {renderStepContent()}
                </div>

                <div className="navigation-buttons d-flex justify-content-between align-items-center mt-4">
                  <button
                    type="button"
                    className="btn btn-light prev-btn"
                    onClick={prevStep}
                    disabled={currentStep === 1}
                  >
                    Previous
                  </button>

                  <button
                    type="button"
                    className="btn btn-warning save-btn mx-auto"
                    onClick={() => saveFormData(getValues())}
                  >
                    {saveStatus}
                  </button>

                  {currentStep < 13 ? (
                    <button
                      type="submit"
                      className="btn btn-primary next-btn"
                    >
                      Next
                    </button>
                  ) : (
                    <button type="button" className="btn btn-primary submit-btn" onClick={handleSubmit(onSubmit)}>
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </main>
          </div>
        {showConfirmation && (
          <div className="modal-backdrop">
            <div className="modal-content">
              <p>Are you sure you want to submit your property listing?</p>
              <button className="btn btn-success" onClick={confirmSubmit}>Yes, Submit</button>
              <button className="btn btn-secondary" onClick={() => setShowConfirmation(false)}>Cancel</button>
            </div>
          </div>
        )}
        </FormProvider>
      )}
    </div>
  );
};

export default PropertyQuestionnaire;