/**
 * @file Oto.js
 * @module Oto
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires firebase/firestore - Module for additional functionalities
 * @requires react-router-dom - React library




 * 
 * @example
 * // Example usage:
 * <Oto prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { firestore } from '../../firebaseConfig'; // Firestore config
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Firestore methods
import { NavLink } from 'react-router-dom';

function Oto() {
    const [loading, setLoading] = useState(false); // Track loading state
    const [planId, setPlanId] = useState(null); // Track user plan ID
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(600); // 600 seconds = 10 minutes

    // Get the state passed from navigate
    const location = useLocation();
    const { paymentIntentId, userId, planId: initialPlanId } = location.state || {}; // Access the passed state

    useEffect(() => {
      const fetchPlanId = async () => {
          try {
              setLoading(true); // Start loading
              
              // Check if paymentIntentId exists in state
              if (!paymentIntentId) {
                  console.error('No paymentIntentId found in state');
                  alert('No recent order found.');
                  setLoading(false);
                  return;
              }

              const listingRef = doc(firestore, 'listings', paymentIntentId);
              const listingSnap = await getDoc(listingRef);

              if (listingSnap.exists()) {
                  const listingData = listingSnap.data();
                  setPlanId(listingData.plan.id);
              } else {
                  console.error('No listing found for the provided paymentIntentId');
                  alert('No recent order found.');
              }
          } catch (error) {
              console.error('Error fetching plan data:', error);
              alert('Error fetching plan data: ' + error.message);
          } finally {
              setLoading(false); // Stop loading
          }
      };

      fetchPlanId(); 
  }, [paymentIntentId, navigate]);

    useEffect(() => {
        const interval = setInterval(() => {
          setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        return () => clearInterval(interval);
      }, []);
    
      // Function to format time in minutes and seconds
      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
      };
    

      const addBonus = async () => {
        try {
            setLoading(true); // Start loading state
    
            // Use paymentIntentId and userId from state instead of localStorage
            if (!paymentIntentId || !userId) {
                alert('No recent order found.');
                setLoading(false);
                return;
            }
    
            // Send request to backend to add the $50 bonus to the order
            const response = await fetch('https://us-central1-rocketlistings-4820b.cloudfunctions.net/addBonusToOrder', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ paymentIntentId }),
            });
    
            const result = await response.json();
    
            if (result.error) {
                alert(result.error);
            } else {
                alert('Bonus added successfully!');
    
                // Update the listings document with bonus and new plan details
                const listingRef = doc(firestore, 'listings', paymentIntentId);
                await updateDoc(listingRef, {
                    'oto-pro': true, // Mark the OTO as accepted
                    'plan.id': 2,    // Update plan ID to Pro
                    'plan.label': '12-month Listing', // Update plan label to 12-month Listing
                });
    
                // Navigate to the success page
                navigate('/acknowledge');
            }
        } catch (error) {
            alert('Failed to add oto-pro field: ' + error.message);
        } finally {
            setLoading(false); // End loading state
        }
    };    

    // Only show the OTO for Standard users (plan id: 1)
    if (planId === 2) {
        // If user is Pro (plan id: 2), do not show the OTO
        // Navigate to the success page
        navigate('/my-account');
        return null; // Prevent component rendering for Pro users
    }

    return (
        <div style={styles.container}>
          <h1 style={styles.headline}>Wait! Don't Miss This One-Time Offer to Double Your Listing's Exposure!</h1>
          <h3 style={styles.subheadline}>Offer expires: {formatTime(timeLeft)}</h3>
          <div style={styles.offer}>Upgrade to a 12-month listing for only $44 </div>
          
          <p style={styles.description}>
            This is your only chance to claim this exclusive offer as a thank you for purchasing a listing with us. 
            Don't limit the exposure of your listing - take advantage of this limited-time 50% offer!
          </p>
          
          <button 
            style={styles.ctaButton}
            onClick={addBonus} 
            disabled={loading} // Disable while loading
          >
            {loading ? 'Processing...' : 'Claim Your 12-month Upgrade Now'}
          </button>
    
          <p 
            style={{
              marginTop: '0px',
              fontSize: '14px',
              color: '#555',
              fontWeight: 'bold',
            }}
          >
            P.S. This offer does expires in {formatTime(timeLeft)}!
          </p>
    
          <NavLink
            to="/acknowledge"
            style={{
              color: '#007bff',
              textDecoration: 'underline',
              marginTop: '15px',
              display: 'inline-block',
              fontSize: '14px', 
            }}
          >
            No thank you, I want to keep my 6-month listing package...
          </NavLink>
        </div>
      );
    };
    
    // Styling for the component using best practices
    const styles = {
      container: {
        backgroundColor: '#f5f5f5',
        border: '1px solid #ddd',
        borderRadius: '10px',
        padding: '20px',
        maxWidth: '600px',
        margin: '50px auto',
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
      },
      headline: {
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '10px',
      },
      subheadline: {
        fontSize: '18px',
        color: '#555',
        marginBottom: '20px',
      },
      offer: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#e74c3c', // subtle contrast color for urgency
        backgroundColor: '#fff',
        borderRadius: '5px',
        padding: '10px',
        display: 'inline-block',
        marginBottom: '30px',
        marginTop: '20px',
      },
      description: {
        fontSize: '16px',
        color: '#666',
        marginBottom: '30px',
      },
      ctaButton: {
        backgroundColor: '#e67e22', // Call to action stands out
        color: '#fff',
        fontSize: '18px',
        padding: '15px 30px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
      },
    };
    
export default Oto;