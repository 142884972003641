/**
 * @file Step2.js
 * @module Step2
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.
 * @param {string} props.listingType - String representing the type of listing.


 * 
 * @example
 * // Example usage:
 * <Step2 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad';
import FormGroup from './FormGroup';

const Step2 = ({ listingId, listingType }) => {
  const { register, formState: { errors } } = useFormContext();
  const [numCondoParkTypes, setCondoParkTypes] = useState(0);

  const renderSellerFields = () => {
    const sellerFields = [];
    for (let i = 1; i <= numCondoParkTypes; i++) {
      sellerFields.push(
        <div key={i} className="form-group">
          <label>Parking Type for Spot #{i}</label>
          <select
            className="form-control"
            {...register(`._mls.step2.parkingType${i}`, { required: `Parking Type #${i} is required` })}
          >
            <option value="">Please Select</option>
            <option value="Common">Common</option>
            <option value="Compact">Compact</option>
            <option value="Exclusive">Exclusive</option>
            <option value="None">None</option>
            <option value="Owned">Owned</option>
            <option value="Rental">Rental</option>
            <option value="Stacked">Stacked</option>
          </select>
          {errors?._mls?.step2?.[`parkingType${i}`] && (
            <p className="error-text">{errors._mls.step2[`parkingType${i}`].message}</p>
          )}
        </div>
      );
    }
    return sellerFields;
  };

  return (
    <div>
      {/* Occupancy */}
      <FormGroup
        label="Occupancy"
        helperText="Is this an Owner-occupied, Tenant-occupied, Owner-Tenant (if partially rented) or Vacant property?"
        registerName="_mls.step2.occupancy"
        register={register}
        required="Occupancy status is required"
        errors={errors}
        type="select"
        options={["Owner/Tenant", "Owner", "Partial", "Tenant", "Vacant"]}
      />

      {(listingType === 'freehold-sale') && (
        <>
          {/* Parcel of Tied Land (POTL) */}
          <FormGroup
            label="Is the property a Parcel of Tied Land (POTL)?"
            helperText="A POTL is a freehold property that has common areas that are a Common Elements Condominium (“CEC”) with a monthly fee."
            registerName="_mls.step2.potl"
            register={register}
            required="POTL status is required"
            errors={errors}
            type="select"
            options={["Yes", "No"]}
          />
        </>
      )}

      {/* Property Type */}
      {(listingType === 'freehold-sale' || listingType === 'freehold-rent') && (
        <>
          {/* Property Type */}
          <FormGroup
            label="Property Type"
            registerName="_mls.step2.propertyType"
            register={register}
            required="Property Type is required"
            errors={errors}
            type="select"
            options={[
              "Attached/Row/Street Townhouse", "Cottage", "Detached", 
              "Detached-Linked", "Detached with Common Elements", "Duplex", 
              "Farm", "Fourplex", "Link", "Mobile/Trailer", "Multiplex", 
              "Other", "Rural Residential", "Semi-Detached", 
              "Store with Apt/Office", "Triplex", "Vacant Land"
            ]}
          />

          {/* Property Style */}
          <FormGroup
            label="Property Style"
            registerName="_mls.step2.propertyStyle"
            register={register}
            required="Property Style is required"
            errors={errors}
            type="select"
            options={[
              "1 1/2 Storey", "2 Storey", "2 1/2 Storey", "3 Storey", 
              "Backsplit 3 Level", "Backsplit 4 Level", "Backsplit 5 Level", 
              "Bungaloft", "Bungalow", "Bungalow – Raised", "Other", 
              "Sidesplit 3 Level", "Sidesplit 4 Level", "Sidesplit 5 Level"
            ]}
          />
        </>
      )}

      {/* Property Type Condo */}
      {(listingType === 'condo-sale' || listingType === 'condo-lease') && (
        <>
          <FormGroup
            label="Property Type"
            registerName="_mls.step2.propertyTypeCondo"
            register={register}
            required="Property Type is required"
            errors={errors}
            type="select"
            options={[
              "Common Element Condo", "Condo Apartment", "Condo Townhouse", 
              "Co-Op Apartment", "Co-Ownership Apartment", "Detached Condo", 
              "Leasehold Condo", "Locker", "Other", "Parking Space", 
              "Phased Condo", "Semi-Detached Condo", "Time Share", 
              "Vacant Land Condo"
            ]}
          />

          {/* Property Style Condo */}
          <FormGroup
            label="Property Style"
            registerName="_mls.step2.propertyStyleCondo"
            register={register}
            required="Property Style is required"
            errors={errors}
            type="select"
            options={[
              "2-Storey", "3-Storey", "Apartment", "Bachelor/Studio", 
              "Bungaloft", "Bungalow", "Loft", "Multi-Level", "Other", 
              "Stacked Townhouse"
            ]}
          />
        </>
      )}

      {/* /* Freehold and Condo Sale Fields */}
      {(listingType === 'freehold-rent' || listingType === 'condo-lease') && (
        <>
          {/* Private Entrance */}
          <FormGroup
            label="Private Entrance"
            registerName="_mls.step2.privateEntrance"
            register={register}
            required="Private Entrance is required"
            errors={errors}
            type="select"
            options={["Yes", "No"]}
          />
        </>
      )}

      {/* /* Exterior */}
      <FormGroup
        label="Exterior material"
        helperText="Choose the primary material used for the exterior of the property."
        registerName="_mls.step2.exterior"
        register={register}
        required="Exterior is required"
        errors={errors}
        type="select"
        options={[
          "Aluminum Siding", "Board & Batten", "Brick", "Brick Front", 
          "Concrete", "Insulbrick", "Log", "Metal/Steel Siding", "Other", 
          "Shingle", "Stone", "Stucco (Plaster)", "Vinyl Siding", "Wood"
        ]}
      />

      {(listingType === 'freehold-sale' || listingType === 'freehold-rent') && (
        <>
          {/* Foundation Detail */}
          <FormGroup
            label="Foundation Detail"
            registerName="_mls.step2.foundationDetail"
            register={register}
            required="Foundation Detail is required"
            errors={errors}
            type="select"
            options={[
              "Block", "Brick", "Concrete", "Concrete Block", 
              "Insulated Concrete Form", "Not Applicable", "Other", 
              "Perimeter Wall", "Piers", "Post & Pad", "Poured Concrete", 
              "Prefabricated", "Slab", "Steel Frame", "Stone", "Unknown", 
              "Wood", "Wood Frame"
            ]}
          />

          {/* Roof */}
          <FormGroup
            label="Roof"
            registerName="_mls.step2.roof"
            register={register}
            required="Roof is required"
            errors={errors}
            type="select"
            options={[
              "Asphalt Rolled", "Asphalt Shingle", "Cedar", 
              "Fibreglass Shingle", "Flat", "Green", "Membrane", 
              "Metal", "Not Applicable", "Other", "Rolled", "Shake", 
              "Shingles", "Slate", "Solar", "Tar and Gravel", 
              "Tile", "Unknown"
            ]}
          />
        </>
      )}

      {/* Garage Type */}
      <FormGroup
        label="Garage Type"
        helperText="If your Condo has Underground parking, then select Underground. Otherwise, select the closest option."
        registerName="_mls.step2.garageType"
        register={register}
        required="Garage Type is required"
        errors={errors}
        type="select"
        options={[
          "Attached", "Built-In", "Carport", "Detached", "None", 
          "Other", "Underground", "Surface"
        ]}
      />

      {(listingType === 'condo-sale' || listingType === 'condo-lease') && (
        <>
          {/* Parking/Drive */}
          <FormGroup
            label="Parking/Driveway Description"
            helperText="If your Condo has Underground parking, then select Underground. Otherwise, select the closest option."
            registerName="_mls.step2.parkingDriveCondo"
            register={register}
            required="Parking/Drive selection is required"
            errors={errors}
            type="select"
            options={[
              "Facilities", "Mutual", "None", "Other", 
              "Private", "Surface", "Underground"
            ]}
          />

          {/* Number of Garage Spaces */}
          <FormGroup
            label="Number of Garage Spaces Condo"
            helperText="Indicate the number of parking spaces available for the condo unit. For each parking space, a new field will appear below to confirm the parking type."
            registerName="_mls.step2.garageSpacesCondo"
            register={register}
            errors={errors}
            type="select"
            options={[...Array(11).keys()].map(String)} // Generates options from 0 to 10
            onChange={(e) => setCondoParkTypes(Number(e.target.value))} // Dynamic function for changing state
            value={numCondoParkTypes}
      />

          {/* Render parking type fields dynamically based on the selected number of garage spaces */}
          {renderSellerFields()}
        </>
      )}

      {(listingType === 'freehold-sale' || listingType === 'freehold-rent') && (
        <>
          {/* # of Vehicle Garage Spaces */}
          <FormGroup
            label="Garage Parking Spaces"
            helperText="Indicates if there is a garage with the property and the number of vehicles it can hold."
            registerName="_mls.step2.garageSpaces"
            register={register}
            required="Garage Spaces is required"
            errors={errors}
            type="select"
            options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
          />

          {/* Driveway */}
          <FormGroup
            label="Driveway"
            helperText="Indicates the location of parking affiliated with the property."
            registerName="_mls.step2.drive"
            register={register}
            required="Drive is required"
            errors={errors}
            type="select"
            options={[
              "Available", "Circular", "Front Yard (Legal)", "Lane", 
              "Mutual", "None", "Other", "Private", "Private Double", 
              "Right-of-Way"
            ]}
          />

          {/* Number of Driveway Vehicle Parking Spaces */}
          <FormGroup
            label="Number of Driveway Vehicle Parking Spaces"
            helperText="Indicates the number of parking spots affiliated with the property."
            registerName="_mls.step2.driveParkingSpaces"
            register={register}
            required="Drive parking spaces count is required"
            errors={errors}
            type="select"
            options={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
          />

          {/* Pool */}
          <FormGroup
            label="Pool"
            registerName="_mls.step2.pool"
            register={register}
            required="Pool type is required"
            errors={errors}
            type="select"
            options={["None", "Above Ground", "Indoor", "Inground"]}
          />
        </>
      )}

      {/* Water */}
      <FormGroup
        label="Water"
        registerName="_mls.step2.water"
        register={register}
        required="Water type is required"
        errors={errors}
        type="select"
        options={["Both", "Municipal", "None", "Other", "Well"]}
      />

      {/* Sewers */}
      <FormGroup
        label="Sewers"
        registerName="_mls.step2.sewers"
        register={register}
        required="Sewers type is required"
        errors={errors}
        type="select"
        options={["Holding Tank", "None", "Other", "Septic", "Sewer"]}
      />

      {/* Waterfront */}
      <FormGroup
        label="Waterfront"
        registerName="_mls.step2.waterfront"
        register={register}
        required="Waterfront status is required"
        errors={errors}
        type="select"
        options={["Direct", "Indirect", "None"]}
      />

      {(listingType === 'freehold-sale') && (
        <>
          {/* Cable TV */}
          <FormGroup
            label="Cable T.V."
            registerName="_mls.step2.cableTV"
            register={register}
            required="Cable TV status is required"
            errors={errors}
            type="select"
            options={["Yes", "No", "Available"]}
          />

          {/* Hydro */}
          <FormGroup
            label="Hydro"
            registerName="_mls.step2.hydro"
            register={register}
            required="Hydro status is required"
            errors={errors}
            type="select"
            options={["Yes", "No", "Available"]}
          />

          {/* Sewers */}
          <FormGroup
            label="Sewers"
            registerName="_mls.step2.sewersAvailability"
            register={register}
            required="Sewers availability is required"
            errors={errors}
            type="select"
            options={["Yes", "No", "Available"]}
          />

          {/* Gas (Natural) */}
          <FormGroup
            label="Gas (Natural)"
            registerName="_mls.step2.gas"
            register={register}
            required="Gas availability is required"
            errors={errors}
            type="select"
            options={["Yes", "No", "Available"]}
          />

          {/* Municipal Water */}
          <FormGroup
            label="Municipal Water"
            registerName="_mls.step2.municipalWater"
            register={register}
            required="Municipal Water status is required"
            errors={errors}
            type="select"
            options={["Yes", "No", "Available"]}
          />

          {/* Telephone */}
          <FormGroup
            label="Telephone"
            registerName="_mls.step2.telephone"
            register={register}
            required="Telephone availability is required"
            errors={errors}
            type="select"
            options={["Yes", "No", "Available"]}
          />
        </>
      )}

      <SignaturePad 
        step={2} 
        listingId={listingId} 
        fieldName="_mls.step2.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step2?.signature}
      />
    </div>
  );
};

export default Step2;