/**
 * @file MLSDatasheet.js
 * @module MLSDatasheet
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library

 * @param {Object} props - The properties passed to the component
 * @param {object} props.listing - Object containing MLS number, property address, and images. (e.g., { MLS number, property address, and images. })
 * @param {any} props.section - Detailed description for section


 * 
 * @example
 * // Example usage:
 * <MLSDatasheet prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { renderField, formatRooms } from './helpers';
import './MLSDatasheet.css';

const MLSDatasheet = ({ listing, section }) => {
  const stepData = listing._mls?.[`step${section}`];

  return (
    <div className="mls-datasheet">
      <div className="mls-info">
        <h3>MLS Section #{section}</h3>
        {stepData ? (
          Object.keys(stepData)
            .sort((a, b) => a.localeCompare(b)) // Sort field names alphabetically
            .map((key) => {
              const value = stepData[key];

              // Special handling for rooms
              if (key === 'rooms' && Array.isArray(value)) {
                return (
                  <div className="info-item" key={key}>
                    <button className="flag-button">Flag</button>
                    {formatRooms(value)}
                  </div>
                );
              }

              // Render other fields
              return (
                <div className="info-item" key={key}>
                  <button className="flag-button">Flag</button>
                  {renderField(key, value)}
                </div>
              );
            })
        ) : (
          <p>No data available for this section.</p>
        )}
      </div>
    </div>
  );
};

export default MLSDatasheet;