/**
 * @file AdditionalServicesSectionAlt.js
 * @module AdditionalServicesSectionAlt
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <AdditionalServicesSectionAlt prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
// import additionalServicesImage from '../../assets/images/resources/guys-lifting-mattress.png';

function AdditionalServicesSectionAlt() {
    return (
        <section className="additional-services-two">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-8">
                        <div className="additional-services-two__left">
                            <div className="section-title text-left">
                                {/* <span className="section-title__tagline">No job too big, or too small</span> */}
                                <h2 className="section-title__title">All packages include:</h2>
                            </div>
                            {/* <p className="additional-services-two__text">
                                We're happy to remove anything from a single couch to clearing out an entire house or apartment. 
                                No job is too big or too small.
                            </p> */}
                            <div className="additional-services-two__points-box">
                                <ul className="additional-services-two__points list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Listing on REALTOR.ca</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Listing in MLS&reg; system</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Virtual Submission Process</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>24/7 inquiry notifications</p>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="additional-services-two__points list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Open house postings</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Upload up to 40 photos</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Free agreements</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Live Chat Support</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-4 col-lg-5">
                        <div className="additional-services-two__right">
                            <div className="additional-services-two__img">
                                <img src={additionalServicesImage} alt="" />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
}

export default AdditionalServicesSectionAlt;