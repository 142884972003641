/**
 * @file FooterDashboard.js
 * @module FooterDashboard
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library




 * 
 * @example
 * // Example usage:
 * <FooterDashboard prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { Link } from 'react-router-dom';
// import footerWidgetImg from '../../assets/images/resources/footer-trucklogo.png';
// import footerMascot from '../../assets/images/resources/footer-mascot.png';

function FooterDashboard() {
    return (
        <footer className="site-footer">
            <div className="site-footer__bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="site-footer__bottom-inner">
                                <p className="site-footer__bottom-text">
                                    &copy; <Link to="">RocketListings.ca</Link>
                                </p>
                            </div>
                            <p 
                                className="site-footer__bottom-text" 
                                data-nosnippet
                                style={{paddingBottom: '50px', fontSize: '14px', lineHeight: '1.5'}}
                            >
                                Disclaimer: RocketListings.ca provides private property marketing services only. RocketListings.ca is not a real estate brokerage, broker or sales representative. We do not represent buyers, sellers, tenants or landlords, and we do not trade in real estate. We partner with professional real estate agents in Canada which allow our customers to list their properties for sale or rent on REALTOR.CA®.
                                <br /><br />
                                Any real estate listing-related services described as well as MLS® & REALTOR.CA® listing services are available only through our partnership with Shamairah Noufaro, a real estate agent and member of the Canadian Real Estate Association. 
                                All information is from sources deemed reliable but no warranty or representation is made as to the accuracy thereof and the same is submitted subject to errors, change of price or other conditions prior sale or lease or financing or withdrawal without notice. All information displayed is believed to be accurate but is not guaranteed and should be independently verified. No warranties or representations are made of any kind.
                                <br /><br />
                                REALTOR®, REALTORS®, REALTOR.CA®, MLS®, Multiple Listing Service® and all related graphics are trademarks of REALTOR® Canada Inc. a corporation owned by The Canadian Real Estate Association and the National Association of REALTORS® and are used within this website to identify certain real estate services provided by partner brokerages who are members of the Canadian Real Estate Association.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterDashboard;