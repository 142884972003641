/**
 * @file AdminDashboard.js
 * @module AdminDashboard
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <AdminDashboard prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import Admin from '../components/Dashboard/Admin';

function AdminDashboard() {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check for authentication status on component mount
  useEffect(() => {
    const savedAuth = localStorage.getItem('isAuthenticated');
    if (savedAuth === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    if (password === 'yhvh') {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true'); // Save login state
    } else {
      alert('Incorrecto!');
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Clear login state
  };

  return (
    <>
      {!isAuthenticated ? (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}>
          <h1>🚀</h1>
          <br />
          <input
            type="password"
            placeholder="..."
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ padding: '10px', margin: '10px 0', width: '200px' }}
          />
          <br />
          <button 
            onClick={handleLogin} 
            style={{
              padding: '10px 20px',
              cursor: 'pointer'
            }}
          >
            Lift off
          </button>
        </div>
      ) : (
        <div>
          <Admin />
          <button 
            onClick={handleLogout} 
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              padding: '10px 20px',
              cursor: 'pointer'
            }}
          >
            Logout
          </button>
        </div>
      )}
    </>
  );
}

export default AdminDashboard;