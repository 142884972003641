/**
 * @file ListingPage.js
 * @module ListingPage
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires react-icons/fa - React library
 * @requires react-loading-skeleton - React library




 * 
 * @example
 * // Example usage:
 * <ListingPage prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { firestore } from '../../firebaseConfig';
import GoogleMapComponent from './GoogleMapComponent'; // Adjust the path if needed
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import headerLogo from '../../assets/images/resources/logo.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './ListingPage.css';

const ListingPage = () => {
  const { slug } = useParams();
  const [listing, setListing] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const imageContainerRef = useRef(null);

  useEffect(() => {
    const fetchListing = async () => {
      const snapshot = await firestore.collection('listings')
        .where('slug', '==', slug)
        .get();

      if (!snapshot.empty) {
        setListing(snapshot.docs[0].data());
      } else {
        setListing(null);
      }
    };

    fetchListing();
  }, [slug]);

  const handleThumbnailClick = (index) => {
    setCurrentImage(index);
  };

  const handlePrevImage = () => {
    setCurrentImage((prevIndex) =>
      prevIndex === 0 ? listing._mls._images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImage((prevIndex) =>
      prevIndex === listing._mls._images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Keyboard navigation for left and right arrow keys
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        handlePrevImage();
      } else if (event.key === 'ArrowRight') {
        handleNextImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentImage, listing]);

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '(Please Email)';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const formatCurrency = (price) => {
    if (!price) return '';
    return `$${parseInt(price).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  };

  const formatNumberWithCommas = (number) => {
    if (!number) return '';
    return parseInt(number).toLocaleString('en-US');
  };

  return (
    <div className="listing-container">
      {/* Left Column */}
      <div className="left-column">
        <h1 
          className="property-title"
          style={{
            fontSize: '1.5rem',
            paddingBottom: 10,
          }}
        >
          {listing ? listing.propertyAddress : <Skeleton width={300} />}
        </h1>
        <h2 
          className="property-price"
          style={{
            fontSize: '1.3rem',
            paddingBottom: 10,
            fontWeight: 'bolder',
            color: 'rgb(0, 51, 102)',
          }}
        >
          {listing ? (
            <>
              {listing._mls?.step1?.listPrice ? `For sale: ${formatCurrency(listing._mls.step1.listPrice)}` : ''}
              {listing._mls?.step1?.leasePerMonth ? `For lease: ${formatCurrency(listing._mls.step1.leasePerMonth)}` : ''}
            </>
          ) : (
            <Skeleton width={150} />
          )}
        </h2>

        {/* Main Image */}
        <div className="listing-image" ref={imageContainerRef}>
          {listing?._mls?._images ? (
            <img
              src={listing._mls._images[currentImage] || 'https://via.placeholder.com/800x400'}
              alt="Property"
            />
          ) : (
            <Skeleton height={400} />
          )}
        </div>

        {/* Previous and Next Buttons */}
        {listing?._mls?._images && (
          <div className="image-navigation">
            <button onClick={handlePrevImage} className="nav-button">&#8592; Previous</button>
            <button onClick={handleNextImage} className="nav-button">Next &#8594;</button>
          </div>
        )}

        {/* Thumbnails */}
        <div className="thumbnail-container">
          {listing?._mls?._images ? (
            listing._mls._images.map((imageUrl, index) => (
              <img
                key={index}
                src={imageUrl}
                alt={`Thumbnail ${index}`}
                className={`thumbnail ${index === currentImage ? 'active' : ''}`}
                onClick={() => handleThumbnailClick(index)}
              />
            ))
          ) : (
            <Skeleton height={80} width={80} count={3} style={{ marginRight: 10 }} />
          )}
        </div>

        {/* Property Description */}
        <div className="property-description">
          <h3>Description</h3>
          <p>{listing ? listing._mls?.step5?.mainDetails : <Skeleton count={5} />}</p>
        </div>

        {/* Property Information */}
        <div className="property-info-grid">
          <div className="property-info-row">
            <div className="property-info-item">
              Bedrooms: 
              {listing ? `${listing._mls?.step3?.totalBedroomsMainUpper || ''}+${listing._mls?.step3?.totalBedroomsBasement || '0'}` : <Skeleton width={100} />}
            </div>
            <div className="property-info-item">
              Bathrooms: 
              {listing ? `${parseInt(listing._mls?.step3?.totalWashrooms3Piece || 0) + parseInt(listing._mls?.step3?.totalWashrooms4Piece || 0) + parseInt(listing._mls?.step3?.totalWashrooms5Piece || 0)}+${listing._mls?.step3?.totalWashrooms2Piece || '0'}` : <Skeleton width={100} />}
            </div>
          </div>
          <div className="property-info-row">
            <div className="property-info-item">
              Square Footage: 
              {listing ? formatNumberWithCommas(listing._mls?.step1?.squareFootage) : <Skeleton width={100} />}
            </div>
            <div className="property-info-item">
              Property Type: {listing ? listing._mls?.step2?.propertyType : <Skeleton width={100} />}
            </div>
          </div>
        </div>

        {/* Google Map Component */}
        {listing && listing.propertyAddress && (
          <GoogleMapComponent address={listing.propertyAddress} />
        )}

        {/* Disclaimer */}
        <div className="disclaimer">
          <p>
            {listing ? "Disclaimer: All information is from sources deemed reliable but no warranty or representation is made as to the accuracy thereof and the same is submitted subject to errors, change of price or other conditions prior sale or lease or financing or withdrawal without notice. All information displayed is believed to be accurate but is not guaranteed and should be independently verified. No warranties or representations are made of any kind." : <Skeleton count={3} />}
          </p>
        </div>
      </div>

      {/* Right Column */}
      <div className="right-column">
        <div className="sidebar-content">
          <h4
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#333',
              marginBottom: 20,
            }}
          >Interested? Contact {listing ? listing.name || '' : <Skeleton width={200} />} for Showings or More Information:</h4>
          <div className="contact-buttons">
            <p className="contact-btn">
              {listing ? listing.name || '' : <Skeleton width={200} />}
            </p>
            <p className="contact-btn">
              <FaPhoneAlt /> {listing ? formatPhoneNumber(listing.phone) : <Skeleton width={150} />}
            </p>
            <p className="contact-btn">
              <FaEnvelope /> {listing ? listing.email || '(Please Call)' : <Skeleton width={200} />}
            </p>
            <div 
              style={{
                marginTop: 80,
                textAlign: 'center',
              }}
            >
              <Link to="/">
                  <img 
                    src={headerLogo} 
                    width="200" 
                    alt="RocketListing Logo"
                  />
              </Link>
              <p
                style={{
                  fontSize: 16,
                  color: '#333',
                  marginTop: -20,
                  lineHeight: 1.25,
                }}
              >
                RocketListings.ca is a real estate marketing platform and is not a real estate brokerage. For more information, please contact the owner directly.
              </p>
              <p
                style={{
                  fontSize: 16,
                  color: '#333',
                  marginTop: 10,
                }}
              >
                &copy; RocketListings.ca
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Sticky Footer for mobile */}
      <div className="sticky-footer">
        {/* Call to Action Row */}
        <div className="cta-row">
          <p>Interested? &nbsp;Contact owner: <strong>{listing ? listing.name || '' : ''}</strong></p>
        </div>
        
        {/* Contact Information Row */}
        <div className="footer-content">
          {/* Phone Section */}
          <div>
            <p><FaPhoneAlt />&nbsp;&nbsp; {listing ? formatPhoneNumber(listing.phone) : '(Please Call)'}</p>
          </div>
          {/* Email Section */}
          <div>
            <p><FaEnvelope />&nbsp;&nbsp; {listing ? listing.email || '(Please Email)' : '(Please Email)'}</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ListingPage;