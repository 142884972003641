/**
 * @file CommissionRental.js
 * @module CommissionRental
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <CommissionRental prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState } from "react";
import './CommissionCalculator.css'; // Import the CSS file

const CommissionRental = () => {
  const [propertyPrice, setPropertyPrice] = useState(2000); // Start at $2,000
  const commissionRate = 1;

  const handleSliderChange = (e) => {
    setPropertyPrice(e.target.value);
  };

  // Function to format number with commas
  const formatWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculateSavings = () => {
    return formatWithCommas(Math.floor(propertyPrice * commissionRate)); // Remove decimal/cents and format with commas
  };

  return (
    <div
      style={{
        backgroundColor: "#00264d",
        color: "white",
        padding: "100px 10px",
        textAlign: "center",
        width: "100%",
      }}
    >
      <h1 style={{ fontSize: "2.5rem", marginBottom: "30px", color: "white", paddingBottom: "30px" }}>
        How much you can save when you list your rental with us?
      </h1>

      {/* Desktop and Mobile Layout */}
      <div className="calculator-container">
        <div className="property-price-section">
          <div
            style={{
              color: "orange",
              fontSize: "1.5rem",
              fontWeight: "bolder",
              letterSpacing: "1px",
            }}
          >
            MONTHLY RENT/LEASE PRICE
          </div>
          <div
            style={{
              color: "white",
              fontSize: "2.5rem",
              letterSpacing: "1px",
              lineHeight: "3.25rem",
            }}
          >
            ${formatWithCommas(propertyPrice)}
          </div>
        </div>

        <div className="slider-container">
          <input
            type="range"
            min="1000"
            max="5000"
            step="50"
            value={propertyPrice}
            onChange={handleSliderChange}
            style={{
              width: "100%",
              appearance: "none",
              backgroundColor: "#007bff", // Bright blue background
              height: "10px",
              borderRadius: "5px",
              outline: "none",
            }}
          />
        </div>

        <div className="savings-section">
          <div
            style={{
              color: "orange",
              fontWeight: "bolder",
              fontSize: "1.5rem",
              letterSpacing: "1px",
            }}
          >
            COMMISSION<br />SAVINGS
          </div>
          <div
            style={{
              color: "white",
              fontSize: "2.5rem",
              fontWeight: "bolder",
              letterSpacing: "1px",
              lineHeight: "3.25rem",
            }}
          >
            ${calculateSavings()}
          </div>
        </div>
      </div>

      {/* Disclaimer text should be below the slider */}
      <div
        style={{
          color: "white",
          fontStyle: "italic",
          fontSize: "18px",
          marginTop: "10px",
          textAlign: "center", // Ensure it's centered on both mobile and desktop
        }}
      >
        Based on the average rental commission (1-month rent)
      </div>
    </div>
  );
};

export default CommissionRental;