/**
 * @file Step10.js
 * @module Step10
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step10 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad';

const Step10 = ({ listingId }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div>
      {/* Step 5: Add Rooms */}
      {/* <div
        className="alert alert-warning"
        role="alert"
        style={{ marginTop: '10px', marginBottom: '30px' }}
      >
        Simply click Add Room to add a new room, and be sure to enter room dimensions in Meters.
      </div> */}
      {/* Legal Disclaimer Section */}
      <div 
        className="form-group" 
        style=
        {{ 
          fontStyle: 'italic', 
          backgroundColor: '#f9f9f9', 
          border: '1px solid #ddd', 
          padding: '15px', 
          borderRadius: '5px', 
          marginBottom: '50px' 
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'brown',
          }}
        >
          Communications
        </p>
        <p style={{ marginBottom: '10px' }}>
        All our properties are listed through our partner brokerage to gain access to REALTOR.CA®. Because of MLS rules, only a brokerage can list a property on REALTOR.CA®, and only their contact details are listed on REALTOR.CA. We cannot list seller/landlord contact details directly on your REALTOR.CA® listing.
        <br /><br />
        There are three ways a buyer/tenant can contact you through REALTOR.CA®.
        <br /><br />
        1. The first way is by the buyer/tenant calling the brokerage. A receptionist is available 24/7 to answer calls. They would take down the contact details of the lead and send you a system email.
        <br />
        2. The second way is by the buyer/tenant clicking Email Realtor on REALTOR.CA and send a message. An email is sent to the brokerage servers and automatically within 5 minutes; you will receive a system email with the information of the lead. This is an automatic process available 24/7.
        <br />
        3. The third way is by a buyer/tenant clicking Go to Listing on Realtor.ca and seeing your contact details on the property webpage we create for you.
        <br /><br />
        All leads are sent to you regardless if a buyer/tenant contacts the brokerage only or clicks on the link on REALTOR.CA to view your property webpage.
        <br /><br />
        <strong>I have reviewed the information above regarding how REALTOR.CA leads are handled:</strong>
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input
            type="checkbox"
            {...register('._mls.step10.disclaimerAccepted', { required: 'You must agree to the disclaimer.' })}
            style={{ marginRight: '10px' }}
          />
          <label style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            I HEREBY ACKNOWLEDGE AND AGREE
          </label>
        </div>
        {errors._mls?.step10?.disclaimerAccepted && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errors._mls.step10.disclaimerAccepted.message}
          </p>
        )}
      </div>

      {/* Signature Pad */}
      <SignaturePad 
        step={10} 
        listingId={listingId} 
        fieldName="_mls.step10.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step10?.signature}
      />
    </div>
  );
};

export default Step10;