/**
 * @file GoogleMapComponent.js
 * @module GoogleMapComponent
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library

 * @param {Object} props - The properties passed to the component
 * @param {object} props.address - Object containing street, city, state, and zip properties. (e.g., { street, city, state, and zip properties. })


 * 
 * @example
 * // Example usage:
 * <GoogleMapComponent prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
// GoogleMapComponent.js
import React, { useEffect, useRef } from 'react';

const GoogleMapComponent = ({ address }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (!address || !window.google) return;

    // Geocode the address to get latitude and longitude
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const { lat, lng } = results[0].geometry.location;

        // Define map options
        const mapOptions = {
          center: { lat: lat(), lng: lng() },
          zoom: 15,
          mapTypeControl: false,       // Disables the Satellite option
          streetViewControl: false,    // Disables the Street View option
          fullscreenControl: false,    // Disables the Fullscreen option
        };

        // Initialize the map
        const map = new window.google.maps.Map(mapRef.current, mapOptions);

        // Add a marker at the address
        new window.google.maps.Marker({
          position: { lat: lat(), lng: lng() },
          map,
        });
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  }, [address]);

  return (
    <div
      ref={mapRef}
      style={{ width: '100%', height: '400px', marginTop: '20px', border: '1px solid #ccc' }}
    />
  );
};

export default GoogleMapComponent;