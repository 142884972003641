/**
 * @file Listings.js
 * @module Listings
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires firebase/firestore - Module for additional functionalities
 * @requires react-paginate - React library
 * @requires react-select - React library
 * @requires react-loading-skeleton - React library

 * @param {Object} props - The properties passed to the component
 * @param {any} props.selected - Detailed description for selected


 * 
 * @example
 * // Example usage:
 * <Listings prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ListingContainer from './ListingContainer';
import './Listings.css';

const Listings = () => {
  const [listings, setListings] = useState([]);
  const [filteredListings, setFilteredListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterAddress, setFilterAddress] = useState('');
  const [filterMLS, setFilterMLS] = useState('');
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 25;

  useEffect(() => {
    const fetchListings = async () => {
      setLoading(true);
      const db = getFirestore();
      const listingsSnapshot = await getDocs(collection(db, 'listings'));
      const fetchedListings = listingsSnapshot.docs.map(doc => ({
        id: doc.id, 
        ...doc.data()
      }));
      setListings(fetchedListings);
      setFilteredListings(fetchedListings);
      setLoading(false);
    };
    fetchListings();
  }, []);

  // Filter Listings
  useEffect(() => {
    let filtered = listings;

    if (filterStatus) {
      filtered = filtered.filter(listing => listing.status === filterStatus);
    }
    if (filterAddress) {
      filtered = filtered.filter(listing => 
        listing.propertyAddress?.toLowerCase().includes(filterAddress.toLowerCase())
      );
    }
    if (filterMLS) {
      filtered = filtered.filter(listing => 
        listing.mlsNumber?.toLowerCase().includes(filterMLS.toLowerCase())
      );
    }

    setFilteredListings(filtered);
    setCurrentPage(0); // Reset to first page after filter
  }, [filterStatus, filterAddress, filterMLS, listings]);

  // Handle Page Change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Handle Status Change
  const handleStatusChange = (option) => {
    setFilterStatus(option ? option.value : ''); // Handle null option
  };

  // Paginate Listings
  const paginatedListings = filteredListings.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Status Options for Filtering
  const statusOptions = [
    { value: '', label: 'All' },
    { value: 'PROCESSING', label: 'Processing' },
    { value: 'INFO NEEDED', label: 'Info Needed' },
    { value: 'LIVE', label: 'Live' },
    { value: 'NEW', label: 'New' },
  ];

  return (
    <div className="listings-container" style={{ padding: '0' }}>
      {!selectedListing ? (
        <>
          {/* Filters */}
          <br />
          <h4>Listings</h4>
          <br />
          <div className="filters">
            <div className="filter-item">
              <label>Status:</label>
              <Select
                options={statusOptions}
                onChange={handleStatusChange}
                placeholder="Select Status"
                isClearable
              />
            </div>
            <div className="filter-item">
              <label>Address:</label>
              <input
                type="text"
                value={filterAddress}
                onChange={e => setFilterAddress(e.target.value)}
                placeholder="Filter by Address"
              />
            </div>
            <div className="filter-item">
              <label>MLS #:</label>
              <input
                type="text"
                value={filterMLS}
                onChange={e => setFilterMLS(e.target.value)}
                placeholder="Filter by MLS #"
              />
            </div>
          </div>

          {/* Listings Table */}
          <table className="listings-table">
            <thead>
              <tr>
                <th>MLS #</th>
                <th>Status</th>
                <th>Type</th>
                <th>Address</th>
                <th>View More</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                // Display skeletons while loading
                [...Array(itemsPerPage)].map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={80} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={120} /></td>
                    <td><Skeleton width={200} /></td>
                    <td><Skeleton width={80} /></td>
                  </tr>
                ))
              ) : (
                paginatedListings.map(listing => (
                  <tr key={listing.id}>
                    <td>{listing.mlsNumber || '...'}</td>
                    <td>
                      {listing.type === 'condo-sale'
                        ? 'Condo for Sale'
                        : listing.type === 'freehold-sale'
                        ? 'Freehold for Sale'
                        : listing.type === 'freehold-rent'
                        ? 'Freehold for Rent'
                        : listing.type === 'condo-lease'
                        ? 'Condo for Lease'
                        : listing.type.replace(/-/g, ' ').replace(/^./, (str) => str.toUpperCase())}
                    </td>
                    <td>
                      {listing.status === 'PROCESSING'
                        ? 'Processing'
                        : listing.status === 'INFO NEEDED'
                        ? 'Info Needed'
                        : listing.status === 'LIVE'
                        ? 'Live'
                        : listing.status === 'NEW'
                        ? 'New' : '...'
                      }
                    </td>
                    <td>{listing.propertyAddress}</td>
                    <td>
                      <button onClick={() => setSelectedListing(listing)}>View More</button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* Pagination */}
          {!loading && (
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={Math.ceil(filteredListings.length / itemsPerPage)}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          )}
        </>
      ) : (
        <ListingContainer listing={selectedListing} onClose={() => setSelectedListing(null)} />
      )}
    </div>
  );
};

export default Listings;