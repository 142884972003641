/**
 * @file App.js
 * @module App
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires @chakra-ui/react - React library
 * 
 * @example
 * // Example usage:
 * <App prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useEffect } from 'react';
import { useLocation, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { ChakraProvider } from '@chakra-ui/react'; 
// import { ChatProvider } from './components/LiveChat/contexts/ChatContext'; 
// import LiveChat from './components/LiveChat/LiveChat';

// Page Components
import Home from './pages/Home';
import BookNow from './pages/BookNow';
import ThankYou from './pages/ThankYou';
import FAQ from './pages/FAQ';
import HowItWorks from './pages/HowItWorks';
import ServiceBusiness from './pages/ServiceBusiness';
import ServiceSell from './pages/ServiceSell';
import ServiceRental from './pages/ServiceRental';
import Pricing from './pages/Pricing';
import OneTimeOffer from './pages/OneTimeOffer';
import Login from './pages/Login';
import Acknowledgement from './pages/Acknowledgement';
import MyAccount from './pages/MyAccount';
import Questionnaire from './pages/Questionnaire';
import Listing from './pages/Listing';
import AdminDashboard from './pages/AdminDashboard';

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    // <ChakraProvider>
    //   <ChatProvider>
        <Router>
          <ScrollToTop />
          <div className="page-wrapper">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/list-business-for-sale" element={<ServiceBusiness />} />
              <Route path="/list-for-sale" element={<ServiceSell />} />
              <Route path="/list-for-rent" element={<ServiceRental />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/list-now" element={<BookNow />} />
              <Route path="/oto" element={<OneTimeOffer />} />
              <Route path="/success" element={<ThankYou />} />
              <Route path="/login" element={<Login />} />
              <Route path="/acknowledge" element={<Acknowledgement />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/my-account/:listingId" element={<Questionnaire />} />
              <Route path="/listings/:slug" element={<Listing />} />
              <Route path="/yhvh" element={<AdminDashboard />} />
            </Routes>
          </div>
          {/* <LiveChat /> */}
        </Router>
    //   </ChatProvider>
    // </ChakraProvider>
  );
}

export default App;