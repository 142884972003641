/**
 * @file Login.js
 * @module Login
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <Login prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import LoginForm from '../components/Dashboard/LoginForm';

function Login() {
  return (
    <>
    <Header />
    <LoginForm />
        <Footer />
    </>
  );
}

export default Login;