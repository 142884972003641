/**
 * @file Step6.js
 * @module Step6
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires aws-sdk - Module for additional functionalities
 * @requires react-hook-form - React library
 * @requires firebase/firestore - Module for additional functionalities

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step6 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import { useFormContext } from 'react-hook-form';
import { firestore } from '../../firebaseConfig'; // Import Firestore
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableItem from './SortableItem'; // We'll create this component in a moment


const Step6 = ({ listingId }) => {
  const { setValue } = useFormContext();
  const [imageFiles, setImageFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadedUrls, setUploadedUrls] = useState([]); // Store uploaded URLs
  const [paymentIntentId, setPaymentIntentId] = useState(''); // Store paymentIntentId
  const [folderImages, setFolderImages] = useState([]); // Store images from S3 folder
  const [imageLimit, setImageLimit] = useState(40); // Default image limit to 40
  const [upgrading, setUpgrading] = useState(false); // Track upgrade process
  const [isUpgraded, setIsUpgraded] = useState(false); // Track if the plan is upgraded

  // Fetch paymentIntentId from Firestore
  useEffect(() => {
    const fetchListingData = async () => {
      if (listingId) {
        const docRef = doc(firestore, 'listings', listingId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setPaymentIntentId(data.stripe.paymentIntentId); // Set paymentIntentId from Firestore
          
          // Check if plan.id is 1, then set image limit to 20
          if (data.plan?.id === 1) {
            setImageLimit(20);
          } else {
            setImageLimit(40); // Default to 40 if plan.id is not 1
          }
        }
      }
    };

    fetchListingData();
  }, [listingId]);

  // Upgrade function
  // Upgrade function with confirmation alert
const handleUpgrade = async () => {
  const confirmUpgrade = window.confirm(
    "Are you ready to upgrade your listing?"
  );
  
  if (!confirmUpgrade) return; // Exit function if user cancels

  setUpgrading(true); // Set upgrading state to true
  try {
    const response = await fetch('https://us-central1-rocketlistings-4820b.cloudfunctions.net/oneClickUpgrade', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        paymentIntentId,
      }),
    });

    const result = await response.json();
    if (result.success) {
      alert('Upgrade successful! You can now upload up to 40 images.');
      
      // Update Firestore to reflect the new plan details
      const docRef = doc(firestore, 'listings', listingId);
      await updateDoc(docRef, {
        'plan.id': 2,
        'plan.label': '12-month Listing',
      });

      setImageLimit(40); // Update image limit to 40
      setIsUpgraded(true); // Update state to reflect the upgraded plan
    } else {
      alert(`Upgrade failed: ${result.error}`);
    }
  } catch (error) {
    console.error('Error with upgrade:', error);
    alert('An error occurred while upgrading. Please try again.');
  }
  setUpgrading(false); // Reset upgrading state
};

  // Configure AWS S3 credentials
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  // Handle image file selection
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const totalImages = imageFiles.length + files.length;
  
    if (totalImages > imageLimit) {
      setErrorMessage(`You can upload up to ${imageLimit} images.`);
      return;
    }
  
    const validFiles = files.filter((file) => {
      if (file.size > 2 * 1024 * 1024) {
        setErrorMessage('Each file must be smaller than 2MB.');
        return false;
      }
      return true;
    });
  
    setErrorMessage('');
    setImageFiles((prevFiles) => [...prevFiles, ...validFiles]);
  
    // Create thumbnails
    const newThumbnails = validFiles.map((file) => URL.createObjectURL(file));
    setThumbnails((prevThumbnails) => [...prevThumbnails, ...newThumbnails]);
  };  

  // Upload selected images to S3
  const uploadToS3 = async () => {
    setUploading(true);
  
    try {
      // Slice the imageFiles array based on the imageLimit
      const filesToUpload = imageFiles.slice(0, imageLimit - uploadedUrls.length);
  
      const uploadPromises = filesToUpload.map((file, index) => {
        const params = {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: `${paymentIntentId}/${Date.now()}-${file.name}`, // Upload to folder named after paymentIntentId
          Body: file,
          ContentType: file.type,
        };
  
        // Log before upload
        console.log(`Uploading ${file.name} to S3 under folder ${paymentIntentId}...`);
  
        return s3.upload(params).promise()
          .then((data) => {
            console.log(`${file.name} uploaded successfully: ${data.Location}`);
            return data;
          })
          .catch((error) => {
            console.error(`Error uploading ${file.name}:`, error);
            throw error;
          });
      });
  
      const uploadedFiles = await Promise.all(uploadPromises);
  
      // Get uploaded file URLs
      const uploadedImageUrls = uploadedFiles.map((file) => file.Location);
  
      // Save the image URLs in form data
      setValue('._mls._images', uploadedImageUrls);
      setUploadedUrls((prevUrls) => [...prevUrls, ...uploadedImageUrls]); // Store uploaded URLs to display thumbnails
  
      // Clear imageFiles after successful upload
      setImageFiles([]); // Clear the image files to prevent re-uploading
      setThumbnails([]); // Clear thumbnails as well
  
      // Fetch and display the images from the folder
      await fetchFolderImages(); // Refresh the folder images after uploading
    } catch (error) {
      console.error('Error uploading to S3:', error);
      setErrorMessage('Error uploading files');
    }
  
    setUploading(false);
  };  

  // Fetch all images from the S3 folder
  // Fetch all images from the S3 folder
const fetchFolderImages = async () => {
  const params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    Prefix: `${paymentIntentId}/`, // Use paymentIntentId as folder prefix
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    
    // Filter to include only images directly in the `paymentIntentId` folder
    const imageUrls = data.Contents
      .filter(item => item.Key.split('/').length === 2) // Ensure the key only has the format: "paymentIntentId/filename"
      .map(item => `https://${params.Bucket}.s3.amazonaws.com/${item.Key}`);

    setFolderImages(imageUrls); // Store filtered image URLs from the folder
  } catch (error) {
    console.error('Error fetching images from S3:', error);
    setErrorMessage('Error fetching images from the folder');
  }
};


  // Delete image from S3 with confirmation
  const deleteImage = async (url, index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this image?");
    if (!confirmDelete) return; // If user clicks "No, Keep", cancel the deletion

    const fileName = url.split('/').pop(); // Extract the file name from the URL
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      Key: `${paymentIntentId}/${fileName}`, // Use paymentIntentId as folder
    };

    try {
      await s3.deleteObject(params).promise();
      console.log(`${fileName} deleted successfully from S3`);

      // Remove image from UI
      setFolderImages((prevUrls) => prevUrls.filter((_, i) => i !== index));
    } catch (error) {
      console.error(`Error deleting ${fileName}:`, error);
      setErrorMessage('Error deleting image');
    }
  };

  // Handle reordering logic
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = folderImages.findIndex((url) => url === active.id);
      const newIndex = folderImages.findIndex((url) => url === over.id);

      const newOrder = arrayMove(folderImages, oldIndex, newIndex);
      setFolderImages(newOrder);

      // Update the image URLs in the form state if necessary
      setValue('._mls._images', newOrder);
    }
  };

  // Fetch images from folder when paymentIntentId changes
  useEffect(() => {
    if (paymentIntentId) {
      fetchFolderImages();
    }
  }, [paymentIntentId]);

  console.log('Uploaded URLs:', uploadedUrls); // Debugging log

  return (
    <div>
      {imageLimit === 20 && !isUpgraded && (
        <>
        {/* <strong>Important: Your plan only allows up to {imageLimit} images...</strong> */}
          <div
          className="alert alert-danger"
          role="alert"
          style={{ marginTop: '10px', marginBottom: '30px' }}
          >
            <div style={{ marginBottom: '15px' }}>
              <p>
              <strong>Important: Your plan only allows up to {imageLimit} images...</strong>
              <br /><br />
              <strong>Upgrade now to unlock the ability to upload up to 40 images</strong>, extend your listing to a full 12 months, and reach more high-traffic websites for maximum visibility — all for just $89! 
              Just click 'Upgrade Now' and make your listing stand out!
              </p>
              <button 
                className="btn btn-success"
                style={{
                  // backgroundColor: 'black',
                  color: 'white',
                  padding: '12px 45px',
                  marginTop: '18px',
                }}
                onClick={handleUpgrade}
                disabled={upgrading}
              >
                {upgrading ? 'Upgrading...' : 'Upgrade Now!'}
              </button>
            </div>
          </div>
          </>
        )}
      <div
        className="alert alert-warning"
        role="alert"
        style={{ marginTop: '10px', marginBottom: '30px' }}
      >
        <strong>Instructions:</strong>
        <br />
        First, click "Choose Files" and select the images you want to upload. Be sure to only choose up to {imageLimit} images or it will not save!
        <br /><br />
        Then click "Upload Images" and after you see the images, click Save!
      </div>
      {/* File Input */}
      <div className="form-group">
        <label>Upload up to {imageLimit} images (png, jpg, jpeg)</label>
        <input
          type="file"
          className="form-control"
          accept=".png, .jpg, .jpeg"
          multiple
          onChange={handleFileChange}
        />
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>

      {/* Show Selected Thumbnails (Before Upload) */}
      <div className="thumbnails-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {thumbnails.map((thumbnail, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <img
              src={thumbnail}
              alt="Thumbnail"
              className="thumbnail"
              style={{ maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }}
            />
          </div>
        ))}
      </div>

      {/* Upload Button */}
      <div className="form-group mt-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={uploadToS3}
          disabled={uploading || imageFiles.length === 0}
        >
          {uploading ? 'Uploading...' : 'Upload Images'}
        </button>
      </div>

      {/* Photos added successfully */}
      {folderImages.length > 0 && (
        <div className="uploaded-thumbnails-container mt-4">
          <h4>Photos added successfully:</h4>
          <p>Drag and drop to Reorder images.</p>
          <p>Click the X button to delete an image.</p>
          <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={folderImages}
            strategy={horizontalListSortingStrategy}
          >
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {folderImages.map((url, index) => (
                <SortableItem
                key={url}
                id={url}
                url={url}
                index={index}
                deleteImage={deleteImage}
                totalImages={folderImages.length}
              />              
              ))}
            </div>
          </SortableContext>
        </DndContext>
        </div>
      )}
    </div>
  );
};

export default Step6;