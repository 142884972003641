/**
 * @file SignaturePad.js
 * @module SignaturePad
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-signature-canvas - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {any} props.step - Detailed description for step
 * @param {any} props.fieldName - Detailed description for fieldName
 * @param {function} props.register - Function handling registration behavior. (e.g., () => {})
 * @param {string} props.error - String representing error messages related to component or API calls.


 * 
 * @example
 * // Example usage:
 * <SignaturePad prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useFormContext } from 'react-hook-form';

const SignaturePad = ({ step, fieldName, register, error }) => {
  const { setValue, getValues } = useFormContext();
  const signatureRef = useRef(null);

  // Register the field and set up validation
  useEffect(() => {
    if (register && fieldName) {
      register(fieldName, {
        required: 'Signature is required. Please sign then click `Save Signature`.',
        validate: value => value && value.trim() !== '' || 'Signature is required. Please sign then click `Save Signature`.',
      });
    }
  }, [register, fieldName]);

  // Load the signature for the current step if it exists
  useEffect(() => {
    const fetchSignature = async () => {
      const existingSignature = getValues(fieldName); // Get the saved signature for this step
      if (existingSignature && signatureRef.current) {
        // Create a new Image object
        const image = new Image();
        image.onload = () => {
          // Clear the canvas before drawing the image
          signatureRef.current.clear();
          const canvas = signatureRef.current.getCanvas();
          const ctx = canvas.getContext("2d");

          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;
          const imageWidth = 330;
          const imageHeight = 150;

          // Calculate the position to center the image
          const xOffset = (canvasWidth - imageWidth) / 2;
          const yOffset = (canvasHeight - imageHeight) / 2;

          // Draw the image centered on the canvas
          ctx.drawImage(image, xOffset, yOffset, imageWidth, imageHeight);
        };
        image.src = existingSignature; // Load the existing signature data URL
      }
    };

    fetchSignature();
  }, [fieldName, getValues]);

  const clearSignature = () => {
    signatureRef.current.clear(); // Clear the signature pad
  };

  const handleSaveSignature = () => {
    const signature = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    setValue(fieldName, signature); // Save the signature data for this step
  };

  return (
    <div className="form-group">
      <label>Signature for Step {step}</label>
      <SignatureCanvas
        ref={signatureRef}
        penColor="black"
        canvasProps={{ className: 'signature-canvas', width: 500, height: 200 }}
      />
      <div className="mt-2">
        <button type="button" className="btn btn-light" onClick={clearSignature}>
          Clear
        </button>
        <button type="button" className="btn btn-primary" style={{ marginLeft: '20px' }} onClick={handleSaveSignature}>
          Save Signature
        </button>
      </div>
      {/* Hidden input to store the signature */}
      <input type="hidden" name={fieldName} />
      {/* Display error message */}
      {error && (
        <p 
        className="error-text"
        style={{
          backgroundColor: 'yellow',
          fontWeight: 'bolder',
          marginTop: '10px',
          padding: '10px',
          fontSize: '17px',
          letterSpacing: '0.01rem',
        }}
      >
          {error.message}
        </p>
      )}
    </div>
  );
};

export default SignaturePad;