/**
 * @file Step13.js
 * @module Step13
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step13 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad';

const Step13 = ({ listingId }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div>
      {/* Step 5: Add Rooms */}
      {/* <div
        className="alert alert-warning"
        role="alert"
        style={{ marginTop: '10px', marginBottom: '30px' }}
      >
        Simply click Add Room to add a new room, and be sure to enter room dimensions in Meters.
      </div> */}
      {/* Legal Disclaimer Section */}
      <div 
        className="form-group" 
        style=
        {{ 
          fontStyle: 'italic', 
          backgroundColor: '#f9f9f9', 
          border: '1px solid #ddd', 
          padding: '15px', 
          borderRadius: '5px', 
          marginBottom: '50px' 
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'brown',
          }}
        >
          Image Upload Certification
        </p>
        <p style={{ marginBottom: '10px' }}>
        I hereby certify the following with respect to the images, photographs, visual recordings or created graphics, rendering, floor plans or other digital content ( collectively "Images") to the multiple listing organization ( including its parents, affiliates, subsidiaries, successors, and assigns ) ( collectively, "MLO" ) to which I am uploading the Images:
        <br /><br />
        - For Participants ( and those acting under the authority of Participants )
        <br /><br />
        - For those images created by me, I hereby grant to MLO, or, if applicable, certify and confirm a prior grant that I have made to MLO in the End user License Agreement (EULA), an irrevocable, perpetual, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to use, reproduce, modify, resize, adapt, prepare derivative works of, distribute, and display the Images for any lawful purpose, and to modify, add to, or strip out the medadata contained within the Images ( collectively the "License" ). For those images created by others, I further certify and confirm that I am the copyright holder of the images or have permission from the copyright holder of the images to grant to MLO the License.
        <br /><br />
        - For Photographers / Service Providers of Participants
        <br /><br />
        - As a photographer or other service provider engaged by a Participant to create images, I hereby grant to MLO an irrevocable, perpetual, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to use, reproduce, modify, resize, adapt, prepare derivative works or, distribute, and display the images for any lawful purpose, and to modify, add to, or strip out the metadata contained within the images.
        <br /><br />
        <strong>IF YOU CANNOT CERTIFY THAT YOU OWN THE IMAGE RIGHTS, DO NOT UPLOAD THAT IMAGE. MLO RESERVES THE RIGHT TO REJECT OR REMOVE IMAGES FOR ANY REASON.</strong>
        <br /><br />
        The Seller/Landlord hereby confirms that they have paid for and own the property photos they have uploaded to the RocketListings.ca website to be used on the MLS. 
        <br /><br />
        Property photos cannot be taken from a previous MLS listing unless you have obtained written consent from the listing agent to use the photos with your listing.
        <br /><br />
        Unlawful use of photos can result in your listing being removed without notice and you being fined up to $2,500.
        <br /><br />
        <strong>I have reviewed the information above regarding the Image Upload Certification.</strong>
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input
            type="checkbox"
            {...register('._mls.step13.disclaimerAccepted', { required: 'You must agree to the disclaimer.' })}
            style={{ marginRight: '10px' }}
          />
          <label style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            I HEREBY ACKNOWLEDGE AND AGREE
          </label>
        </div>
        {errors._mls?.step13?.disclaimerAccepted && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errors._mls.step13.disclaimerAccepted.message}
          </p>
        )}
      </div>

      {/* Signature Pad */}
      <SignaturePad 
        step={13} 
        listingId={listingId} 
        fieldName="_mls.step13.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step13?.signature}
      />
    </div>
  );
};

export default Step13;