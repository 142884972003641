/**
 * @file ThankYou.js
 * @module ThankYou
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <ThankYou prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import ThankYouSection from '../components/ThankYou/ThankYouSection';

function ThankYou({ locationInfo }) {
  return (
    <div>
        <ThankYouSection />
    </div>
  );
}

export default ThankYou;