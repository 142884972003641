/**
 * @file FormGroup.js
 * @module FormGroup
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.label - String for form field labeling.
 * @param {string} props.helperText - String for form field helper text.
 * @param {string} props.registerName - String for registration name.
 * @param {function} props.register - Function handling registration behavior. (e.g., () => {})
 * @param {any} props.required = 'This field is required' - Detailed description for required = 'This field is required'
 * @param {any} props.// Default required message
  errors - Detailed description for // Default required message
  errors
 * @param {any} props.type = 'text' - Detailed description for type = 'text'
 * @param {any} props.options = [] - Detailed description for options = []
 * @param {any} props.disabled = false - Detailed description for disabled = false
 * @param {function} props.onChange - Function invoked when input changes, updating state or form data. (e.g., () => {})
 * @param {number} props.maxLength - Number indicating max character length.
 * @param {any} props.// Adding maxLength for textarea
  rows - Detailed description for // Adding maxLength for textarea
  rows
 * @param {any} props.// Adding rows for textarea - Detailed description for // Adding rows for textarea


 * 
 * @example
 * // Example usage:
 * <FormGroup prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';

const FormGroup = ({
  label,
  helperText,
  registerName,
  register,
  required = 'This field is required', // Default required message
  errors,
  type = 'text',
  options = [],
  disabled = false,
  onChange,
  maxLength, // Adding maxLength for textarea
  rows,      // Adding rows for textarea
}) => {
  // Check if registerName is defined
  if (!registerName) {
    console.error("registerName is undefined or missing in FormGroup component");
    return null; // Or render some fallback UI
  }

  // Access the error using the nested path of registerName
  const error = registerName
    .split('.')
    .reduce((acc, key) => acc && acc[key], errors);

  // Debugging: Log the error object to check if it's present
  console.log('Error for:', registerName, '=>', error);

  return (
    <div className="form-group">
      <label>{label}</label>
      {helperText && <small className="form-text helper-text">{helperText}</small>}

      {type === 'select' ? (
        <select
          className="form-control"
          {...register(registerName, { required })}
          onChange={onChange}
        >
          <option value="">Please Select</option>
          {options.map((option, idx) => (
            <option key={idx} value={option}>
              {option}
            </option>
          ))}
        </select>
      ) : type === 'textarea' ? (
        <textarea
          className="form-control"
          {...register(registerName, { required })}
          disabled={disabled}
          rows={rows}        // Apply rows for textarea
          maxLength={maxLength} // Apply maxLength for textarea
          onChange={onChange}
        />
      ) : (
        <input
          type={type}
          className="form-control"
          {...register(registerName, { required })}
          disabled={disabled}
          onChange={onChange}
        />
      )}

      {/* Display error messages */}
      {error && (
        <p 
          className="error-text"
          style={{
            backgroundColor: 'yellow',
            fontWeight: 'bolder',
            marginTop: '10px',
            padding: '10px',
            fontSize: '17px',
            letterSpacing: '0.01rem',
          }}
        >
          {error.message || required}
        </p>
      )}
    </div>
  );
};

export default FormGroup;