/**
 * @file index.js
 * @module index
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-dom/client - React library




 * 
 * @example
 * // Example usage:
 * <index prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import App from './App';
import './assets/css/fonts.css';
import './assets/vendors/bootstrap/css/bootstrap.min.css';
import './assets/vendors/animate/custom-animate.css'; //floating mascot, pulsating video play button
import './assets/vendors/hauzo-icons/style.css';
import './assets/css/style.css'; 
import './assets/css/responsive.css'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// reportWebVitals(console.log)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();