/**
 * @file BookNow.js
 * @module BookNow
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <BookNow prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import AdditionalServicesSectionAlt from '../components/Services/AdditionalServicesSectionAlt';
import PricingSection from '../components/Coupons/PricingSection';
import WideCtaSection from '../components/CTA/WideCtaSection';
import CommissionCalculator from '../components/CTA/CommissionCalculator';
import HowItWorksSection from '../components/HowItWorks/HowItWorksSection';
import SocialProof from '../components/SocialProof/SocialProof';
import ExitIntentPopup from '../components/ExitIntentPopup/ExitIntentPopup';
import CheckoutForm from '../components/CheckoutForm/CheckoutForm';

function BookNow() {
  return (
    <>
      <Header />
      <CheckoutForm />
      <AdditionalServicesSectionAlt />
      <PricingSection />
      <CommissionCalculator />
      <HowItWorksSection />
      <WideCtaSection />
      <Footer />
      <SocialProof />
      <ExitIntentPopup />
    </>
  );
}

export default BookNow;