/**
 * @file ExitIntentPopup.js
 * @module ExitIntentPopup
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <ExitIntentPopup prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import './ExitIntentPopup.css'; // External CSS for better control

const ExitIntentPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Check if popup has already been shown in this session
    if (sessionStorage.getItem('exitIntentPopupShown')) return;
  
    // Check the current URL to prevent the popup on certain pages
    const currentURL = window.location.href.toLowerCase();
    const blockedURLs = ['thanks', 'thank-you', 'confirmation', 'confirm'];
    const isBlockedPage = blockedURLs.some((keyword) => currentURL.includes(keyword));
    
    if (!isBlockedPage) {
      const handleMouseMove = (event) => {
        const { clientY } = event;
        
        // Double-check session storage here to avoid multiple triggers
        if (clientY <= 10 && !sessionStorage.getItem('exitIntentPopupShown')) {
          setShowPopup(true);
          sessionStorage.setItem('exitIntentPopupShown', 'true'); // Mark as shown
        }
      };
  
      // Add mouse movement event listener
      document.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        // Clean up the event listener on component unmount
        document.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []);  

  const handleClose = () => {
    setShowPopup(false); // Close the popup
  };

  return (
    <>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <h1 className="headline">Leaving because your property isn't ready yet? Here's a free tip...</h1>
            <p className="subheadline">🔥 Pro Tip: <strong>Save time by creating your listing now then schedule it for when your property is ready.</strong> 
            <br />
            - RocketListings.ca
            </p>
            <button className="cta-button" onClick={handleClose}>
              Got it! Thanks!
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ExitIntentPopup;