/**
 * @file ListingContainer.js
 * @module ListingContainer
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires firebase/firestore - Module for additional functionalities
 * @requires react-loading-skeleton - React library
 * @requires jszip - Module for additional functionalities
 * @requires file-saver - Module for additional functionalities

 * @param {Object} props - The properties passed to the component
 * @param {object} props.listing - Object containing MLS number, property address, and images. (e.g., { MLS number, property address, and images. })
 * @param {function} props.onClose - Function to close the modal. (e.g., () => {})


 * 
 * @example
 * // Example usage:
 * <ListingContainer prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getFirestore, collection, addDoc, getDocs, query, where, Timestamp, doc, updateDoc } from 'firebase/firestore';
import Skeleton from 'react-loading-skeleton';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import 'react-loading-skeleton/dist/skeleton.css';
import ListingDetails from './ListingDetails';
import MLSDatasheet from './MLSDatasheet';
import './ListingContainer.css';

const ListingContainer = ({ listing, onClose }) => {
  const [activeSection, setActiveSection] = useState('details');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [mlsNumber, setMlsNumber] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate hook

  const db = getFirestore();

  // Fetch messages related to the listing
  useEffect(() => {
    const fetchMessages = async () => {
      if (!listing?.stripe?.paymentIntentId) return;

      setIsLoading(true);

      const messagesRef = collection(db, 'messages');
      const q = query(messagesRef, where('paymentIntentId', '==', listing.stripe.paymentIntentId));
      const querySnapshot = await getDocs(q);

      const fetchedMessages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort messages by dateSent in descending order
      const sortedMessages = fetchedMessages.sort(
        (a, b) => b.dateSent.toDate() - a.dateSent.toDate()
      );

      setMessages(sortedMessages);
      setIsLoading(false);
    };

    fetchMessages();
  }, [listing?.stripe?.paymentIntentId, db]);

  // Handle message submission
  const handleSendMessage = async () => {
    if (!message.trim() || !listing?.stripe?.paymentIntentId || !listing?.email) return;

    const newMessage = {
      paymentIntentId: listing.stripe.paymentIntentId,
      sentTo: listing.email,
      user: 'admin',
      message,
      status: 'open',
      address: listing.propertyAddress,
      dateSent: Timestamp.now(),
    };

    try {
      await addDoc(collection(db, 'messages'), newMessage);
      setMessages(prevMessages => [newMessage, ...prevMessages]); // Add new message to top
      setMessage(''); // Clear input after sending
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // Handle mark message as closed
const handleMarkAsClosed = async (messageId) => {
  try {
    if (!messageId) {
      console.error('Invalid messageId:', messageId);
      return;
    }

    // Create a document reference to the message
    const messageRef = doc(db, 'messages', messageId);

    // Update the status to 'closed'
    await updateDoc(messageRef, { status: 'closed' });

    // Update state to reflect the change
    setMessages(prevMessages => 
      prevMessages.map(msg => 
        msg.id === messageId ? { ...msg, status: 'closed' } : msg
      )
    );
    console.log(`Message ${messageId} marked as closed.`);
  } catch (error) {
    console.error('Error marking message as closed:', error);
  }
};

// Function to download all images as a zip
const downloadAllImages = async () => {
  const zip = new JSZip();
  const imageFolder = zip.folder("images");

  // Fetching images and adding them to the zip folder
  const imagePromises = listing._mls?._images?.map(async (imageUrl, index) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const fileName = `image_${index + 1}.jpg`;
    imageFolder.file(fileName, blob);
  });

  // Wait for all image fetching to complete
  await Promise.all(imagePromises);

  // Generate the zip file and prompt download
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, "listing_images.zip");
  });
};

const handleSubmitMLS = async () => {
  if (!mlsNumber.trim()) {
    alert('Please enter a valid MLS Number.');
    return;
  }

  try {
    const listingRef = doc(db, 'listings', listing.id);
    const today = new Date();
    const dateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    await updateDoc(listingRef, {
      mlsNumber: mlsNumber.trim(),
      status: 'LIVE',
      mlsListingDate: dateOnly, // Add 'mlsListingDate' field with current day
    });

    alert('MLS Number updated and status set to LIVE.');

    // Clear input after submission
    setMlsNumber(''); 

    // Refresh the page and navigate to
    navigate('/yhvh', { replace: true });
    window.location.reload(); // Refresh the page

  } catch (error) {
    console.error('Error updating MLS Number:', error);
  }
};

  return (
    <div className="listing-container" style={{ boxShadow: 'none', border: 'none', margin: 0, padding: '30px 0px' }}>
      {/* Sidebar */}
      <aside className="sidebar">
        <div className="sidebar-header">
          <h2 style={{ color: 'lightgrey', fontSize: '14px', fontWeight: '300', margin: '0', paddingBottom: '10px' }}>
            MLS DATASHEET
          </h2>
        </div>
        <nav className="menu">
          <ul>
            <li
              className={activeSection === 'direct-message' ? 'active' : ''}
              onClick={() => setActiveSection('direct-message')}
            >
              🚀 Direct Message
            </li>
            <li
              className={activeSection === 'details' ? 'active' : ''}
              onClick={() => setActiveSection('details')}
            >
              Listing Overview
            </li>
            {[...Array(5)].map((_, index) => (
              <li
                key={index}
                className={activeSection === `section-${index + 1}` ? 'active' : ''}
                onClick={() => setActiveSection(`section-${index + 1}`)}
              >
                Section {index + 1}
              </li>
            ))}
            <li
              className={activeSection === 'photos' ? 'active' : ''}
              onClick={() => setActiveSection('photos')}
            >
              Photos
            </li>
            <li
              className={activeSection === 'id-verification' ? 'active' : ''}
              onClick={() => setActiveSection('id-verification')}
            >
              ID Verification
            </li>
            <li
              className={activeSection === 'submit-finalize' ? 'active' : ''}
              onClick={() => setActiveSection('submit-finalize')}
            >
              Submit & Finalize
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main Content */}
      <div className="content" style={{ background: 'none' }}>
        {activeSection === 'details' && <ListingDetails listing={listing} />}
        {[...Array(5)].map((_, index) => (
          activeSection === `section-${index + 1}` && (
            <MLSDatasheet key={index} listing={listing} section={index + 1} />
          )
        ))}
        {activeSection === 'photos' && (
          <div className="photos-section">
            <button
              onClick={downloadAllImages}
              className="download-all-btn"
              style={{
                marginBottom: '1rem',
                padding: '0.5rem 1rem',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Download All
            </button>
            <div className="thumbnails-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {listing._mls?._images?.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt=""
                  style={{ maxWidth: '150px', maxHeight: '150px', objectFit: 'cover', marginBottom: '10px' }}
                />
              ))}
            </div>
          </div>
        )}
        {activeSection === 'id-verification' && (
          <div className="id-verification-section">
            <h4>Uploaded Documents:</h4>
            <br />
            <ul>
              {listing._mls?._ids?.['id-doc']?.map((url, index) => (
                <li key={index}>
                  <Link to={url} target="_blank" rel="noopener noreferrer">
                    ID Document {index + 1}
                  </Link>
                </li>
              ))}
              {listing._mls?._ids?.['property-doc']?.map((url, index) => (
                <li key={index}>
                  <Link to={url} target="_blank" rel="noopener noreferrer">
                    Property Document {index + 1}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Direct Message Section */}
        {activeSection === 'direct-message' && (
          <div className="direct-message-section">
            <h4>Send direct message to {listing.name}:</h4>
            <p>You can ask to have new photos or files emailed to hello@rocketlistings.ca</p>
            <br />
            <textarea
              value={message}
              onChange={e => setMessage(e.target.value)}
              placeholder="Type your direct message here..."
              rows="3"
              style={{ width: '70%', marginTop: '10px', padding: '10px' }}
            />
            <br />
            <button
              onClick={handleSendMessage}
              style={{ marginTop: '10px', padding: '10px 20px', cursor: 'pointer' }}
              disabled={!message.trim()}
            >
              Send
            </button>

            <div className="message-log" style={{ marginTop: '50px' }}>
              <h5>Previous logs:</h5>
              <br />
              {isLoading ? (
                Array(5).fill(0).map((_, index) => (
                  <div key={index} style={{ padding: '10px 0', borderBottom: '1px solid #ccc' }}>
                    <Skeleton height={20} width={`80%`} style={{ marginBottom: '5px' }} />
                    <Skeleton height={15} width={`60%`} />
                  </div>
                ))
              ) : messages.length > 0 ? (
                messages.map((msg, index) => (
                  <div key={index} className="message-item" style={{ padding: '10px 0', borderBottom: '1px solid #ccc' }}>
                    <strong>
                      <span style={{ color: msg.user === 'admin' ? 'darkred' : 'red' }}>
                        {msg.user === 'admin' ? 'RocketListings:' : 'User'}
                      </span>
                    </strong>&nbsp; {msg.message}
                    <br />
                    <small>({msg.dateSent.toDate().toLocaleString()})</small>
                    {msg.status === 'open' && (
                      <p
                        onClick={() => handleMarkAsClosed(msg.id)}
                        style={{ color: 'blue', cursor: 'pointer', marginTop: '5px' }}
                      >
                        Mark as Closed
                      </p>
                    )}
                  </div>
                ))
              ) : (
                <p>No messages yet.</p>
              )}
            </div>
          </div>
        )}

        {/* SUBMIT/FINALIZE MLS SECTION */}
        {activeSection === 'submit-finalize' && (
        <div className="submit-finalize-section">
          <h4>Enter MLS Number to Submit & Finalize:</h4>
          <input
            type="text"
            value={mlsNumber || listing?.mlsNumber || ''}
            onChange={(e) => setMlsNumber(e.target.value)}
            placeholder="MLS Number"
            style={{ padding: '10px', width: '70%', marginTop: '10px' }}
          />
          <br />
          <button
            onClick={handleSubmitMLS}
            style={{ marginTop: '10px', padding: '10px 20px', cursor: 'pointer' }}
            disabled={!mlsNumber.trim()}
          >
            Submit & Finalize
          </button>
        </div>
      )}
      </div>
    </div>
  );
};

export default ListingContainer;