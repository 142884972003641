/**
 * @file Step5.js
 * @module Step5
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.
 * @param {string} props.listingType - String representing the type of listing.


 * 
 * @example
 * // Example usage:
 * <Step5 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad'; 
import FormGroup from './FormGroup';

const Step5 = ({ listingId, listingType }) => {
  const { register, formState: { errors } } = useFormContext();

  // State to track character counts
  const [mainDetailsCount, setMainDetailsCount] = useState(0);
  const [extrasCount, setExtrasCount] = useState(0);
  const [inclusionsCount, setInclusionsCount] = useState(0);
  const [exclusionsCount, setExclusionsCount] = useState(0);
  const [rentalItemsCount, setRentalItemsCount] = useState(0);

  return (
    <div>
      {/* Main Details for Realtor.ca */}
      <FormGroup
        label="Main Details for Realtor.ca (Max 1000 characters)*"
        registerName="_mls.step5.mainDetails"
        register={register}
        required="Main details are required"
        errors={errors}
        type="textarea"
        maxLength={1000}
        rows={13}
        helperText={`${mainDetailsCount}/1000`}
        onChange={(e) => setMainDetailsCount(e.target.value.length)}
      />

      {/* Extras Section on Realtor.ca */}
      <FormGroup
        label="Extras Section on Realtor.ca (Max 160 characters)*"
        registerName="_mls.step5.extras"
        register={register}
        required="Extras details are required"
        errors={errors}
        type="textarea"
        maxLength={160}
        rows={3}
        helperText={`${extrasCount}/160`}
        onChange={(e) => setExtrasCount(e.target.value.length)}
      />

      {/* Inclusions Section */}
      <FormGroup
        label="Inclusions Section. Items included in the sale. (Max 160 characters)*"
        registerName="_mls.step5.inclusions"
        register={register}
        required="Inclusions are required"
        errors={errors}
        type="textarea"
        maxLength={160}
        rows={3}
        helperText={`${inclusionsCount}/160`}
        onChange={(e) => setInclusionsCount(e.target.value.length)}
      />

      {/* Exclusions Section */}
      <FormGroup
        label="Exclusions Section. Items not included with the sale. (Max 160 characters)*"
        registerName="_mls.step5.exclusions"
        register={register}
        required="Exclusions are required"
        errors={errors}
        type="textarea"
        maxLength={160}
        rows={3}
        helperText={`${exclusionsCount}/160`}
        onChange={(e) => setExclusionsCount(e.target.value.length)}
      />

      {/* Rental Items Section */}
      <FormGroup
        label="Rental Items Section. Rental items included with the sale. (Max 160 characters)"
        registerName="_mls.step5.rentalItems"
        register={register}
        errors={errors}
        type="textarea"
        maxLength={160}
        rows={3}
        helperText={`${rentalItemsCount}/160`}
        onChange={(e) => setRentalItemsCount(e.target.value.length)}
      />

      {/* Add the Signature Pad at the end of Step 1 */}
      <SignaturePad 
        step={5} 
        listingId={listingId} 
        fieldName="_mls.step5.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step5?.signature}
      />
    </div>
  );
};

export default Step5;