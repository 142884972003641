/**
 * @file Admin.js
 * @module Admin
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <Admin prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState } from 'react';
import Listings from './Listings';
import Users from './Users';
import Messages from './Messages';
import './Admin.css';

const Admin = () => {
  const [activePage, setActivePage] = useState('home');

  return (
    <div className="admin-container">
      <nav className="admin-nav">
        <ul>
          <li className="admin-title">RocketListings&reg;</li>
          <li onClick={() => setActivePage('home')} className={activePage === 'home' ? 'active' : ''}>
            🏠 Listings
          </li>
          <li onClick={() => setActivePage('messages')} className={activePage === 'messages' ? 'active' : ''}>
            🚩 Messages
          </li>
          <li onClick={() => setActivePage('users')} className={activePage === 'users' ? 'active' : ''}>
            🤑 Customers
          </li>
        </ul>
      </nav>
      <div className="">
        {activePage === 'home' && <Listings />}
        {activePage === 'messages' && <Messages />}
        {activePage === 'users' && <Users />}
      </div>
      <p
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '20px',
        }}
      >
        
      </p>
    </div>
  );
};

export default Admin;