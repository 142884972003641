/**
 * @file SocialProof.js
 * @module SocialProof
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <SocialProof prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';

const SocialProof = () => {
  const [visible, setVisible] = useState(false);
  const [notification, setNotification] = useState({ name: '', city: '', time: '' });

  // List of names, cities, and times
  const names = [
    'Arjun', 'Zara', 'Mateo', 'Aisha', 'Liam', 'Mei', 'Jamal', 'Sofia', 'Jin', 'Anika',
    'Omar', 'Kavya', 'Ethan', 'Isabella', 'Kwame', 'Ravi', 'Elena', 'Isaac', 'Yara', 'Noah',
    'Priya', 'Amara', 'Malik', 'Sarah', 'Keisha', 'Levi', 'Nina', 'Wei', 'Hassan', 'Kiran',
    'Sebastian', 'Nia', 'Dae', 'Ahmed', 'Tara', 'Samuel', 'Emilia', 'Chinwe', 'Raj', 'Maya',
    'David', 'Fatima', 'Kim', 'Olivia', 'Nelson', 'Sandeep', 'Amir', 'Lina', 'Jasper', 'Chen'
  ];

  const cities = [
    'Toronto', 'Ottawa', 'Mississauga', 'Brampton', 'Hamilton', 'London', 'Markham', 'Vaughan', 'Kitchener', 'Windsor',
    'Richmond Hill', 'Barrie', 'Sudbury', 'Guelph', 'Kingston', 'Cambridge', 'Thunder Bay', 'Waterloo', 'Oshawa', 'Whitby',
    'Pickering', 'Ajax', 'Niagara Falls', 'Burlington', 'St. Catharines', 'Sault Ste. Marie', 'Peterborough', 'Milton', 'Newmarket', 'Oakville',
    'Chatham', 'Aurora', 'Welland', 'Cornwall', 'Stratford', 'Orillia', 'Brantford', 'Woodstock', 'North Bay', 'Timmins',
    'Sarnia', 'Belleville', 'Caledon', 'Orangeville', 'Georgetown', 'Grimsby', 'Collingwood', 'Kenora', 'Brockville', 'Tillsonburg'
  ];

  const times = ['Just Now', '10 minutes ago', '30 minutes ago', '1 hour ago', '2 hours ago', '3 hours ago', '4 hours ago', '5 hours ago', '6 hours ago', '7 hours ago'];

  useEffect(() => {
    // Function to get a random item from an array
    const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];

    // Function to generate a random notification
    const generateRandomNotification = () => {
      const randomName = getRandomItem(names);
      const randomCity = getRandomItem(cities);
      const randomTime = getRandomItem(times);
      return { name: randomName, city: randomCity, time: randomTime };
    };

    // Show the first notification after 6 seconds
    const initialTimeout = setTimeout(() => {
      setNotification(generateRandomNotification());
      setVisible(true);

      // Hide the notification after 5-6 seconds
      setTimeout(() => {
        setVisible(false);
      }, 5000); // Popup will stay visible for 5 seconds
    }, 6000);

    // Show subsequent notifications every 22 seconds
    const interval = setInterval(() => {
      setNotification(generateRandomNotification());
      setVisible(true);

      // Hide the notification after 5-6 seconds
      setTimeout(() => {
        setVisible(false);
      }, 5000); // Popup will stay visible for 5 seconds
    }, 22000); // Popup appears every 22 seconds

    return () => {
      clearTimeout(initialTimeout);
      clearInterval(interval);
    };
  }, []); // Empty dependency array

  // Handle the click to close the popup
  const handleClick = () => {
    setVisible(false);
  };

  return (
    visible && (
      <div style={styles.notification} onClick={handleClick}>
        <div style={styles.rocket}>🚀</div>
        <div style={styles.textContainer}>
          <div>
            <span style={styles.name}>{notification.name} </span>
            <span>started to list their property in </span>
            <strong>{notification.city}!</strong>
          </div>
          <div style={styles.time}>{notification.time}</div>
        </div>
      </div>
    )
  );
};

// CSS-in-JS styling
const styles = {
  notification: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    background: 'linear-gradient(45deg, #6a11cb, #2575fc)',
    color: '#fff',
    borderRadius: '12px',
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    minWidth: '250px',
    maxWidth: '300px',
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
  },
  rocket: {
    fontSize: '30px',
    marginRight: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  name: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
    marginBottom: '5px',
  },
  time: {
    marginTop: '5px',
    fontSize: '1.1rem',
    opacity: 0.8,
  },
};

export default SocialProof;