/**
 * @file SortableItem.js
 * @module SortableItem
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires @dnd-kit/sortable - Module for additional functionalities
 * @requires @dnd-kit/utilities - Module for additional functionalities

 * @param {Object} props - The properties passed to the component
 * @param {string} props.id - String representing a unique identifier.
 * @param {string} props.url - String representing a URL.
 * @param {number} props.index - Number representing an index.
 * @param {function} props.deleteImage - Function to delete an image. (e.g., () => {})
 * @param {number} props.totalImages - Number indicating total count of images.


 * 
 * @example
 * // Example usage:
 * <SortableItem prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem = ({ id, url, index, deleteImage, totalImages }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: isDragging ? 'transform 200ms ease' : transition, // Smooth transition when dragging
    position: 'relative',
    maxWidth: '150px',
    maxHeight: '150px',
    objectFit: 'cover',
    zIndex: isDragging ? 999 : 'auto', // Elevate dragged item
  };

  return (
    <div ref={setNodeRef} style={{ ...style, marginBottom: '20px' }} {...attributes} {...listeners}>
      <img
        src={url}
        alt=''
        style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', marginBottom: '5px' }}
      />
      <button
        onClick={() => deleteImage(url, index)}
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          backgroundColor: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          cursor: 'pointer',
          padding: '0 8px 0 8px',
        }}
      >
        X
      </button>
      {/* Show the image position */}
      <div style={{
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'bold',
        marginTop: '5px',
      }}>
        #{index + 1}
      </div>
    </div>
  );
};

export default SortableItem;