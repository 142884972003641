/**
 * @file Questionnaire.js
 * @module Questionnaire
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <Questionnaire prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import Header from '../components/Header/Header';
import FooterDashboard from '../components/Footer/FooterDashboard';
import PropertyQuestionnaire from '../components/Dashboard/PropertyQuestionnaire';

function Questionnaire() {
  return (
    <>
      <Header />
      <PropertyQuestionnaire />
      <FooterDashboard />
    </>
  );
}

export default Questionnaire;