/**
 * @file Header.js
 * @module Header
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires react-router-dom - React library
 * @requires react-icons/md - React library
 * @requires react-icons/fa - React library




 * 
 * @example
 * // Example usage:
 * <Header prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'; 
import { NavLink } from 'react-router-dom';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import headerLogo from '../../assets/images/resources/logo.png';
import backgroundImage from '../../assets/images/shapes/main-header__top-pattern-bg.png';
import StickyHeader from './StickyHeader';
import { MdClose } from "react-icons/md";
import { FaLongArrowAltRight, FaBars, FaArrowRight } from "react-icons/fa";
import './Header.css'; 

function Header() {
    const [isSticky, setIsSticky] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleScroll = () => {
        const offset = window.scrollY;
        const headerHeight = document.querySelector('.main-header').offsetHeight;
    
        // for example, headerHeight + 100 or any other number that works for your layout
        if (offset > headerHeight + 5) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    // Handle closing the dropdown when clicking outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        }

        // Add event listener for clicks
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Cleanup event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                console.log('User location:', ipData.city, ipData.region_code, ipData.postal);
            }
        });
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to toggle dropdown visibility
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const MobileMenu = () => (
        <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
            <div className="mobile-menu__logo">
                <img src={headerLogo} 
                alt="Logo"
                style={{
                    width: '250px'
                }}
                />
            </div>
            <button className="mobile-menu__close-btn" onClick={toggleMobileMenu}>
                <MdClose />
            </button>
            <nav className="mobile-menu__nav">
                <ul className="mobile-menu__list">
                    <li><NavLink to="/pricing" onClick={toggleMobileMenu}><FaLongArrowAltRight /> Pricing</NavLink></li>
                    <li><NavLink to="/how-it-works" onClick={toggleMobileMenu}><FaLongArrowAltRight /> How It Works</NavLink></li>
                    <li><NavLink to="/faq" onClick={toggleMobileMenu}><FaLongArrowAltRight /> FAQ</NavLink></li>
                    <li><NavLink to="/my-account" onClick={toggleMobileMenu}><FaLongArrowAltRight /> My Account</NavLink></li>
                    <li><NavLink to="/list-now" onClick={toggleMobileMenu}><FaLongArrowAltRight /> List Now!</NavLink></li>
                </ul>
            </nav>
        </div>
    );

    return (
        <>
        <header className="main-header main-header--one">
            <div className="main-header__top-sticky">
                <div className="top-border">
                </div>
                <div className="bottom-border"></div>
                    <div className="main-header__top-pattern-bg"
                    style={{
                        backgroundImage: {backgroundImage}
                    }}
                    >
                </div>
                <div className="container">
                    <div className="main-header__top-inner">
                        <div className="main-header__top-left">
                            <div className="main-header__logo">
                                    <Link to="/">
                                        <img src={headerLogo} width="200" alt="RocketListing Logo" />
                                    </Link>
                            </div>
                        </div>
                        <div className="main-header__top-right">
                            <nav className="main-menu main-menu--one">
                                <div className="main-menu__wrapper">
                                    <div className="container">
                                        <div className="main-menu__wrapper-inner-sticky">
                                            <div className="">
                                            </div>
                                            <div className="main-menu__main-menu-box">
                                                <Link to="#" className="mobile-nav__toggler" onClick={toggleMobileMenu}>
                                                    <FaBars /> MENU
                                                </Link>
                                                <ul className="main-menu__list">
                                                    <li>
                                                    <NavLink to="/" end activeclasscame="active">Home</NavLink>
                                                    </li>
                                                    <li className="dropdown" ref={dropdownRef}>
                                                        <Link 
                                                            onClick={toggleDropdown} 
                                                            to="#"
                                                            className="dropdown-toggle"
                                                            style={{
                                                                color: 'black!important',
                                                            }}
                                                        >
                                                            Services
                                                        </Link>
                                                        {dropdownOpen && (
                                                            <ul className="dropdown-menu">
                                                                <li><NavLink to="/list-for-sale" activeclasscame="active">Sell My Property</NavLink></li>
                                                                <li><NavLink to="/list-for-rent" activeclasscame="active">Rent My Property</NavLink></li>
                                                                <li><NavLink to="/list-business-for-sale" activeclasscame="active">Business for Sale</NavLink></li>
                                                            </ul>
                                                        )}
                                                    </li>
                                                    <li>
                                                        <NavLink to="/how-it-works" activeclasscame="active">How It Works</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/pricing" activeclasscame="active">Pricing</NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink to="/faq" activeclasscame="active">FAQ</NavLink>
                                                    </li>
                                                    {/* <li>
                                                        <NavLink to="/my-account" activeclasscame="active">My Account</NavLink>
                                                    </li> */}
                                                </ul>
                                            </div>
                                            <div className="main-menu__right">
                                                <div className="main-menu__search-nav-sidebar-btn">
                                                    <div className="main-menu__btn-sticky">
                                                    <Link to="/list-now">List Now
                                                        <FaArrowRight 
                                                            style={{
                                                                fontSize: '.85em',
                                                                marginLeft: '10px'
                                                            }} 
                                                        />
                                                    </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <MobileMenu />

        {isSticky && (
            <div className="sticky-header">
                <StickyHeader />
            </div>
        )}
    </>
    );
}

export default Header;