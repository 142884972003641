/**
 * @file Hero.js
 * @module Hero
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <Hero prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import heroImage from '../../assets/images/resources/realtor-canada-flat-fee-service.png';

function Hero() {

    return (
        <section className="main-slider black-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xl-8 col-lg-7">
                        <div className="main-slider__left">
                            <div className="main-slider__title-box">
                                <span className="main-slider__sub-title">FLAT FEE MLS&reg; LISTING</span>
                                <h2 className="main-slider__title">List your property for sale or rent on the MLS&reg; for as low as $19.99/mo.</h2>
                                <ul className="additional-services-two__points list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>Includes REALTOR.ca listing</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>6 and 12-month listing packages available</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span className="icon-comment"></span>
                                        </div>
                                        <div className="text">
                                            <p>No hidden fees, commissions, ever!</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                        <div className="main-slider__right">
                            <div className="main-slider__form-box">
                            <img src={heroImage} alt="" style={{paddingBottom:'10px'}}/>
                                <h3>The #1 website in Canada to list your property for sale or rent.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;