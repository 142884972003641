/**
 * @file Home.js
 * @module Home
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires @chakra-ui/react - React library




 * 
 * @example
 * // Example usage:
 * <Home prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'; // Import ChakraProvider
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import AdditionalServicesSectionAlt from '../components/Services/AdditionalServicesSectionAlt';
import Hero from '../components/Hero/Hero';
import AboutHome from '../components/About/AboutHome';
import PricingSection from '../components/Coupons/PricingSection';
import BadgeSection from '../components/Badges/BadgeSection';
import OverlapCta from '../components/CTA/OverlapCta';
import HowItWorksSection from '../components/HowItWorks/HowItWorksSection';
import WideCtaSection from '../components/CTA/WideCtaSection';
import CommissionCalculator from '../components/CTA/CommissionCalculator';
import SocialProof from '../components/SocialProof/SocialProof';
import ExitIntentPopup from '../components/ExitIntentPopup/ExitIntentPopup';
import CheckoutForm from '../components/CheckoutForm/CheckoutForm';

function Home() {
  return (
    <ChakraProvider>
      <>
        <Header />
        <Hero />
        <CommissionCalculator />
        <AboutHome />
        <OverlapCta />
        <HowItWorksSection />
        <AdditionalServicesSectionAlt />
        <PricingSection />
        <CheckoutForm />
        <BadgeSection />
        <WideCtaSection />
        <SocialProof />
        <ExitIntentPopup />
        <Footer />
      </>
    </ChakraProvider>
  );
}

export default Home;