/**
 * @file HeaderUser.js
 * @module HeaderUser
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires react-router-dom - React library
 * @requires react-icons/md - React library
 * @requires react-icons/fa - React library




 * 
 * @example
 * // Example usage:
 * <HeaderUser prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { NavLink } from 'react-router-dom';
import { fetchCountryFromIP } from '../../utils/fetchCountryFromIP';
import headerLogo from '../../assets/images/resources/logo.png';
import backgroundImage from '../../assets/images/shapes/main-header__top-pattern-bg.png';
import { MdClose } from "react-icons/md";
import { FaLongArrowAltRight, FaBars, FaArrowRight } from "react-icons/fa";
import './Header.css'; 

function HeaderUser() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    
    useEffect(() => {
        fetchCountryFromIP().then(ipData => {
            if (ipData) {
                console.log('User location:', ipData.city, ipData.region_code, ipData.postal);
            }
        });
    }, []);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const MobileMenu = () => (
        <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
            <div className="mobile-menu__logo">
                <img src={headerLogo} 
                alt="Logo"
                style={{
                    width: '250px'
                }}
                />
            </div>
            {/* <div className="mobile-menu__phone">
                <a href={'tel:' + locationInfo.tracking_phone}>
                    <MdLocalPhone /> {locationInfo.tracking_phone}
                </a>
            </div> */}
            <button className="mobile-menu__close-btn" onClick={toggleMobileMenu}>
                <MdClose />
            </button>
            <nav className="mobile-menu__nav">
                <ul className="mobile-menu__list">
                    <li><NavLink to="/my-account" onClick={toggleMobileMenu}><FaLongArrowAltRight /> My Account</NavLink></li>
                </ul>
            </nav>
        </div>
    );

    return (
        <>
        <header className="main-header main-header--one">
            <div className="main-header__top-sticky">
                <div className="top-border">
                </div>
                <div className="bottom-border"></div>
                    <div className="main-header__top-pattern-bg"
                    style={{
                        backgroundImage: {backgroundImage}
                    }}
                    >
                </div>
                <div className="container">
                    <div className="main-header__top-inner">
                        <div className="main-header__top-left">
                            <div className="main-header__logo">
                                <img src={headerLogo} width="200" alt="RocketListing Logo" />
                            </div>
                        </div>
                        <div className="main-header__top-right">
                            <nav className="main-menu main-menu--one">
                                <div className="main-menu__wrapper">
                                    <div className="container">
                                        <div className="main-menu__wrapper-inner-sticky">
                                            <div className="">
                                            </div>
                                            <div className="main-menu__main-menu-box">
                                                <Link to="#" className="mobile-nav__toggler" onClick={toggleMobileMenu}>
                                                    <FaBars /> MENU
                                                </Link>
                                            </div>
                                            <div className="main-menu__right">
                                                <div className="main-menu__search-nav-sidebar-btn">
                                                    <div className="main-menu__btn-sticky">
                                                    <Link to="#">
                                                        My Account
                                                        <FaArrowRight 
                                                            style={{
                                                                fontSize: '.85em',
                                                                marginLeft: '10px'
                                                            }} 
                                                        />
                                                    </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <MobileMenu />
    </>
    );
}

export default HeaderUser;