/**
 * @file WideCtaSection.js
 * @module WideCtaSection
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <WideCtaSection prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';

function WideCtaSection() {
    return (
        <section className="row emmergency-leaks">
            <div className="container">
                <h4 className="this-intro">We're doing our best to help save you and other Canadians thousands of dollars,</h4>
                {/* <h2 className="this-title">What are you waiting for?</h2> */}
                <h2 className="this-title"><span>Please Tell Your Friends about Us!</span></h2>
            </div>
        </section>
    );
}

export default WideCtaSection;