/**
 * @file Messages.js
 * @module Messages
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires firebase/firestore - Module for additional functionalities
 * @requires react-paginate - React library
 * @requires react-select - React library
 * @requires react-loading-skeleton - React library

 * @param {Object} props - The properties passed to the component
 * @param {any} props.selected - Detailed description for selected


 * 
 * @example
 * // Example usage:
 * <Messages prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ListingContainer from './ListingContainer';
import './Listings.css';

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [listings, setListings] = useState([]);
  const [selectedListing, setSelectedListing] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterStatus, setFilterStatus] = useState('open'); // Default to 'open' status
  const [filterMessage, setFilterMessage] = useState('');
  const [filterAddress, setFilterAddress] = useState('');
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 25;

  useEffect(() => {
    const fetchMessagesAndListings = async () => {
      setLoading(true);
      const db = getFirestore();

      // Fetch messages
      const messagesSnapshot = await getDocs(collection(db, 'messages'));
      const fetchedMessages = messagesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch listings
      const listingsSnapshot = await getDocs(collection(db, 'listings'));
      const fetchedListings = listingsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setMessages(fetchedMessages);
      setListings(fetchedListings);

      // Set initial filter to show only 'open' messages
      const openMessages = fetchedMessages.filter(message => message.status === 'open');
      setFilteredMessages(openMessages);

      setLoading(false);
    };

    fetchMessagesAndListings();
  }, []);

  // Filter Messages
  useEffect(() => {
    let filtered = messages;

    // Apply status filter
    if (filterStatus) {
      filtered = filtered.filter(message => message.status?.toLowerCase() === filterStatus.toLowerCase());
    }
    if (filterMessage) {
      filtered = filtered.filter(message =>
        message.message?.toLowerCase().includes(filterMessage.toLowerCase())
      );
    }
    if (filterAddress) {
      filtered = filtered.filter(message =>
        message.address?.toLowerCase().includes(filterAddress.toLowerCase())
      );
    }

    setFilteredMessages(filtered);
    setCurrentPage(0); // Reset to first page after filter
  }, [filterStatus, filterMessage, filterAddress, messages]);

  // Handle Page Change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Paginate Messages
  const paginatedMessages = filteredMessages.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // Handle View Listing
  const handleViewListing = (paymentIntentId) => {
    const listing = listings.find(listing => listing.stripe?.paymentIntentId === paymentIntentId);
    if (listing) {
      setSelectedListing(listing);
    }
  };

  // Handle Mark as Closed
  const handleMarkAsClosed = async (messageId) => {
    const db = getFirestore();
    try {
      const messageRef = doc(db, 'messages', messageId);
      await updateDoc(messageRef, { status: 'closed' });

      // Update the state to reflect the change
      setMessages(prevMessages => 
        prevMessages.map(message => 
          message.id === messageId ? { ...message, status: 'closed' } : message
        )
      );
      setFilteredMessages(prevMessages => 
        prevMessages.map(message => 
          message.id === messageId ? { ...message, status: 'closed' } : message
        )
      );
    } catch (error) {
      console.error('Error updating message status:', error);
    }
  };

  // Status Options for Filtering
  const statusOptions = [
    { value: '', label: 'All' },
    { value: 'open', label: 'Open' },
    { value: 'closed', label: 'Closed' },
  ];

  return (
    <div className="listings-container" style={{ padding: '0' }}>
      {!selectedListing ? (
        <>
          <br />
          <h4>Messages</h4>
          <br />
          <div className="filters">
            <div className="filter-item">
              <label>Status:</label>
              <Select
                options={statusOptions}
                value={statusOptions.find(option => option.value === filterStatus)}
                onChange={option => setFilterStatus(option ? option.value : '')}
                placeholder="Select Status"
                isClearable
              />
            </div>
            <div className="filter-item">
              <label>Message:</label>
              <input
                type="text"
                value={filterMessage}
                onChange={e => setFilterMessage(e.target.value)}
                placeholder="Filter by Message"
              />
            </div>
            <div className="filter-item">
              <label>Address:</label>
              <input
                type="text"
                value={filterAddress}
                onChange={e => setFilterAddress(e.target.value)}
                placeholder="Filter by Address"
              />
            </div>
          </div>

          <table className="listings-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Message</th>
                <th>Address</th>
                <th>Link to Listing</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                [...Array(itemsPerPage)].map((_, index) => (
                  <tr key={index}>
                    <td><Skeleton width={80} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={200} /></td>
                    <td><Skeleton width={150} /></td>
                    <td><Skeleton width={100} /></td>
                    <td><Skeleton width={100} /></td>
                  </tr>
                ))
              ) : (
                paginatedMessages.map(message => (
                  <tr key={message.id}>
                    <td>{new Date(message.dateSent?.toDate()).toLocaleString()}</td>
                    <td>{message.status || '...'}</td>
                    <td>
                      {message.message.length > 150
                        ? `${message.message.slice(0, 150)}...`
                        : message.message}
                    </td>
                    <td>{message.address || '...'}</td>
                    <td>
                      <button onClick={() => handleViewListing(message.paymentIntentId)}>
                        View Listing
                      </button>
                    </td>
                    <td>
                      {message.status === 'open' && (
                        <button onClick={() => handleMarkAsClosed(message.id)}>
                          Mark as Closed
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {!loading && (
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={Math.ceil(filteredMessages.length / itemsPerPage)}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          )}
        </>
      ) : (
        <ListingContainer listing={selectedListing} onClose={() => setSelectedListing(null)} />
      )}
    </div>
  );
};

export default Messages;