/**
 * @file OverlapCta.js
 * @module OverlapCta
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library




 * 
 * @example
 * // Example usage:
 * <OverlapCta prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { Link } from 'react-router-dom'; 

function OverlapCta() {
    return (
        <section className="request-bar">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="request-bar__inner">
                            <div className="request-bar__bg"></div>
                            <div className="request-bar__left">
                                <div className="icon">
                                    <span className="icon-right-arrow"></span>
                                </div>
                                <div className="request-bar__content">
                                    <p>Would you spend $150 to save thousands $$$?</p>
                                    <h3>Then, you should see our packages!</h3>
                                </div>
                            </div>
                            <div className="request-bar__right">
                                <Link to="/list-now" className="thm-btn request-bar__btn">Get Started</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OverlapCta;