/**
 * @file Step11.js
 * @module Step11
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step11 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad';

const Step11 = ({ listingId }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div>
      {/* Step 5: Add Rooms */}
      {/* <div
        className="alert alert-warning"
        role="alert"
        style={{ marginTop: '10px', marginBottom: '30px' }}
      >
        Simply click Add Room to add a new room, and be sure to enter room dimensions in Meters.
      </div> */}
      {/* Legal Disclaimer Section */}
      <div 
        className="form-group" 
        style=
        {{ 
          fontStyle: 'italic', 
          backgroundColor: '#f9f9f9', 
          border: '1px solid #ddd', 
          padding: '15px', 
          borderRadius: '5px', 
          marginBottom: '50px' 
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'brown',
          }}
        >
          Image Upload Certification
        </p>
        <p style={{ marginBottom: '10px' }}>
        You hereby confirm that they have paid for and own the property photos they have uploaded to the RocketListings.ca website to be used on the MLS.
        <br /><br />
        Property photos cannot be taken from a previous MLS listing unless you have obtained written consent from the listing agent to use the photos with your listing.
        <br /><br />
        <span
          style={{
            color: 'black',
            backgroundColor: 'yellow',
          }}
        >
          Unlawful use of photos can result in your listing being removed without notice and you being fined up to $2,500.
        </span>
        <br /><br />
        <strong>I have reviewed the information above regarding the Image Upload Certification.</strong>
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input
            type="checkbox"
            {...register('._mls.step11.disclaimerAccepted', { required: 'You must agree to the disclaimer.' })}
            style={{ marginRight: '10px' }}
          />
          <label style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            I HEREBY ACKNOWLEDGE AND AGREE
          </label>
        </div>
        {errors._mls?.step11?.disclaimerAccepted && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errors._mls.step11.disclaimerAccepted.message}
          </p>
        )}
      </div>

      {/* Signature Pad */}
      <SignaturePad 
        step={11} 
        listingId={listingId} 
        fieldName="_mls.step11.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step11?.signature}
      />
    </div>
  );
};

export default Step11;