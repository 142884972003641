/**
 * @file Dashboard.js
 * @module Dashboard
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires firebase/auth - Module for additional functionalities
 * @requires firebase/firestore - Module for additional functionalities
 * @requires react-icons/fa - React library
 * @requires react-icons/ti - React library
 * @requires react-icons/md - React library
 * @requires react-icons/fa - React library




 * 
 * @example
 * // Example usage:
 * <Dashboard prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect, useRef } from 'react';
    import { useNavigate, NavLink } from 'react-router-dom';
    import { auth, firestore } from '../../firebaseConfig';
    import { signOut } from 'firebase/auth';
    import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
    import { FaPhotoVideo, FaPencilAlt } from "react-icons/fa";
    import { TiInfoOutline } from "react-icons/ti";
    import { MdCurrencyExchange } from "react-icons/md";
    import { FaRegBuilding } from "react-icons/fa";
    import PropertyQuestionnaire from './PropertyQuestionnaire';
    import MyProfile from './MyProfile';
    import Resources from './Resources';
    import './Dashboard.css';

    function Dashboard() {
        const [activeMenu, setActiveMenu] = useState('My Listings');
        const [listings, setListings] = useState([]);
        const [user, setUser] = useState(null);
        const [selectedListingId, setSelectedListingId] = useState(null); // Track selected listing for form
        const [listingType, setListingType] = useState(''); // Store selected listing type
        const [editingAddress, setEditingAddress] = useState(false); // Track if address is being edited
        const [newAddress, setNewAddress] = useState(''); // Track new address
        const [selectedListing, setSelectedListing] = useState(null); // Store selected listing for address editing
        const addressInputRef = useRef(null); // For Google Autocomplete
        const [, setProfileData] = useState({
            name: '',
            email: '',
            phone: '',
        });
        const navigate = useNavigate();

        const fetchUserListings = async (userId) => {
            try {
                const q = query(collection(firestore, 'listings'), where('userId', '==', userId));
                const querySnapshot = await getDocs(q);
                const userListingData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setListings(userListingData);
        
                // Check if any listing does not have 'acknowledged' or has it set to false
                const unacknowledgedListing = userListingData.find(listing => !listing.acknowledged);
                if (unacknowledgedListing) {
                    navigate('/acknowledge');
                    return; // Prevent further execution if redirected
                }
        
                if (querySnapshot.size > 0) {
                    const listingData = querySnapshot.docs[0].data();
                    setProfileData({
                        name: listingData.name || '',
                        email: listingData.email || '',
                        phone: listingData.phone || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching listings:', error);
            }
        };            

        useEffect(() => {
            const unsubscribe = auth.onAuthStateChanged((loggedInUser) => {
                if (loggedInUser) {
                    setUser(loggedInUser);
                    fetchUserListings(loggedInUser.uid);
                } else {
                    navigate('/login');
                }
            });

            return () => unsubscribe();
        }, [navigate]);

        const handleSignOut = async () => {
            try {
                await signOut(auth);
                setUser(null);
                setListings([]);
                navigate('/login');
            } catch (error) {
                console.error('Error signing out:', error);
            }
        };

        const getStatusLabel = (status) => {
            switch (status) {
                case 'NEW':
                    return <span className="status-label live">NEW</span>;
                case 'MISSING SIGNATURES':
                    return <span className="status-label missing-signatures">MISSING SIGNATURES</span>;
                case 'INFO NEEDED':
                    return <span className="status-label missing-info">INFORMATION NEEDED</span>;
                case 'PROCESSING':
                    return <span className="status-label missing-info">PROCESSING</span>;
                case 'LIVE':
                    return <span className="status-label missing-info">LIVE</span>;
                default:
                    return <span className="status-label">UNKNOWN</span>;
            }
        };

        const goToQuestionnaire = (listingId) => {
            setSelectedListingId(listingId);
            setActiveMenu('Questionnaire');
        };

        const handleListingTypeChange = (e) => {
            setListingType(e.target.value);
        };

        const submitListingType = async (listingId) => {
            if (!listingType) {
                return alert('Please select a listing type.');
            }

            try {
                const listingDocRef = doc(firestore, 'listings', listingId);
                await updateDoc(listingDocRef, {
                    type: listingType,          // Set the selected listing type
                    status: 'INFO NEEDED'       // Update the status to "INFO NEEDED"
                });

                // alert('Listing type updated successfully. Status changed to "INFO NEEDED".');
                window.location.reload(); // Refresh the page after updating the listing type
            } catch (error) {
                console.error('Error updating listing type:', error);
            }
        };

        const handleAddressEdit = (listing) => {
            setSelectedListing(listing); // Set selected listing for editing
            setEditingAddress(true);
            setNewAddress(listing.propertyAddress); // Pre-fill with the existing address
        };

        const updateAddress = async () => {
            if (!newAddress) {
                alert('Please enter an address.');
                return;
            }

            try {
                const listingDocRef = doc(firestore, 'listings', selectedListing.id);
                await updateDoc(listingDocRef, {
                    propertyAddress: newAddress,
                });
                alert('Address updated successfully.');
                setEditingAddress(false); // Exit edit mode
                window.location.reload(); // Refresh the page to enforce new state
            } catch (error) {
                console.error('Error updating address:', error);
            }
        };

        useEffect(() => {
            if (window.google && editingAddress) {
                const autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current, {
                    componentRestrictions: { country: 'ca' }, // Restrict to Canada (adjust for your region)
                    fields: ['formatted_address'],
                });

                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place && place.formatted_address) {
                        setNewAddress(place.formatted_address); // Set new address
                    }
                });
            }
        }, [editingAddress]);

        const renderContent = () => {
            if (activeMenu === 'My Listings') {
                return (
                    <div>
                        <h3>Your Listings - {user ? user.email : ''}</h3>
                        <br />
                        {listings.length > 0 ? (
                            <div className="listings-grid single-listing">
                                {listings.map((listing, index) => (
                                    <div key={index} className="listing-card wide-card">
                                        <div className="listing-card-header">
                                            <h4>
                                                {listing.propertyAddress}
                                            
                                                {(listing.status === 'INFO NEEDED' || listing.status === 'NEW') && (
                                                <>
                                                    <div
                                                        style={{
                                                            marginTop: 15,
                                                        }}
                                                    >
                                                    {/* Edit Address Button and Input */}
                                                    {!editingAddress ? (
                                                        <button 
                                                            className="btn btn-secondary" 
                                                            onClick={() => handleAddressEdit(listing)}
                                                        >
                                                            Change listing address
                                                        </button>
                                                    ) : selectedListing?.id === listing.id && (
                                                        <div className="autocomplete-edit">
                                                            <input
                                                                type="text"
                                                                ref={addressInputRef} // Google Autocomplete input
                                                                value={newAddress}
                                                                onChange={(e) => setNewAddress(e.target.value)}
                                                                placeholder="Enter new address"
                                                                className="input-field"
                                                                style={{
                                                                    width: '400px',
                                                                    marginRight: '10px',
                                                                    fontSize: '14px',
                                                                }}
                                                            />
                                                            <button 
                                                                className="btn btn-primary" 
                                                                onClick={updateAddress}>
                                                                Save Address
                                                            </button>
                                                            <button 
                                                                className="btn btn-secondary" 
                                                                onClick={() => setEditingAddress(false)} // Close button action to exit without saving
                                                                style={{
                                                                    marginLeft: '10px',
                                                                }}
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    )}
                                                    </div>
                                                </>
                                            )}
                                            </h4>
                                            {getStatusLabel(listing.status)}
                                        </div>
                                        <div className="listing-details">
                                            {listing.type && (
                                                <p>
                                                    <strong>Type:&nbsp;</strong>
                                                    {listing.type === 'freehold-sale' && 'Freehold for Sale'}
                                                    {listing.type === 'freehold-rent' && 'Freehold for Rent'}
                                                    {listing.type === 'condo-sale' && 'Condo for Sale'}
                                                    {listing.type === 'condo-lease' && 'Condo for Lease'}
                                                    &nbsp; ({listing.plan.id === 1 ? '6-month Term' : '12-month Term'})
                                                </p>
                                            )}
                                            {listing.status === 'NEW' && (
                                            <div 
                                                className="alert alert-warning" 
                                                role="alert"
                                                style={{ marginTop: '10px', marginBottom: '30px' }}
                                            >
                                                Important message: Please select the Type of Listing to proceed:
                                            </div>
                                            )}

                                            {listing.status === 'INFO NEEDED' && (
                                            <div
                                                className="alert alert-warning"
                                                role="alert"
                                                style={{ marginTop: '10px', marginBottom: '30px' }}
                                            >
                                                Important message: Click the "Provide Info" button to complete your listing:
                                            </div>
                                            )}
                                            
                                            {listing.status === 'NEW' && (
                                                
                                                <div className="listing-type-actions">
                                                    <h4 className="listing-title">Choose a Listing Type:</h4>
                                                    <div className="listing-type-grid listing-type-options">
                                                        <label>
                                                            <div className="radio-icon">
                                                                <FaRegBuilding />
                                                            </div>
                                                            <div className="radio-option-title">Freehold for Sale</div>
                                                            <div className="radio-description">Suitable for residential freehold properties available for sale.</div>
                                                            <input
                                                                type="radio"
                                                                name="listingType"
                                                                value="freehold-sale"
                                                                onChange={handleListingTypeChange}
                                                            />
                                                        </label>

                                                        <label>
                                                            <div className="radio-icon">
                                                                <FaRegBuilding />
                                                            </div>
                                                            <div className="radio-option-title">Freehold for Rent</div>
                                                            <div className="radio-description">Rent out your freehold property to interested tenants.</div>
                                                            <input
                                                                type="radio"
                                                                name="listingType"
                                                                value="freehold-rent"
                                                                onChange={handleListingTypeChange}
                                                            />
                                                        </label>

                                                        <label>
                                                            <div className="radio-icon">
                                                                <FaRegBuilding />
                                                            </div>
                                                            <div className="radio-option-title">Condo for Sale</div>
                                                            <div className="radio-description">Condominiums available for sale.</div>
                                                            <input
                                                                type="radio"
                                                                name="listingType"
                                                                value="condo-sale"
                                                                onChange={handleListingTypeChange}
                                                            />
                                                        </label>

                                                        <label>
                                                            <div className="radio-icon">
                                                                <FaRegBuilding />
                                                            </div>
                                                            <div className="radio-option-title">Condo for Lease</div>
                                                            <div className="radio-description">Lease out your condominium.</div>
                                                            <input
                                                                type="radio"
                                                                name="listingType"
                                                                value="condo-lease"
                                                                onChange={handleListingTypeChange}
                                                            />
                                                        </label>
                                                    </div>
                                                    <button 
                                                        className="submit-button" 
                                                        onClick={() => submitListingType(listing.id)}
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            )}
                                            <div className="listing-actions">
                                                {listing.status === 'INFO NEEDED' && (
                                                    <button className="listing-action-button" onClick={() => goToQuestionnaire(listing.id)}>
                                                        <TiInfoOutline
                                                            style={{
                                                                fontSize: '2.3rem',
                                                                color: '#007bff',
                                                                marginRight: '5px',
                                                            }} />
                                                        <span>Provide Info</span>
                                                    </button>
                                                )}
                                                {(listing.status === 'PROCESSING' || listing.status === 'LIVE') && (
                                                    <>
                                                        <button className="listing-action-button">
                                                            <MdCurrencyExchange
                                                                style={{
                                                                    fontSize: '2.3rem',
                                                                    color: '#007bff',
                                                                    marginRight: '5px',
                                                                }}
                                                            />
                                                            <span>Edit Price</span>
                                                        </button>
                                                        <button className="listing-action-button">
                                                            <FaPhotoVideo
                                                                style={{
                                                                    fontSize: '2.3rem',
                                                                    color: '#007bff',
                                                                    marginRight: '5px',
                                                                }}
                                                            />
                                                            <span>Manage Photos</span>
                                                        </button>
                                                        <button className="listing-action-button">
                                                            <FaPencilAlt
                                                                style={{
                                                                    fontSize: '2rem',
                                                                    color: '#007bff',
                                                                    marginRight: '5px',
                                                                }}
                                                            />
                                                            <span>Edit Listing</span>
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                            {(listing.type === 'freehold-sale' || listing.type === 'condo-sale') && (
                                            <>
                                                <p
                                                    style={{
                                                        marginTop: '20px',
                                                        color: 'grey',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Other listing actions
                                                </p>
                                                <ul className="listing-actions-list">
                                                    <li>
                                                        <NavLink>
                                                            Mark as SOLD - WITH Conditions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink>
                                                            Mark as SOLD - WITH Conditions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink>
                                                            CANCEL Listing (No longer selling)
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </>
                                            )}
                                            {(listing.type === 'freehold-rent' || listing.type === 'condo-lease') && (
                                            <>
                                                <p
                                                    style={{
                                                        marginTop: '20px',
                                                        color: 'grey',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    Other listing actions
                                                </p>
                                                <ul className="listing-actions-list">
                                                    <li>
                                                        <NavLink>
                                                            Mark as RENTED - WITH Conditions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink>
                                                            Mark as RENTED - WITH Conditions
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink>
                                                            CANCEL Listing (No longer renting)
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>
                );
            } else if (activeMenu === 'Questionnaire' && selectedListingId) {
                return <PropertyQuestionnaire listingId={selectedListingId} />;
            } else if (activeMenu === 'My Profile') {
                return <MyProfile />;
            } else if (activeMenu === 'Resources') {
                return <Resources listingId={selectedListingId} listings={listings} />;
            } else if (activeMenu === 'Upgrades') {
                return <MyProfile />;
            }
        };

        return (
            <div className="dashboard-container">
                <aside className="sidebar">
                    <div className="sidebar-header">
                        <h2
                            style={{
                                color: 'lightgrey',
                                fontSize: '14px',
                                fontWeight: '300',
                                margin: '0',
                                paddingBottom: '10px',
                            }}
                        >MY ACCOUNT</h2>
                    </div>
                    <nav className="menu">
                        <ul>
                            <li className={activeMenu === 'My Listings' ? 'active' : ''} onClick={() => setActiveMenu('My Listings')}>
                                My Listings
                            </li>
                            <li className={activeMenu === 'My Profile' ? 'active' : ''} onClick={() => setActiveMenu('My Profile')}>
                                My Profile
                            </li>
                            <li className={activeMenu === 'Resources' ? 'active' : ''} onClick={() => setActiveMenu('Resources')}>
                                Resources
                            </li>
                            <li className={activeMenu === 'Upgrades' ? 'active' : ''} onClick={() => setActiveMenu('Upgrades')}>
                                Upgrades
                            </li>
                        </ul>
                    </nav>
                    <div className="logout-section">
                        <button onClick={handleSignOut} className="logout-button">Logout</button>
                    </div>
                </aside>
                <main className="content">{renderContent()}</main>
            </div>
        );
    }

    export default Dashboard;