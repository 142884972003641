/**
 * @file ListingDetails.js
 * @module ListingDetails
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires react-loading-skeleton - React library

 * @param {Object} props - The properties passed to the component
 * @param {object} props.listing - Object containing MLS number, property address, and images. (e.g., { MLS number, property address, and images. })


 * 
 * @example
 * // Example usage:
 * <ListingDetails prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { renderField, renderDate } from './helpers';

const ListingDetails = ({ listing }) => {
  const isLoading = !listing; // Determine if data is loading

  return (
    <div>
      <h4>
        {isLoading ? <Skeleton width={200} /> : listing.propertyAddress}
      </h4>
      <p style={{ margin: '1.5rem 0' }}>
        <span
          style={{
            color: isLoading ? 'gray' : listing.status === 'PROCESSING' ? 'red'
              : listing.status === 'LIVE' ? 'green'
              : listing.status === 'INFO NEEDED' ? 'navy'
              : 'black',
            fontWeight: 'bold',
            backgroundColor: 'white',
            border: '5px solid black',
            padding: '0.5rem',
          }}
        >
          {isLoading ? <Skeleton width={80} /> : listing.status}
        </span>
        <span
          style={{
            color: 'black',
            fontWeight: 'bold',
            padding: '0.5rem',
            border: '1px solid',
            marginLeft: '1.5rem',
          }}
        >
          {isLoading ? (
            <Skeleton width={120} />
          ) : (
            listing.type === 'condo-sale'
              ? 'Condo for Sale'
              : listing.type === 'freehold-sale'
              ? 'Freehold for Sale'
              : listing.type === 'freehold-rent'
              ? 'Freehold for Rent'
              : listing.type === 'condo-lease'
              ? 'Condo for Lease'
              : listing.type.replace(/-/g, ' ').replace(/^./, (str) => str.toUpperCase())
          )}
        </span>
        <span
          style={{
            color: 'black',
            fontWeight: 'bold',
            border: '1px solid',
            padding: '0.5rem',
            marginLeft: '1.5rem',
          }}
        >
          {isLoading ? <Skeleton width={100} /> : listing.plan?.label}
        </span>
        <span
          style={{
            color: 'black',
            fontWeight: 'bold',
            padding: '0.5rem',
            marginLeft: '1.5rem',
          }}
        >
          {isLoading ? (
            <Skeleton width={100} />
          ) : listing.slug ? (
            <>
              <Link to={'/listings/'+listing.slug} target="_blank" rel="noreferrer" style={{ marginRight: '1rem' }}>
                <button>View Listing</button>
              </Link>
            </>
          ) : (
            'Listing URL Missing!'
          )}
        </span>
      </p>
      <div className="listing-info">
        {isLoading ? (
          // Show skeletons when data is loading
          <>
            <Skeleton width={150} height={20} />
            <Skeleton width={100} height={20} />
            <Skeleton width={200} height={20} />
            <Skeleton width={80} height={20} />
            <Skeleton width={250} height={20} />
          </>
        ) : (
          // Render fields when data is available
          <>
            {renderField('MLS #', listing.mlsNumber)}
            {renderField('Type', listing.type)}
            {renderField('IP Address', listing?.acknowledgement?.ipAddress)}
            {renderField('Status', listing.status)}
            {renderField('Property Address', listing.propertyAddress)}
            {renderField('Name', listing.name)}
            {renderField('Phone', listing.phone)}
            {renderField('Email', listing.email)}
            {renderField('Acknowledged', listing.acknowledged ? 'Yes' : 'No')}
            {renderDate('Acknowledgement Date', listing?.acknowledgement?.acknowledgementDate)}
            {renderDate('Created At', listing.createdAt)}
          </>
        )}
      </div>
    </div>
  );
};

export default ListingDetails;