/**
 * @file Step4.js
 * @module Step4
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingId - String representing a unique identifier for a listing.
 * @param {string} props.listingType - String representing the type of listing.


 * 
 * @example
 * // Example usage:
 * <Step4 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import SignaturePad from './SignaturePad';

const Step4 = ({ listingId, listingType }) => {
  const { register, formState: { errors }, control, setError, clearErrors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: '_mls.step4.rooms'
  });

  useEffect(() => {
    if (fields.length < 3) {
      setError('_mls.step4.rooms', {
        type: 'manual',
        message: 'You must add a Room for every single room in the entire property.'
      });
    } else {
      clearErrors('_mls.step4.rooms');
    }
  }, [fields, setError, clearErrors]);  

  useEffect(() => {
    if (fields.length === 0) {
      append({ roomLevel: '', roomType: '', length: '', width: '' });
    }
  }, [fields, append]);
  

  return (
    <div>
      {/* Step 5: Add Rooms */}
      <div
        className="alert alert-warning"
        role="alert"
        style={{ marginTop: '10px', marginBottom: '30px' }}
      >
        IMPORTANT: Be sure to add every single room in the entire property (main floor, upper floors, basement, etc.). That includes bedrooms, bathrooms, kitchens, living rooms, etc.
        <br /><br />
        Simply click Add Room to add a new room, and be sure to enter room dimensions in Meters.
      </div>

      {/* Add Rooms Section */}
      <div>
        {fields.map((item, index) => (
          <div key={item.id} class="form-group" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            {/* Room Level */}
            <div style={{ marginRight: '10px' }}>
              <label htmlFor={`length-${index}`}>Room Level:</label>
              <select
                className="form-control"
                {...register(`_mls.step4.rooms.${index}.roomLevel`, { required: true })}
                style={{ marginRight: '10px' }}
              >
                <option value="">Select Room Level</option>
                <option value="Main">Main</option>
                <option value="2nd">2nd</option>
                <option value="3rd">3rd</option>
                <option value="Basement">Basement</option>
                <option value="Flat">Flat</option>
                <option value="Ground">Ground</option>
                <option value="In Between">In Between</option>
                <option value="Lower">Lower</option>
                <option value="Sub">Sub</option>
                <option value="Upper">Upper</option>
              </select>
            </div>

            {/* Room Type */}
            <div style={{ marginRight: '10px' }}>
              <label htmlFor={`length-${index}`}>Room Type:</label>
              <select
                className="form-control"
                {...register(`._mls.step4.rooms.${index}.roomType`, { required: true })}
                style={{ marginRight: '10px' }}
              >
                <option value="">Select Type</option>
                <option value="Bathroom">Bathroom</option>
                <option value="Bedroom">Bedroom</option>
                <option value="2nd Bedroom">2nd Bedroom</option>
                <option value="3rd Bedroom">3rd Bedroom</option>
                <option value="4th Bedroom">4th Bedroom</option>
                <option value="5th Bedroom">5th Bedroom</option>
                <option value="Breakfast">Breakfast</option>
                <option value="Cold Room Cantina">Cold Room Cantina</option>
                <option value="Common Room">Common Room</option>
                <option value="Den">Den</option>
                <option value="Dining">Dining</option>
                <option value="Exercise">Exercise</option>
                <option value="Family">Family</option>
                <option value="Foyer">Foyer</option>
                <option value="Furnace">Furnace</option>
                <option value="Games">Games</option>
                <option value="Great Room">Great Room</option>
                <option value="Kitchen">Kitchen</option>
                <option value="Laundry">Laundry</option>
                <option value="Library">Library</option>
                <option value="Living">Living</option>
                <option value="Locker">Locker</option>
                <option value="Loft">Loft</option>
                <option value="Media/Entertainment">Media/Entertainment</option>
                <option value="Mudroom">Mudroom</option>
                <option value="Nursery">Nursery</option>
                <option value="Office">Office</option>
                <option value="Other">Other</option>
                <option value="Pantry">Pantry</option>
                <option value="Playroom">Playroom</option>
                <option value="Powder Room">Powder Room</option>
                <option value="Primary Bedroom">Primary Bedroom</option>
                <option value="Recreation Room">Recreation Room</option>
                <option value="Sitting">Sitting</option>
                <option value="Solarium">Solarium</option>
                <option value="Study">Study</option>
                <option value="Sun Room">Sun Room</option>
                <option value="Tandem Room">Tandem Room</option>
                <option value="Utility">Utility</option>
                <option value="Workshop">Workshop</option>
              </select>
            </div>

            {/* Room Length */}
            <div style={{ marginRight: '10px' }}>
              <label htmlFor={`length-${index}`}>Length (m)</label>
              <input
                type="number"
                placeholder="Length (m)"
                className="form-control"
                {...register(`._mls.step4.rooms.${index}.length`, { required: true })}
                style={{ marginRight: '10px' }}
              />
            </div>

            {/* Room Width */}
            <div style={{ marginRight: '10px' }}>
              <label htmlFor={`length-${index}`}>Width (m)</label>
              <input
                type="number"
                placeholder="Width (m)"
                className="form-control"
                {...register(`._mls.step4.rooms.${index}.width`, { required: true })}
                style={{ marginRight: '10px' }}
              />
            </div>

            {/* Delete Room Button */}
            
            <button
              type="button"
              onClick={() => remove(index)}
              style={{
                backgroundColor: 'black',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                padding: '0px 8px',
                marginTop: '25px'
              }}
            >
              X
            </button>
          </div>
        ))}

        {/* Add Room Button */}
        <button
          type="button"
          onClick={() => append({ roomLevel: '', roomType: '', length: '', width: '' })}
          style={{
            marginTop: '10px',
            padding: '5px 15px',
            backgroundColor: 'green',
            color: 'white',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          Add Room
        </button>
        <br />
        <br />
        {errors.mls?.step4?.rooms && (
        <p
          className="error-text"
          style={{
            backgroundColor: 'yellow',
            fontWeight: 'bolder',
            marginTop: '10px',
            padding: '10px',
            fontSize: '17px',
            letterSpacing: '0.01rem',
          }}
        >
          {errors.mls.step4.rooms.message}
        </p>
      )}
      <br />
      </div>
      {/* Legal Disclaimer Section */}
      <div 
        className="form-group" 
        style=
        {{ 
          fontStyle: 'italic', 
          backgroundColor: '#f9f9f9', 
          border: '1px solid #ddd', 
          padding: '15px', 
          borderRadius: '5px', 
          marginBottom: '50px' 
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'brown',
          }}
        >Acknowledgement required:</p>
        <p style={{ marginBottom: '10px' }}>
          The Property Owner hereby acknowledges and agrees that they have personally taken the measurements listed above and ensure that the information and measurements entered are accurate. The Property Owner hereby indemnifies the Brokerage against all liability, loss, damages, costs, and expenses sustained, suffered, or incurred by the Brokerage as a result of any incorrect and/or false information provided by the Property Owner.
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input
            type="checkbox"
            {...register('._mls.step4.disclaimerAccepted', { required: 'You must agree to the disclaimer.' })}
            style={{ marginRight: '10px' }}
          />
          <label style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            I HEREBY ACKNOWLEDGE AND AGREE
          </label>
        </div>
        {/* Display error message for the checkbox */}
        {errors._mls?.step4?.disclaimerAccepted && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errors._mls.step4.disclaimerAccepted.message}
          </p>
        )}
      </div>

      {/* Signature Pad */}
      <SignaturePad 
        step={4} 
        listingId={listingId} 
        fieldName="_mls.step4.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step4?.signature}
      />
    </div>
  );
};

export default Step4;