/**
 * @file FaqSection.js
 * @module FaqSection
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <FaqSection prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState } from 'react';

const faqData = [
  { 
    question: 'How does RocketListings.ca work?', 
    answer: 'RocketListings.ca allows property sellers and landlords to list their property on the MLS® and REALTOR.ca for a flat fee. Once listed, potential buyers and agents contact you directly, keeping you in full control of your sale.' 
  },
  { 
    question: 'Is a real estate agent listing my property?', 
    answer: 'Yes, a licensed real estate agent is required to submit your property listing to the MLS® as part of a Mere Posting. However, unlike a traditional listing, the agent’s role is limited to uploading your property details to the MLS® and REALTOR.ca. You remain in control of everything else—handling buyer inquiries, scheduling showings, and negotiating offers. The agent is not involved in any active representation or day-to-day management of your property sale.' 
  },
  { 
    question: 'What is the cost of listing?', 
    answer: 'We offer two flat-fee options: $149.99 for our Standard Listing Package and $239.99 for the MAX EXPOSURE® Listing Package. Both provide full exposure on MLS® and REALTOR.ca, with the MAX EXPOSURE® Listing Package offering additional benefits such as an extended listing term (12-months) and submission ito other popular listing platforms.' 
  },
  { 
    question: 'How is RocketListings.ca different from traditional real estate agents?', 
    answer: 'Traditional agents charge 2.5-3.0% commission fees, while RocketListings.ca provides a cost-effective alternative. You handle all buyer inquiries, negotiations, and showings directly, saving thousands in commission fees.' 
  },
  { 
    question: 'How quickly will my listing appear on MLS® and REALTOR.ca?', 
    answer: 'Once your listing package is completed and submitted, your property will be live on MLS® within one business day. It may take an additional 24 hours to appear on REALTOR.ca.' 
  },
  { 
    question: 'Can I make changes or extend my listing?', 
    answer: 'Yes! You can easily request changes to your listing, such as price updates or extensions. Listings can be extended for discounted rate of $129 for 6-months, or $99 for 12-months.' 
  },
  { 
    question: 'What is a "Mere Posting" on MLS®?', 
    answer: 'A "Mere Posting" means your property is listed on MLS® and REALTOR.ca, but you handle all inquiries and negotiations directly. It gives you maximum exposure while allowing you to manage the sale yourself without a listing agent.' 
  },
  { 
    question: 'Do I have to pay commission to buyer’s agents?', 
    answer: 'No, you are not required to pay commission to buyer’s agents unless you choose to. However, if a buyer’s agent is involved, you can negotiate a commission directly with them, or offer a small co-operating commission upfront.' 
  },
  { 
    question: 'How do buyers and agents find my MLS® listing?', 
    answer: 'Your property will be searchable on MLS® by agents and homebuyers, and will also appear on REALTOR.ca for public viewing. All interested parties will contact you directly using the contact details you provide in the listing.' 
  },
  { 
    question: 'What should I do when my property is sold?', 
    answer: 'When your property is sold, simply notify us by providing the necessary sale documentation, and we will update the listing status on MLS®. There’s no additional charge for reporting a sale.' 
  },
  { 
    question: 'Can I list my rental property with RocketListings.ca?', 
    answer: 'Absolutely! RocketListings.ca allows landlords to list rental properties on MLS® and REALTOR.ca, making it easier for potential tenants to find your property.' 
  },
  { 
    question: 'How do showings work with a RocketListings.ca listing?', 
    answer: 'When buyers or agents are interested in viewing your property, they will contact you directly to schedule showings at your convenience. You remain in full control of the process.' 
  },
  { 
    question: 'Do I need to disclose defects or issues with the property?', 
    answer: 'Yes, it’s important to disclose any known defects, hazards, or stigmas associated with your property to potential buyers. This ensures transparency and helps avoid legal issues down the road.' 
  },
];

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div style={styles.container}>
      <div className="section-title text-left">
        <h2 className="section-title__title">
        Frequently Asked Questions
        </h2>
    </div>
      {faqData.map((faq, index) => (
        <div
          key={index}
          style={{
            ...styles.faqItem,
            backgroundColor: activeIndex === index ? '#f0f4ff' : '#ffffff',
            boxShadow: activeIndex === index ? '0 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
          }}
        >
          <div
            onClick={() => toggleFAQ(index)}
            style={styles.question}
          >
            <div style={styles.icon}>
              {activeIndex === index ? '▼' : '▶'}
            </div>
            <div style={styles.text}>{faq.question}</div>
          </div>
          {activeIndex === index && (
            <div style={styles.answer}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1175px',
    margin: '0 auto',
    padding: '20px',
  },
  faqItem: {
    marginBottom: '12px',
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  },
  question: {
    padding: '24px',  // Increased padding for a larger feel
    backgroundColor: '#e7effc',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
  },
  icon: {
    marginRight: '16px',
    fontSize: '16px',  // Doubled font size for the icon
    fontWeight: 'bold',
    color: '#0044cc',
  },
  text: {
    fontSize: '20px',  // Doubled font size for the question
    fontWeight: '600',
  },
  answer: {
    padding: '24px',  // Increased padding for a better experience
    backgroundColor: '#f9faff',
    borderTop: '1px solid #d0d8e8',
    fontSize: '20px',  // Doubled font size for the answer
    lineHeight: '1.6',
  },
};

export default FaqSection;