/**
 * @file LoginForm.js
 * @module LoginForm
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires firebase/auth - Module for additional functionalities
 * @requires firebase/firestore - Module for additional functionalities




 * 
 * @example
 * // Example usage:
 * <LoginForm prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { auth, firestore } from '../../firebaseConfig'; // Your Firebase config
import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth'; // Add signOut from firebase/auth
import { collection, query, where, getDocs } from 'firebase/firestore'; // Firestore methods
import './LoginForm.css'; // Updated CSS for the new design

function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [listings, setListings] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null); // Track the logged-in user

    const navigate = useNavigate(); // Initialize the useNavigate hook

    // Handle user login
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user); // Set the user after successful login
            navigate('/my-account'); // Redirect to /my-account after login
        } catch (err) {
            setError('Login failed. Please check your credentials.');
        } finally {
            setLoading(false);
        }
    };

    // Fetch listings for the authenticated user
    const fetchUserListings = async (userId) => {
        try {
            const q = query(collection(firestore, 'listings'), where('userId', '==', userId));
            const querySnapshot = await getDocs(q);
            const userListingData = querySnapshot.docs.map(doc => doc.data());
            setListings(userListingData);
        } catch (error) {
            console.error('Error fetching listings:', error);
        }
    };

    // Listen to auth state changes and fetch listings when user logs in
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (loggedInUser) => {
            if (loggedInUser) {
                setUser(loggedInUser); // Set the user
                fetchUserListings(loggedInUser.uid); // Fetch listings for the logged-in user
                navigate('/my-account'); // Redirect to /my-account if logged in
            } else {
                setUser(null); // No user is logged in
                setListings([]); // Clear listings
            }
        });

        // Cleanup the listener on component unmount
        return () => unsubscribe();
    }, [navigate]);

    // Handle sign out
    const handleSignOut = async () => {
        try {
            await signOut(auth); // Sign the user out
            setUser(null); // Reset user state after sign out
            setListings([]); // Clear listings after sign out
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div className="login-container-centered">
            {user ? (
                <div className="welcome-section">
                    <h2 className="welcome-message">Welcome, {user.email}</h2>
                    <button onClick={handleSignOut} className="signout-button">Sign Out</button>
                    <h3>Your Listings</h3>
                    {listings.length > 0 ? (
                        <ul className="listings-list">
                            {listings.map((listing, index) => (
                                <li key={index} className="listing-item">
                                    <strong>Address:</strong> {listing.propertyAddress}<br />
                                    <strong>Plan:</strong> {listing.plan.label}<br />
                                    <strong>Bonus:</strong> {listing.bonus ? 'Yes' : 'No'}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No listings found.</p>
                    )}
                </div>
            ) : (
                <div className="inline-login-form">
                    <form onSubmit={handleLogin} className="login-form">
                        <h2 className="inline-title">Login to RocketListings.ca</h2>
                        <p>Forgot your password?  Check your Welcome email from us, it will contain your password.</p>
                        <br />
                        <div className="form-elements">
                            <input
                                type="email"
                                placeholder="Email address..."
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="inline-input"
                            />
                            <input
                                type="password"
                                placeholder="Password..."
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="inline-input"
                            />
                            <button type="submit" disabled={loading} className="inline-submit">
                                {loading ? 'Logging in...' : 'Login to My Account'}
                            </button>
                        </div>
                        {error && <p className="error-message">{error}</p>}
                    </form>
                </div>
            )}
        </div>
    );
}

export default LoginForm;