/**
 * @file helpers.js
 * @module helpers
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <helpers prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';

// Helper function to render images if data is base64 encoded
export const renderImage = (label, base64) => {
  if (base64 && base64.startsWith('data:image')) {
    return (
      <div className="info-item">
        <strong>{label}:</strong>
        <img src={base64} alt={label} style={{ maxWidth: '100px', display: 'block' }} />
      </div>
    );
  }
  return null;
};

// Helper function to render fields with formatted labels
export const renderField = (label, value) => {
  const formattedLabel = formatLabel(label);

  // Handle missing or null values
  if (value === undefined || value === null) {
    return (
      <div className="">
        <strong>{formattedLabel}:</strong> N/A
      </div>
    );
  }

  // Handle array values with bullet points
  if (Array.isArray(value)) {
    return (
      <div className="">
        <strong>{formattedLabel}:</strong>
        <ul>
          {value.map((item, index) => (
            <li key={index}>
              {typeof item === 'object' ? JSON.stringify(item) : item}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  // Handle object values
  if (typeof value === 'object') {
    return (
      <div className="">
        <strong>{formattedLabel}:</strong>
        <pre>{JSON.stringify(value, null, 2)}</pre>
      </div>
    );
  }

  // Handle image rendering for base64 strings
  if (typeof value === 'string' && value.startsWith('data:image')) {
    return (
      <div className="">
        <strong>{formattedLabel}:</strong>
        <img src={value} alt={formattedLabel} style={{ maxWidth: '100px', display: 'block', margin: '10px 0' }} />
      </div>
    );
  }

  // Default rendering for strings, numbers, etc.
  return (
    <div className="">
      <strong>{formattedLabel}:</strong> {value.toString()}
    </div>
  );
};

// Helper function to format rooms data
export const formatRooms = (rooms) => {
  return (
    <div className="">
      <strong>Rooms:</strong>
      <ul>
        {rooms.map((room, index) => (
          <li key={index}>
            {room.roomType}: {room.width} x {room.length}
          </li>
        ))}
      </ul>
    </div>
  );
};

// Helper function to safely render dates
export const renderDate = (label, dateValue) => {
  if (!dateValue) {
    return (
      <div className="info-item">
        <strong>{label}:</strong> N/A
      </div>
    );
  }
  const date = new Date(dateValue);
  return (
    <div className="info-item">
      <strong>{label}:</strong> {isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString()}
    </div>
  );
};

// Helper function to format array as bullet points
export const formatArrayAsBullets = (array) => {
  return array.map(item => `• ${item}`).join('\n');
};

// Helper function to format label by capitalizing words, adding spaces before capital letters and numbers
export const formatLabel = (label) => {
  return label
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/(\d+)/g, ' $1') // Add space before numbers
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
    .trim(); // Remove leading space if any
};