/**
 * @file HowItWorksSection.js
 * @module HowItWorksSection
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library




 * 
 * @example
 * // Example usage:
 * <HowItWorksSection prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
// import { Link } from 'react-router-dom'; 
import rateBanner from '../../assets/images/resources/so-how-does-it-work.png';

function HowItWorksSection() {

    return (
        <section className="why-choose-one">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-5">
                        <div className="why-choose-one__left">
                            <div className="why-choose-one__img-box">
                                <div className="why-choose-one__img">
                                    <img src={rateBanner} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-7">
                        <div className="why-choose-one__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">HOW IT WORKS</span>
                                <h2 className="section-title__title">5 Steps &#x27A1; Save Thousands $$$</h2>
                            </div>
                            <p className="why-choose-one__text">
                                    We've made it really affordable to list your property for sale or rent. Just follow these simple steps:
                                </p>
                            <ul className="list-unstyled why-choose-one__points">
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Step 1: Choose a 6 or 12-month listing package and complete your payment online. 
                                            You will receive an email to begin the listing process.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Step 2: Provide your property's information online, then sign documents electronically.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Step 3: Your property is now listed on the MLS&reg; and REALTOR.ca,&reg; plus we'll send it to even more websites 
                                            if you use our 12-month 'Max Exposure' Listing package.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Step 4: You will start receiving inquiries and showing requests from buyers or renters, 
                                            depending whether your property is listed for sale or rent.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon-comment"></span>
                                    </div>
                                    <div className="text">
                                        <p>
                                            Step 5: Now work with your buyer, renter, or real estate lawyer to complete the necessary paperwork and finalize the deal. 
                                            Congratulations, you've just saved thousands in real estate commissions!
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWorksSection;