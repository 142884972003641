/**
 * @file AboutHome.js
 * @module AboutHome
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <AboutHome prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import aboutImage2 from '../../assets/images/resources/for-sale-rent.png';
import aboutImage1 from '../../assets/images/shapes/about-two-shape-1.png';

function AboutHome() {
    return (
        <section className="about-two">
            <div className="about-two__shape-1 float-bob-x">
                <img src={aboutImage1} alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-lg-5">
                        <div className="about-two__left">
                            <div className="about-two__img float-bob-y">
                                <img src={aboutImage2} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-7">
                        <div className="about-two__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">List on MLS&reg; &amp; REALTOR.ca</span>
                                <h2 className="section-title__title">
                                Sell or Rent &#x27A1; Your Property
                                </h2>
                            </div>
                            <p className="about-two__text">List your property for sale or rent on MLS&reg; and REALTOR.ca&reg; for as low as $19.99/month.
                             Your listing will be seen by thousands of potential buyers and renters!
                            </p>
                        </div>
                        
                        <ul className="about-one__points-box list-unstyled">
                                <li>
                                    <div className="about-one__points-list">
                                        <div className="about-one__points-icon">
                                            <span className="icon-satisfaction"></span>
                                        </div>
                                        <h3 className="about-one__points-title">REALTOR.ca Listing</h3>
                                    </div>
                                    {/* <p className="about-one__points-text">Our gutter protection simply works better than any
                                        other product.</p> */}
                                </li>
                                <li>
                                    <div className="about-one__points-list">
                                        <div className="about-one__points-icon">
                                            <span className="icon-thumbs-up"></span>
                                        </div>
                                        <h3 className="about-one__points-title">Avoid 2.5% Commission</h3>
                                    </div>
                                    {/* <p className="about-one__points-text">Our gutter protection simply works better than any
                                        other product.</p> */}
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutHome;