/**
 * @file Step8.js
 * @module Step8
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-hook-form - React library

 * @param {Object} props - The properties passed to the component
 * @param {string} props.listingType - String representing the type of listing.
 * @param {string} props.listingId - String representing a unique identifier for a listing.


 * 
 * @example
 * // Example usage:
 * <Step8 prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from './SignaturePad';

const Step8 = ({ listingType, listingId }) => {
  const { register, formState: { errors } } = useFormContext();

  return (
    <div>
      {/* Step 5: Add Rooms */}
      {/* <div
        className="alert alert-warning"
        role="alert"
        style={{ marginTop: '10px', marginBottom: '30px' }}
      >
        Simply click Add Room to add a new room, and be sure to enter room dimensions in Meters.
      </div> */}
      {/* Legal Disclaimer Section */}
      <div 
        className="form-group" 
        style=
        {{ 
          fontStyle: 'italic', 
          backgroundColor: '#f9f9f9', 
          border: '1px solid #ddd', 
          padding: '15px', 
          borderRadius: '5px', 
          marginBottom: '50px' 
        }}
      >
        <p
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'brown',
          }}
        >
          Co-operating Agent Commission
        </p>
        <p style={{ marginBottom: '10px' }}>
        The Seller is under no obligation to provide a co-operating commission UNLESS a commission agreement is signed with a co-operating agent for their specific buyer/tenant. 
        <br /><br />
        It is important to consider offering a co-operating commission to licensed real estate agents to encourage them to show your property to their clients. 
        <br /><br />
        If you, as the Owner, would like to offer a co-operating commission to be offered to licensed real estate agents, please indicate the amount below. The commission offered can be a fixed amount or a percentage of the sale amount.
        <br /><br />
        {(listingType === 'freehold-sale' || listingType === 'condo-sale') && (
          <>
        The standard commission offered to a Buyer's Agent is between 2.0 - 2.5% of the sale price. You, as the Owner, have the right to offer any amount you wish but risk not having your property shown to potential buyers if the commission is considered "too low".
        </>
        )}
        {(listingType === 'freehold-rent' || listingType === 'condo-lease') && (
          <>
          If your tenant is being represented by a licensed real estate agent, the standard commission offered is one (1) month's rent. You, as the Owner, have the right to offer any amount you wish but risk not having your property shown to potential tenants if the commission is considered "too low".
          </>
        )}
        </p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <input
            type="checkbox"
            {...register('._mls.step8.disclaimerAccepted', { required: 'You must agree to the disclaimer.' })}
            style={{ marginRight: '10px' }}
          />
          <label style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
            I HEREBY ACKNOWLEDGE AND AGREE
          </label>
        </div>
        {/* Display error message for the checkbox */}
        {errors._mls?.step8?.disclaimerAccepted && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errors._mls.step8.disclaimerAccepted.message}
          </p>
        )}
      </div>

      {/* Signature Pad */}
      <SignaturePad 
        step={8} 
        listingId={listingId} 
        fieldName="_mls.step8.signature" 
        register={register} 
        required={true} 
        error={errors._mls?.step8?.signature}
      />
    </div>
  );
};

export default Step8;