/**
 * @file ThankYouSection.js
 * @module ThankYouSection
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <ThankYouSection prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useEffect } from 'react';
import './ThankYouSection.css';

function ThankYouSection() {
    useEffect(() => {
        // Check if the URL parameter 'reloaded' is not present
        if (!new URLSearchParams(window.location.search).has('reloaded')) {
            // Reload the page with 'reloaded' parameter to indicate that the page has been refreshed
            window.location.href = window.location.href + '?reloaded=true';
        }
        // The empty array ensures this effect runs only once when the component mounts
    }, []);

    return (
        <div className="ads-layout">
            <div className="ads-banner ads-banner--north">AdSense Banner (North)</div>
            <div className="ads-content">
                <div className="ads-banner ads-banner--west">AdSense Banner (West)</div>
                <div className="thankyou-section">
                    <div className="thankyou-section__container">
                        <div className="thankyou-section__content">
                            <h2 className="thankyou-section__title">Thank you!</h2>
                            <p className="thankyou-section__text">We will contact you shortly.</p>
                        </div>
                    </div>
                </div>
                <div className="ads-banner ads-banner--east">AdSense Banner (East)</div>
            </div>
            <div className="ads-banner ads-banner--south">AdSense Banner (South)</div>
        </div>
    );
}

export default ThankYouSection;