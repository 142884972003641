/**
 * @file AcknowledgementForm.js
 * @module AcknowledgementForm
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires react-router-dom - React library
 * @requires firebase/firestore - Module for additional functionalities
 * @requires react-signature-canvas - React library




 * 
 * @example
 * // Example usage:
 * <AcknowledgementForm prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { auth } from '../../firebaseConfig'; // Assuming you have the firebase config ready
import SignatureCanvas from 'react-signature-canvas';
import './AcknowledgementForm.css';

const AcknowledgementForm = () => {
  const [signatures, setSignatures] = useState({
    mlsRules: null,
    isOwner: null,
    updateListing: null,
    sendDocs: null,
    lawyerRecommendation: null,
  });
  const [twoFactorInput, setTwoFactorInput] = useState(''); // State for user input for 2FA code
  const [selectedListing, setSelectedListing] = useState(null); // Store only selected listing
  const [loading, setLoading] = useState(true); // Loading state
  const [errorMessage, setErrorMessage] = useState(''); // For 2FA error message
  const [signatureErrors, setSignatureErrors] = useState({}); // State to track signature errors
  const [ipAddress, setIpAddress] = useState(''); // State to store the user's IP address
  const navigate = useNavigate();
  const db = getFirestore();

  const sigPads = {
    mlsRules: useRef(null),
    isOwner: useRef(null),
    updateListing: useRef(null),
    sendDocs: useRef(null),
    lawyerRecommendation: useRef(null),
  };

  // Fetch IP address
  const fetchIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      setIpAddress(data.ip); // Set the fetched IP address
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  const generateRandomSlug = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let slug = '';
    for (let i = 0; i < 8; i++) {
      slug += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return slug;
  };  

  useEffect(() => {
    fetchIpAddress(); // Fetch the IP address on component mount

    const fetchUserListings = async (userId) => {
      try {
        const q = query(collection(db, 'listings'), where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        const userListingData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        if (userListingData.length > 0) {
          const firstListing = userListingData[0];
          setSelectedListing(firstListing); // Automatically select the first listing
  
          // Check if already acknowledged
          if (firstListing.acknowledged) {
            navigate('/my-account'); // Redirect if already acknowledged
          }
        }
      } catch (error) {
        console.error('Error fetching listings:', error);
      } finally {
        setLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((loggedInUser) => {
      if (loggedInUser) {
        fetchUserListings(loggedInUser.uid);
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();
  }, [db, navigate]);

  const handleClear = (key) => {
    sigPads[key].current.clear();
    setSignatures({ ...signatures, [key]: null });
    setSignatureErrors((prevErrors) => ({ ...prevErrors, [key]: '' })); // Clear the error for this field
  };

  const handleDraw = (key) => {
    setSignatures({
      ...signatures,
      [key]: sigPads[key].current.getTrimmedCanvas().toDataURL('image/png'),
    });
    setSignatureErrors((prevErrors) => ({ ...prevErrors, [key]: '' })); // Clear the error for this field when signing
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let hasError = false;
  
    // Check for missing 2FA code input
    if (!twoFactorInput) {
      setErrorMessage('Please enter the 2FA code.');
      hasError = true;
    } else if (twoFactorInput !== selectedListing.twoFactorAuth.toString()) {
      setErrorMessage('The 2FA code you entered is incorrect.');
      hasError = true;
    } else {
      setErrorMessage(''); // Clear error message if 2FA is correct
    }
  
    // Check for missing signatures
    const missingSignatures = {};
    Object.keys(signatures).forEach((key) => {
      if (!signatures[key]) {
        missingSignatures[key] = 'This signature is required.';
        hasError = true;
      }
    });
  
    setSignatureErrors(missingSignatures);
  
    // If any errors were found, stop submission
    if (hasError) {
      return;
    }
  
    // Proceed with updating the document if everything is correct
    if (selectedListing) {
      try {
        const docRef = doc(db, 'listings', selectedListing.id);
  
        await updateDoc(docRef, {
          acknowledged: true,
          acknowledgement: {
            mlsRules: signatures.mlsRules,
            isOwner: signatures.isOwner,
            updateListing: signatures.updateListing,
            sendDocs: signatures.sendDocs,
            lawyerRecommendation: signatures.lawyerRecommendation,
            acknowledgementDate: new Date().toISOString(), // Optionally include the timestamp of submission
            ipAddress: ipAddress, // Save the IP address in Firestore
          },
          slug: generateRandomSlug(), // Add the 'slug' field with a random 8-letter string
        });
  
        navigate('/my-account');
      } catch (error) {
        console.error('Error updating document:', error);
        setErrorMessage('An error occurred while submitting. Please try again.');
      }
    }
  };  

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!selectedListing) {
    return <div>No listings found for this user.</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <h2 className="header">Acknowledgement</h2>

      {/* Display listing info */}
      <div className="listing-info">
        <p><strong>Name:</strong> {selectedListing.name || 'N/A'}</p>
        <p><strong>Phone:</strong> {selectedListing.phone || 'N/A'}</p>
        <p><strong>Email:</strong> {selectedListing.email || 'N/A'}</p>
        <p><strong>Property Address:</strong> {selectedListing.propertyAddress}</p>
        <p><strong>IP Address:</strong> {ipAddress || 'Fetching IP...'}</p>
      </div>

      {/* 2FA Code Input Section */}
      <div className="section">
        <div className="section-header">Please enter the 5-digit verification code that was sent to your email:</div>
        <input
          type="text"
          maxLength="5"
          value={twoFactorInput}
          onChange={(e) => setTwoFactorInput(e.target.value)}
          className="input-field"
          placeholder="Enter 2FA code"
          required
        />
        {errorMessage && (
          <p 
            className="error-text"
            style={{
              backgroundColor: 'yellow',
              fontWeight: 'bolder',
              marginTop: '10px',
              padding: '10px',
              fontSize: '17px',
              letterSpacing: '0.01rem',
            }}
          >
            {errorMessage}
          </p>
        )}
      </div>

      {/* Signature sections */}
      {[
        { key: 'mlsRules', label: 'agree to adhere to the MLS listing rules' },
        { key: 'isOwner', label: 'agree that I am one of the owners of the property' },
        { key: 'updateListing', label: 'agree to update the listing when it is Sold/Leased to avoid a fine' },
        { key: 'sendDocs', label: 'agree to upload, upon closing, all agreements, amendments to RocketListing to avoid a fine' },
        { key: 'lawyerRecommendation', label: 'understand that RocketListing highly recommends hiring a real estate lawyer' },
      ].map(({ key, label }) => (
        <div key={key} className="section">
          <div className="section-header">I, {selectedListing.name || 'N/A'}, {label}:</div>
          <div className="signature-wrapper">
            <SignatureCanvas
              ref={sigPads[key]}
              penColor="black"
              canvasProps={{ className: 'signatureCanvas', width: 500, height: 150 }}
              onEnd={() => handleDraw(key)}
            />
            <button type="button" onClick={() => handleClear(key)} className="clear-button">Clear</button>
          </div>
          {signatureErrors[key] && (
            <p 
              className="error-text"
              style={{
                backgroundColor: 'yellow',
                fontWeight: 'bolder',
                marginTop: '10px',
                padding: '10px',
                fontSize: '17px',
                letterSpacing: '0.01rem',
              }}
            >
              {signatureErrors[key]}
            </p>
          )}
        </div>
      ))}

      <button type="submit" className="submit-button">Agree and Submit</button>
    </form>
  );
};

export default AcknowledgementForm;