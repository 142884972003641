/**
 * @file Listing.js
 * @module Listing
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <Listing prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import ListingPage from '../components/Listing/ListingPage';

function Listing({ locationInfo }) {
  return (
    <div
      style={{
        backgroundColor: '#f9f4ef',
        marginTop: -17,
      }}
    >
        <ListingPage />
    </div>
  );
}

export default Listing;