/**
 * @file firebaseConfig.js
 * @module firebaseConfig
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires firebase/compat/app - Module for additional functionalities




 * 
 * @example
 * // Example usage:
 * <firebaseConfig prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database'
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAc5KtOx1uOi8OdpV61-BehdFSQdefOY3o",
    authDomain: "rocketlistings-4820b.firebaseapp.com",
    databaseURL: "https://rocketlistings-4820b-default-rtdb.firebaseio.com",
    projectId: "rocketlistings-4820b",
    storageBucket: "rocketlistings-4820b.appspot.com",
    messagingSenderId: "469844928503",
    appId: "1:469844928503:web:ad0ae7f1aaabf43ddb30f2",
    measurementId: "G-HXHQKJBMXF"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const database = firebase.database();
export const firestore = firebase.firestore();