/**
 * @file Users.js
 * @module Users
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library
 * @requires firebase/firestore - Module for additional functionalities
 * @requires react-loading-skeleton - React library




 * 
 * @example
 * // Example usage:
 * <Users prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ListingContainer from './ListingContainer';
import './Users.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [filterEmail, setFilterEmail] = useState(''); // Email filter
  const [listings, setListings] = useState([]); // Separate listings state
  const [selectedListing, setSelectedListing] = useState(null); // Selected listing

  useEffect(() => {
    const fetchUsersAndListings = async () => {
      const db = getFirestore();
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const listingsSnapshot = await getDocs(collection(db, 'listings'));

      const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const listingsData = listingsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Add listings to users based on matching userId
      const usersWithListings = usersData.map(user => {
        const userListings = listingsData.filter(listing => listing.userId === user.id);
        return { ...user, listings: userListings };
      });

      setUsers(usersWithListings);
      setListings(listingsData);
      setIsLoading(false); // Data is loaded
    };

    fetchUsersAndListings();
  }, []);

  // Filter users by email
  const filteredUsers = filterEmail
    ? users.filter(user => user.email.toLowerCase().includes(filterEmail.toLowerCase()))
    : users;

  // Handle view listing based on paymentIntentId
  const handleViewListing = (paymentIntentId) => {
    const listing = listings.find(listing => listing.stripe?.paymentIntentId === paymentIntentId);
    if (listing) {
      setSelectedListing(listing); // Set the selected listing
    } else {
      alert('Listing not found.');
    }
  };

  return (
    <div className="users-container">
      {!selectedListing ? (
        <>
          <br />
          <h4>Customers</h4>
          <br />

          {/* Email Filter */}
          <div className="filter-item">
            <label>Email:</label>
            <input
              type="text"
              value={filterEmail}
              onChange={e => setFilterEmail(e.target.value)}
              placeholder="Filter by Email"
              style={{ marginBottom: '15px', padding: '8px', width: '250px' }}
            />
          </div>

          <table className="users-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Listing Address</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                // Display skeleton loader while loading
                Array(5)
                  .fill()
                  .map((_, index) => (
                    <tr key={index}>
                      <td><Skeleton width={120} /></td>
                      <td><Skeleton width={150} /></td>
                      <td><Skeleton width={100} /></td>
                      <td><Skeleton width={80} /></td>
                    </tr>
                  ))
              ) : (
                // Display actual data when loaded
                filteredUsers.map(user => 
                  user.listings.map(listing => (
                    <tr key={`${user.id}-${listing.id}`}>
                      <td>{user.email}</td>
                      <td>{listing.propertyAddress}</td>
                      <td>{listing.status}</td>
                      <td>
                        <button
                          onClick={() => handleViewListing(listing.stripe?.paymentIntentId)}
                          disabled={!listing.stripe?.paymentIntentId}
                        >
                          View Listing
                        </button>
                      </td>
                    </tr>
                  ))
                )
              )}
            </tbody>
          </table>
        </>
      ) : (
        <ListingContainer listing={selectedListing} onClose={() => setSelectedListing(null)} />
      )}
    </div>
  );
};

export default Users;