/**
 * @file ServiceRental.js
 * @module ServiceRental
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <ServiceRental prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import AdditionalServicesSectionAlt from '../components/Services/AdditionalServicesSectionAlt';
import AboutRental from '../components/About/AboutRental';
import PricingSection from '../components/Coupons/PricingSection';
import BadgeSection from '../components/Badges/BadgeSection';
import OverlapCta from '../components/CTA/OverlapCta';
import HowItWorksSection from '../components/HowItWorks/HowItWorksSection';
import WideCtaSection from '../components/CTA/WideCtaSection';
import CommissionRental from '../components/CTA/CommissionRental';
import SocialProof from '../components/SocialProof/SocialProof';
import ExitIntentPopup from '../components/ExitIntentPopup/ExitIntentPopup';
import CheckoutForm from '../components/CheckoutForm/CheckoutForm';
import HeroServices from '../components/Hero/HeroServices';

function ServiceRental() {
  return (
    <>
      <Header />
      <HeroServices
        title="List your Property for Rent on the MLS&reg; for as low as $19.99/mo."
        subtitle="FLAT FEE MLS&reg; FOR RENTALS"
        pointA="Includes REALTOR.ca listing"
        pointB="6 and 12-month listing packages available"
        pointC="Save commissions - you can do this!"
        imageCaption="The #1 website in Canada to list your property for sale."
      />
      <CommissionRental />
      <AboutRental />
      <OverlapCta />
      <HowItWorksSection />
        <AdditionalServicesSectionAlt />
      <PricingSection />
      <CheckoutForm />
      <BadgeSection />
      <WideCtaSection />
      <SocialProof />
      <ExitIntentPopup />
      <Footer />
    </>
  );
}

export default ServiceRental;