/**
 * @file Acknowledgement.js
 * @module Acknowledgement
 * @description
 * Component that renders UI and manages interactions.
 * 
 * @requires react - React library




 * 
 * @example
 * // Example usage:
 * <Acknowledgement prop1="value1" prop2="value2" />
 * 
 * @returns {JSX.Element} Rendered component.
 * @throws {Error} If rendering fails due to missing props or errors.
 */
 
import React from 'react';
import HeaderUser from '../components/Header/HeaderUser';
import AcknowledgementForm from '../components/Acknowledgement/AcknowledgementForm';
import FooterDashboard from '../components/Footer/FooterDashboard';

function Acknowledgement(
    
) {
  return (
    <div>
        <HeaderUser />
        <AcknowledgementForm />
        <FooterDashboard />
    </div>
  );
}

export default Acknowledgement;